import WizardController from '../../../wizard_controller'

export default class extends WizardController {

  static targets = [
    'amenorrhoea',
    'amenorrhoeaDetails',
    'boneDensityScan',
    'boneDensityScanDetails',
    'breastLumpsDetails',
    'breastSurgicalDetails',
    'contraceptiveCurrentDetails',
    'contraceptiveNotCurrentInput',
    'contraceptiveDetails',
    'contraceptivePreviousDetails',
    'contraceptivePreviousDetailsInput',
    'contraceptivePreviousInput',
    'ctScans',
    'ctScansDetails',
    'dysmenorrhoea',
    'dysmenorrhoeaDetails',
    'dyspareuniaDetails',
    'exerciseDetails',
    'exerciseDetailsInput',
    'genitourinaryConditionsDetails',
    'infectionDetails',
    'mammogram',
    'mammogramDetails',
    'menopausalDetails',
    'menstrualIrregularities',
    'menstrualIrregularitiesDetails',
    'menstrualNone',
    'neoplasticDetails',
    'otherBreastAbnormalitiesDetails',
    'otherInvestigations',
    'otherInvestigationsDetails',
    'otherMenstrual',
    'otherMenstrualDetails',
    'otherSpecialInvestigationsNone',
    'papSmearDetails',
    'pelvicDetails',
    'polycysticOvarianSyndromeDetails',
    'postcoitalBleedingDetails',
    'recurringInfections',
    'reproductiveDetails',
    'ultrasounds',
    'ultrasoundsDetails',
    'vaginismusDetails',
    'weightDetails'
  ]

  static values = {}

  static classes = []

  initialize() {}

  connect() {}

  // ==== Controllers

  // ==== Actions

  addContraceptivePreviousDetailsItem() {
    if (this.contraceptivePreviousDetailsTarget.classList.contains(this.hideClass)) {
      this.enableContraceptivePreviousDetails()
    } else {
      this.addNestedTextInput({
        listTarget:    this.contraceptivePreviousDetailsInputTargets,
        wrapperTarget: this.contraceptivePreviousDetailsTarget
      })
    }
  }

  addExerciseDetailsItem() {
    this.addNestedTextInput({
      listTarget:    this.exerciseDetailsInputTargets,
      wrapperTarget: this.exerciseDetailsTarget
    })
  }

  menstrualNoneChange({ currentTarget }) {
    if (currentTarget.checked) {
      this.amenorrhoeaTarget.checked             = false
      this.dysmenorrhoeaTarget.checked           = false
      this.menstrualIrregularitiesTarget.checked = false
      this.otherMenstrualTarget.checked          = false

      this.amenorrhoeaDetailsTarget.classList.add(this.hideClass)
      this.dysmenorrhoeaDetailsTarget.classList.add(this.hideClass)
      this.menstrualIrregularitiesDetailsTarget.classList.add(this.hideClass)
      this.otherMenstrualDetailsTarget.classList.add(this.hideClass)
    }
  }

  otherSpecialInvestigationsNoneChange({ currentTarget }) {
    if (currentTarget.checked) {
      this.boneDensityScanTarget.checked     = false
      this.ctScansTarget.checked             = false
      this.mammogramTarget.checked           = false
      this.otherInvestigationsTarget.checked = false
      this.ultrasoundsTarget.checked         = false

      this.boneDensityScanDetailsTarget.classList.add(this.hideClass)
      this.ctScansDetailsTarget.classList.add(this.hideClass)
      this.mammogramDetailsTarget.classList.add(this.hideClass)
      this.otherInvestigationsDetailsTarget.classList.add(this.hideClass)
      this.ultrasoundsDetailsTarget.classList.add(this.hideClass)
    }
  }

  removeContraceptivePreviousDetailsItem({ currentTarget }) {
    const list = this.contraceptivePreviousDetailsInputTargets
    if (list.length === 1) {
      this.clearAndDisableContraceptivePreviousDetails()
      this.contraceptiveNotCurrentInputTarget.checked = false
      this.contraceptiveCurrentDetailsTarget.classList.remove(this.hideClass)
    }

    this.removeNestedTextInput({
      buttonTarget:  currentTarget,
      listTarget:    list,
      wrapperTarget: this.contraceptivePreviousDetailsTarget
    })
  }

  removeExerciseDetailsItem({ currentTarget }) {
    this.removeNestedTextInput({
      buttonTarget:  currentTarget,
      listTarget:    this.exerciseDetailsInputTargets,
      wrapperTarget: this.exerciseDetailsTarget
    })
  }

  toggleAmenorrhoeaDetails({ currentTarget }) {
    if (currentTarget.checked) {
      this.menstrualNoneTarget.checked = false
    }

    this.toggleCheckboxMoreDetails({
      input:  currentTarget,
      target: this.amenorrhoeaDetailsTarget
    })
  }

  toggleBoneDensityScanDetails({ currentTarget }) {
    if (currentTarget.checked) {
      this.otherSpecialInvestigationsNoneTarget.checked = false
    }

    this.toggleCheckboxMoreDetails({
      input:  currentTarget,
      target: this.boneDensityScanDetailsTarget
    })
  }

  toggleBreastLumpsDetails({ currentTarget }) {
    this.toggleRadioButtonMoreDetails({
      input:  currentTarget,
      target: this.breastLumpsDetailsTarget
    })
  }

  toggleBreastSurgicalDetails({ currentTarget }) {
    this.toggleRadioButtonMoreDetails({
      input:  currentTarget,
      target: this.breastSurgicalDetailsTarget
    })
  }

  toggleContraceptiveDetails({ currentTarget }) {
    this.toggleRadioButtonLessDetails({
      input:  currentTarget,
      target: this.contraceptiveDetailsTarget
    })
  }

  toggleContraceptiveCurrentDetails({ currentTarget }) {
    this.toggleCheckboxLessDetails({
      input:  currentTarget,
      target: this.contraceptiveCurrentDetailsTarget
    })

    if (currentTarget.checked) {
      this.enableContraceptivePreviousDetails()
    } else {
      this.disableContraceptivePreviousDetails()
    }
  }

  enableContraceptivePreviousDetails(value = true) {
    this.contraceptivePreviousDetailsTarget.classList.toggle(this.hideClass, !value)
    for (const input of this.contraceptivePreviousInputTargets) {
      input.disabled = !value
    }
  }

  clearAndDisableContraceptivePreviousDetails() {
    for (const input of this.contraceptivePreviousInputTargets) {
      input.value = ''
    }
    this.enableContraceptivePreviousDetails(false)
  }

  disableContraceptivePreviousDetails() {
    for (const input of this.contraceptivePreviousInputTargets) {
      if (input.value.length > 0) return
    }
    this.enableContraceptivePreviousDetails(false)
  }

  toggleCtScansDetails({ currentTarget }) {
    if (currentTarget.checked) {
      this.otherSpecialInvestigationsNoneTarget.checked = false
    }

    this.toggleCheckboxMoreDetails({
      input:  currentTarget,
      target: this.ctScansDetailsTarget
    })
  }

  toggleDysmenorrhoeaDetails({ currentTarget }) {
    if (currentTarget.checked) {
      this.menstrualNoneTarget.checked = false
    }

    this.toggleCheckboxMoreDetails({
      input:  currentTarget,
      target: this.dysmenorrhoeaDetailsTarget
    })
  }

  toggleDyspareuniaDetails({ currentTarget }) {
    this.toggleRadioButtonMoreDetails({
      input:  currentTarget,
      target: this.dyspareuniaDetailsTarget
    })
  }

  toggleExerciseDetails({ currentTarget }) {
    this.toggleCheckboxLessDetails({
      input:  currentTarget,
      target: this.exerciseDetailsTarget
    })
  }

  toggleGenitourinaryConditionsDetails({ currentTarget }) {
    this.toggleCheckboxLessDetails({
      input:  currentTarget,
      target: this.genitourinaryConditionsDetailsTarget
    })
  }

  toggleInfectionDetails({ currentTarget }) {
    this.toggleRadioButtonMoreDetails({
      input:  currentTarget,
      target: this.infectionDetailsTarget
    })
  }

  toggleMammogramDetails({ currentTarget }) {
    if (currentTarget.checked) {
      this.otherSpecialInvestigationsNoneTarget.checked = false
    }

    this.toggleCheckboxMoreDetails({
      input:  currentTarget,
      target: this.mammogramDetailsTarget
    })
  }

  toggleMenstrualIrregularitiesDetails({ currentTarget }) {
    if (currentTarget.checked) {
      this.menstrualNoneTarget.checked = false
    }

    this.toggleCheckboxMoreDetails({
      input:  currentTarget,
      target: this.menstrualIrregularitiesDetailsTarget
    })
  }

  toggleMenopausalDetails({ currentTarget }) {
    this.toggleRadioButtonMoreDetails({
      input:  currentTarget,
      target: this.menopausalDetailsTarget
    })
  }

  toggleNeoplasticDetails({ currentTarget }) {
    this.toggleRadioButtonMoreDetails({
      input:  currentTarget,
      target: this.neoplasticDetailsTarget
    })
  }

  toggleOtherBreastAbnormalitiesDetails({ currentTarget }) {
    this.toggleRadioButtonMoreDetails({
      input:  currentTarget,
      target: this.otherBreastAbnormalitiesDetailsTarget
    })
  }

  toggleOtherInvestigationsDetails({ currentTarget }) {
    if (currentTarget.checked) {
      this.otherSpecialInvestigationsNoneTarget.checked = false
    }

    this.toggleCheckboxMoreDetails({
      input:  currentTarget,
      target: this.otherInvestigationsDetailsTarget
    })
  }

  toggleOtherMenstrualDetails({ currentTarget }) {
    if (currentTarget.checked) {
      this.menstrualNoneTarget.checked = false
    }

    this.toggleCheckboxMoreDetails({
      input:  currentTarget,
      target: this.otherMenstrualDetailsTarget
    })
  }

  togglePapSmearDetails({ currentTarget }) {
    this.toggleCheckboxLessDetails({
      input:  currentTarget,
      target: this.papSmearDetailsTarget
    })
  }

  togglePelvicDetails({ currentTarget }) {
    this.toggleRadioButtonMoreDetails({
      input:  currentTarget,
      target: this.pelvicDetailsTarget
    })
  }

  togglePolycysticOvarianSyndromeDetails({ currentTarget }) {
    this.toggleRadioButtonMoreDetails({
      input:  currentTarget,
      target: this.polycysticOvarianSyndromeDetailsTarget
    })
  }

  togglePostcoitalBleedingDetails({ currentTarget }) {
    this.toggleRadioButtonMoreDetails({
      input:  currentTarget,
      target: this.postcoitalBleedingDetailsTarget
    })
  }

  toggleReproductiveDetails({ currentTarget }) {
    this.toggleRadioButtonMoreDetails({
      input:  currentTarget,
      target: this.reproductiveDetailsTarget
    })
  }

  toggleUltrasoundsDetails({ currentTarget }) {
    if (currentTarget.checked) {
      this.otherSpecialInvestigationsNoneTarget.checked = false
    }

    this.toggleCheckboxMoreDetails({
      input:  currentTarget,
      target: this.ultrasoundsDetailsTarget
    })
  }

  toggleVaginismusDetails({ currentTarget }) {
    this.toggleRadioButtonMoreDetails({
      input:  currentTarget,
      target: this.vaginismusDetailsTarget
    })
  }

  toggleWeightDetails({ currentTarget }) {
    this.toggleRadioButtonMoreDetails({
      input:  currentTarget,
      target: this.weightDetailsTarget
    })
  }

  // ==== Getters

  // ==== Setters

  // ==== Private

  addNestedTextInput({ listTarget, wrapperTarget }) {
    this.addNestedForm({
      list:      listTarget,
      inputType: "input[type='text'], select",
      wrapper:   wrapperTarget
    })
  }

  removeNestedTextInput({ buttonTarget, listTarget, wrapperTarget }) {
    this.removeNestedForm({
      button:    buttonTarget,
      wrapper:   wrapperTarget,
      list:      listTarget,
      inputType: "input[type='text'], select"
    })
  }

  toggleCheckboxLessDetails({ input, target }) {
    if (input.checked) {
      target.classList.add(this.hideClass)
    } else {
      target.classList.remove(this.hideClass)
    }
  }

  toggleRadioButtonMoreDetails({ input, target }) {
    if (input.value === 'true') {
      target.classList.remove(this.hideClass)
    } else if (input.value === 'false') {
      target.classList.add(this.hideClass)
    }
  }

  toggleRadioButtonLessDetails({ input, target }) {
    if (input.value === 'false') {
      target.classList.remove(this.hideClass)
    } else if (input.value === 'true') {
      target.classList.add(this.hideClass)
    }
  }

  // ==== Channels

}
