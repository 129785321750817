import ApplicationController from '../../support/application_controller'

export default class extends ApplicationController {

  static targets = [
    'addAction',
    'codesPanel',
    'codeSlot',
    'searchPanel',
    'searchSlot',
    'blankSlate', // TODO: remove once improved icd10 has been fully implement (HEAL-4523)
    'code', // TODO: remove once improved icd10 has been fully implement (HEAL-4523)
    'list' // TODO: remove once improved icd10 has been fully implement (HEAL-4523)
  ]

  static values = {
    improvedIcd10Implementation: {
      type: Boolean, default: false
    }
  }

  static outlets = [
    'search-v2'
  ]

  // ==== Actions

  addItem(xhr) {
    this.renderFlashMessage(xhr)

    if (!this.improvedIcd10ImplementationValue) {
      // TODO: remove once improved icd10 has been fully implement (HEAL-4523)
      this.listTarget.insertAdjacentHTML('beforeend', xhr.response)
      this.toggleBlankSlate()
      this.searchV2Outlet.clearSearch()
      this.addActionTarget.classList.remove('u-hide')
    }
  }

  removeItem = ({ detail: [response, _status, xhr] }) => {
    this.renderFlashMessage(xhr)

    if (this.improvedIcd10ImplementationValue) {
      this.searchPanelTarget.innerHTML = response.search_html
      this.codesPanelTarget.innerHTML  = response.codes_html
      this.updateCounter(response.codes_count)
    } else { // TODO: remove once improved icd10 has been fully implement (HEAL-4523)
      const element = document.querySelector(`li[data-id='${response.id}']`)
      element.remove()
      this.toggleBlankSlate()
    }
  }

  // TODO: remove once improved icd10 has been fully implement (HEAL-4523)
  toggleBlankSlate() {
    if (this.codeTargets.length === 0) {
      this.blankSlateTarget.classList.remove('u-hide')
    } else {
      this.blankSlateTarget.classList.add('u-hide')
    }
  }

  toggleSelectedSlot() {
    this.tabSlots.forEach((target) => {
      const bodyId         = target.getAttribute('aria-controls')
      const bodyElement    = this.element.querySelector(`#${bodyId}`)
      const wrapperElement = target.closest('.c-ds2-tabs__tab-wrapper')

      if (target.getAttribute('aria-selected') === 'false') {
        target.setAttribute('aria-selected', 'true')
        wrapperElement.classList.add('-state-selected')

        if (bodyElement) {
          bodyElement.setAttribute('aria-hidden', 'false')
          bodyElement.removeAttribute('hidden')
          bodyElement.classList.add('selected')
        }
      } else {
        target.setAttribute('aria-selected', 'false')
        wrapperElement.classList.remove('-state-selected')

        if (bodyElement) {
          bodyElement.setAttribute('aria-hidden', 'true')
          bodyElement.setAttribute('hidden', '')
          bodyElement.classList.remove('selected')
        }
      }
    })
  }

  // ==== Getters

  get tabCounter() {
    return this.codeSlotTarget.getElementsByClassName('-item-tab__counter')[0]
  }

  get tabSlots() {
    return [this.codeSlotTarget, this.searchSlotTarget]
  }

  // ==== Setters

  // ==== Private

  updateCounter(count) {
    this.tabCounter.innerHTML = count

    if (count === 0) {
      this.tabCounter.classList.add('u-hide')
    } else {
      this.tabCounter.classList.remove('u-hide')
    }
  }

  // ==== Channels

}
