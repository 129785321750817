import PopoverController                 from '../../frontend/controllers/popover_controller'
import { disableElement, enableElement } from '../../frontend/support/helpers'

export default class extends PopoverController {

  static targets = [
    'button',
    'popover'
  ]

  static values = {
    direction: String,
    position:  String,
    timeout:   Number
  }

  static classes = [
    'loading'
  ]

  initialize() {}

  connect() {
    if (this.isOpen) this.update()
  }

  // ==== Controllers

  // ==== Actions

  afterToggle(event) {
    super.afterToggle(event)
    if (this.isLoading && event.newState === 'closed' && !this.anyModalOpen) this.show()
  }

  loading({ currentTarget }) {
    this.element.classList.add(this.loadingClass)

    // Small timeout to allow the buttons to serialize and perform its action
    // before disabling.
    setTimeout(() => {
      for (const button of this.buttonTargets) {
        if (button !== currentTarget) disableElement(button)
      }
    }, 13)
    // Re-enable button after `this.timeoutValue`ms. Default 27 seconds.
    setTimeout(() => {
      for (const button of this.buttonTargets) {
        if (button !== currentTarget) enableElement(button)
      }
      this.element.classList.remove(this.loadingClass)
    }, this.timeoutValue)
  }

  // ==== Getters

  get anchor() {
    return this.element
  }

  get isLoading() {
    return this.element.classList.contains(this.loadingClass)
  }

  get anyModalOpen() {
    return !!document.querySelector('.c-modal.-state-open')
  }

  // ==== Setters

  // ==== Private

  update() {
    super.update()

    this.popoverTarget.style.width = `${this.anchorRect.width}px`
  }

  // ==== Channels

}
