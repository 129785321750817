import ApplicationController from '../support/application_controller'

export default class extends ApplicationController {

  static targets = [
    'row',
    'tableRowAction'
  ]

  // ==== Controllers

  // ==== Actions

  rowClicked(event) {
    const element = event.currentTarget

    window.location.href = element.dataset.showPath
  }

  hoverEnable(event) {
    this.rowTargets.forEach((element) => {
      if (event.target.dataset.rowId === element.dataset.rowId) element.classList.add('-hover')
    })
  }

  hoverDisable(event) {
    this.rowTargets.forEach((element) => {
      if (event.target.dataset.rowId === element.dataset.rowId) element.classList.remove('-hover')
    })
  }

  copyText(event) {
    navigator.clipboard.writeText(event.currentTarget.innerHTML)
  }

  // ==== Getters

  // ==== Setters

  // ==== Private

  // ==== Channels

}
