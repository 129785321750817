import Flatpickr from 'stimulus-flatpickr'

export default class extends Flatpickr {

  connect() {
    // this.config = {
    //   mode: 'range'
    // }
    super.connect()
  }

}
