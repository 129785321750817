import moment                from 'moment/moment'
import ApplicationController from '../../frontend/support/application_controller'
import I18n                  from '../../frontend/support/i18n'

export default class extends ApplicationController {

  static targets = [
    'timer'
  ]

  static values = {
    startedAt:       String,
    orangeThreshold: Number,
    redThreshold:    Number
  }

  static classes = [
    'error',
    'success',
    'warning'
  ]

  connect() {
    this.updateWaitTime = this.updateWaitTime.bind(this)
    this.intervalId     = setInterval(this.updateWaitTime, 1000)
  }

  disconnect() {
    clearInterval(this.intervalId)
  }

  // ==== Controllers

  // ==== Actions

  updateWaitTime() {
    const timeElapsed = this.duration.asSeconds()
    this.updateWaitTimeColor(timeElapsed)

    this.timerTarget.textContent = this.formattedTime
  }

  // ==== Getters

  get startedAt() {
    return moment(this.startedAtValue)
  }

  get duration() {
    return moment.duration(moment().diff(this.startedAt))
  }

  get formattedTime() {
    return I18n.l('time.formats.time_elapsed_leading', this.duration.asMilliseconds())
  }

  // ==== Setters

  // ==== Private

  updateWaitTimeColor(timeElapsed) {
    const timerClassList = this.timerTarget.classList
    timerClassList.remove(...this.constructor.classes)

    if (timeElapsed < this.orangeThresholdValue) {
      timerClassList.add(this.successClass)
    } else if (timeElapsed < this.redThresholdValue) {
      timerClassList.add(this.warningClass)
    } else {
      timerClassList.add(this.errorClass)
    }
  }

  // ==== Channels

}
