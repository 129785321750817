import ApplicationController from '../../support/application_controller'

export default class extends ApplicationController {

  static targets = [
    'notes'
  ]

  connect() {
    this.observer = new ResizeObserver(this.update.bind(this))

    if (this.hasNotesTarget) {
      this.observer.observe(this.notesTarget)
    }
  }

  disconnect() {
    this.observer.disconnect()
  }

  update() {
    this.element.setAttribute('style', `padding-bottom: ${this.notesTarget.clientHeight}px !important`)
  }

}
