import { addTwilioEventListeners, getTwilioConversationsClient } from '../../lib/twilio/conversations'

import ApplicationController from '../../support/application_controller'
import displayNotification   from '../../support/notifications'
import I18n                  from '../../support/i18n'
import { playSound }         from '../../support/audio_sound'

export default class NotificationsControllerBase extends ApplicationController {

  static values = {
    twilioConversationSid: String
  }

  #unreadCount = null

  connect() {
    if (!this.hasTwilioConversationSidValue) return

    this.client.onceWithReplay('initialized', () => {
      this.initConversation()
    })
  }

  disconnect() {
    if (this.removeEventListeners) this.removeEventListeners()
    delete this.conversation
  }

  // ==== Controllers

  // ==== Actions

  // ==== Getters

  get client() {
    return getTwilioConversationsClient()
  }

  get unreadCount() {
    return this.#unreadCount
  }

  // ==== Setters

  set unreadCount(value) {
    this.#unreadCount = value
    if (this.#unreadCount) {
      this.dispatch('unread', { detail: { unreadCount: value } })
      this.onUnread(this.#unreadCount)
    }
  }

  // ==== Private

  async initConversation() {
    try {
      this.conversation = await this.client.getConversationBySid(this.twilioConversationSidValue)
    } catch (error) {
      HF.consoleLog(error.message, {
        display: 'error',
        level:   'error'
      })
      return
    }

    this.unreadCount = await this.conversation.getUnreadMessagesCount()

    // There are cases where the controller got disconnected
    // while the unread messages were still being fetched
    if (!this.conversation) return

    this.removeEventListeners = addTwilioEventListeners(this.conversation, {
      messageAdded: (message) => {
        if (message.author === 'system') return
        if (message.author === this.client.user.identity) return

        this.newMessage()
      }
    })
  }

  newMessage() {
    this.unreadCount += 1
    playSound('#notification')
    displayNotification({
      body: I18n.t('kena.patients.consultations.chat.notification_body')
    })
  }

  onUnread() {
    throw new Error(`NotImplementedError: override 'onUnread' in '${this.constructor.name}'`)
  }

  // ==== Channels

}
