import { get, post, destroy } from '@rails/request.js'
import ApplicationController  from '../support/application_controller'
import $fq                    from '../support/fake_query'

export default class extends ApplicationController {

  static targets = []

  static values = {
    url:    String,
    params: Object
  }

  static classes = []

  static outlets = [
    'search-component--search'
  ]

  initialize() {}

  connect() {}

  // ==== Actions

  async performGet(event) {
    const element = event.currentTarget

    $fq(element).addClass('-loading')
    $fq(element).removeClass('-error')

    const response = await get(
      this.urlValue,
      {
        query:        this.formData(),
        responseKind: 'turbo-stream'
      }
    )

    if (response.ok) {
      $fq(element).removeClass('-loading')
      this.searchComponentSearchOutlet.clearSearch()
    } else {
      $fq(element).removeClass('-loading')
      $fq(element).addClass('-error')
    }
  }

  async performPost(event) {
    const element = event.currentTarget

    $fq(element).addClass('-loading')
    $fq(element).removeClass('-error')

    const response = await post(
      this.urlValue,
      {
        body:         this.formData(),
        responseKind: 'turbo-stream'
      }
    )

    if (response.ok) {
      $fq(element).removeClass('-loading')
    } else {
      $fq(element).removeClass('-loading')
      $fq(element).addClass('-error')
    }
  }

  async performDestroy() {
    await destroy(
      this.urlValue,
      {
        responseKind: 'turbo-stream'
      }
    )
  }

  // ==== Getters

  // ==== Setters

  // ==== Private

  formData() {
    const data = new FormData()

    if (this.paramsValue) {
      Object.keys(this.paramsValue).forEach((key) => data.append(key, this.paramsValue[key]))
    }

    return data
  }

  // ==== Channels

}
