import ApplicationController from '../../frontend/support/application_controller'
import $fq                   from '../../frontend/support/fake_query'

export default class extends ApplicationController {

  static targets = []

  static values = {
    textDefault:  String,
    textExpanded: String
  }

  static classes = [
    'expanded',
    'label'
  ]

  initialize() {}

  connect() {}

  // ==== Controllers

  // ==== Actions

  toggle(event) {
    const element     = event.currentTarget
    const bodyId      = element.getAttribute('aria-controls')
    const bodyElement = this.element.querySelector(`[id='${bodyId}']`)
    const textElement = this.element.querySelector(`.${this.labelClass}`)

    element.setAttribute('aria-selected', !this.isSelected(element))

    if (this.isSelected(element)) {
      $fq(textElement).text(this.textExpandedValue)
      element.setAttribute('aria-expanded', 'true')
      bodyElement.setAttribute('aria-hidden', 'false')
      bodyElement.removeAttribute('hidden')
      $fq(element).addClass(this.expandedClass)
      $fq(bodyElement).addClass(this.expandedClass)
    } else {
      $fq(textElement).text(this.textDefaultValue)
      element.setAttribute('aria-expanded', 'false')
      bodyElement.setAttribute('aria-hidden', 'true')
      bodyElement.setAttribute('hidden', '')
      $fq(element).removeClass(this.expandedClass)
      $fq(bodyElement).removeClass(this.expandedClass)
    }
  }

  // ==== Getters / Setters

  // ==== Private

  isSelected(element) {
    return element.getAttribute('aria-selected') === 'true'
  }

  // ==== Channels

}
