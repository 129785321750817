import ApplicationController from '../support/application_controller'

export default class extends ApplicationController {

  // ==== Controllers

  // ==== Actions

  rowClicked({ currentTarget }) {
    window.open(currentTarget.dataset.link, '_blank')
  }

  // ==== Getters

  // ==== Setters

  // ==== Private

  // ==== Channels

}
