import ApplicationController               from '../../support/application_controller'
import { isScrolledBottom, isScrolledTop } from '../../support/helpers'

export default class extends ApplicationController {

  static targets = [
    'scrolling'
  ]

  static classes = [
    'top',
    'bottom'
  ]

  initialize() {
    this.scroll = this.scroll.bind(this)
  }

  connect() {
    this.scroll()

    this.resizeObserver = new ResizeObserver(this.scroll)
    this.resizeObserver.observe(this.scrollingTarget)

    this.mutationObserver = new MutationObserver(this.scroll)
    this.mutationObserver.observe(this.element, { attributes: true })
  }

  disconnect() {
    window.removeEventListener('resize', this.scroll)
    this.resizeObserver.disconnect()
    this.mutationObserver.disconnect()
  }

  // ==== Controllers

  // ==== Actions

  scroll() {
    this.scrollTop    = isScrolledTop(this.viewport)
    this.scrollBottom = isScrolledBottom(this.viewport)
  }

  // ==== Getters

  get viewport() {
    if (this.scrollingTarget.overlayScrollbars) {
      return this.scrollingTarget.overlayScrollbars.getElements().viewport
    }
    return this.scrollingTarget
  }

  get scrollTop() {
    return this.element.classList.contains(this.topClass)
  }

  get scrollBottom() {
    return this.element.classList.contains(this.bottomClass)
  }

  // ==== Setters

  set scrollTop(value) {
    if (this.scrollTop === value) return

    this.element.classList.toggle(this.topClass, value)
    if (value) {
      this.dispatchEvent('top')
    }
  }

  set scrollBottom(value) {
    if (this.scrollBottom === value) return

    this.element.classList.toggle(this.bottomClass, value)
    if (value) {
      this.dispatchEvent('bottom')
    }
  }

  // ==== Private

  dispatchEvent(name) {
    this.scrollingTarget.dispatchEvent(new CustomEvent(`scroll:${name}`, {
      bubbles:    true,
      cancelable: true
    }))
  }

  // ==== Channels

}
