const BmiCalculator = (controller) => {
  Object.assign(controller, {
    connect() {
      this.calculateBMI()
    },

    calculateBMI() {
      if (this.hasHeightTarget && this.hasWeightTarget) {
        const height = this.heightTarget.value
        const weight = this.weightTarget.value

        if (weight && height) {
          const bmi = parseFloat(weight) / ((parseInt(height, 10) / 100) ** 2)
          // do we also not need to check if bmiTarget is defined?
          this.bmiTarget.textContent = Number((bmi).toFixed(1))
        } else {
          this.bmiTarget.textContent = 0
        }
      }
    }
  })
}

export default BmiCalculator
