import ConsultationNoteController from './consultation_note_controller'
import HfDirectUpload             from '../support/hf_direct_upload'
import HfDirectUploadEvents       from '../support/hf_direct_upload_events'

let directUploadEvents

export default class extends ConsultationNoteController {

  static targets = this.targets.concat([
    'fileInput',
    'filePreview',
    'selection',
    'form',
    'submitButton'
  ])

  initialize() {
    directUploadEvents = new HfDirectUploadEvents()
  }

  connect() {
    if (!this.hasFileInputTarget) return

    directUploadEvents.progressWrapperClass  = 'a-directUploadProgressOverlay'
    directUploadEvents.uploadController      = this
    directUploadEvents.fileInputTarget       = this.fileInputTarget
    directUploadEvents.submitButtonTarget    = this.submitButtonTarget
    directUploadEvents.useGallery            = false
    directUploadEvents.progressWrapperHTML   = this.progressWrapperHTML.bind(this)
    directUploadEvents.updateProgressElement = this.updateProgressElement
    directUploadEvents.skipSubmitOnError     = true
    directUploadEvents.bindAllEvents()
  }

  // ==== Controllers

  // ==== Actions

  takePhoto(event) {
    if (event && event.currentTarget.dataset.preventDefault === 'true') event.preventDefault()
    if (this.hasSelectionTarget) this.selectionTarget.value = event.currentTarget.dataset.selection
    this.fileInputTarget.click()
  }

  uploadPhoto(_event) {
    const fileBlob = this.fileInputTarget.files[0]
    this.fileInputTarget.value = ''
    if (fileBlob) {
      const uploader = new HfDirectUpload(this.fileInputTarget, fileBlob)
      uploader.start()
      this.formTarget.classList.add('u-hide')
    }
  }

  tryAgain() {
    this.filePreviewTarget.innerHTML = ''
    this.takePhoto()
  }

  uploadVaccinePhoto() {
    this.takePhoto()
  }

  // ==== Getters

  // ==== Setters

  // ==== Private

  progressWrapperHTML(id) {
    return `
      <div class="a-directUploadProgressOverlay__container">
        <div id="directUploadProgressNumber-${id}" class="a-directUploadProgressOverlay__number">
          <small>Uploading photo</small>
          <span>0%</span>
        </div>
        <div class="a-directUploadProgressOverlay__progress-bar-wrapper">
          <div id="directUploadProgress-${id}" class="a-directUploadProgressOverlay__bar" style="width: 0"></div>
        </div>
      </div>
      <div class="a-directUploadProgressOverlay__error">
        <div>
          <h2 class="c-text u-text-xxxl u-font-bold u-text-primary-on-dark">Failed</h2>
          <p class="c-text u-text-xl u-text-secondary-on-dark">Photo failed to upload. Please try again.</p>
        </div>
        <div>
          <hr class="c-divider -theme-dark -style-solid">
          <button type="button" data-action="${this.identifier}#tryAgain" class="c-button -with-text-only -size-m -width-full u-my-m -theme-dark">
            Try again
          </button>
        </div>
      </div>
    `
  }

  showPreview(image) {
    this.filePreviewTarget.appendChild(image)
  }

  updateProgressElement(progressElement, progress) {
    progressElement.style.width = `${progress}%`
  }

  // ==== Channels

  // ==== Callbacks

}
