import ApplicationController from '../support/application_controller'

export default class extends ApplicationController {

  static targets = [
    'autoOpenTabLink',
    'clickOpenTabLink'
  ]

  static values = {
  }

  static classes = []

  initialize() { }

  connect() {
    if (this.hasAutoOpenTabLinkTarget) {
      this.autoOpenTabLinkTargets.forEach((element) => {
        window.open(element.dataset.url)
      })
    }
  }

  // ==== Controllers

  // ==== Actions

  openTabLinks(e) {
    e.preventDefault()
    if (this.hasClickOpenTabLinkTarget) {
      this.clickOpenTabLinkTargets.forEach((element) => {
        window.open(element.dataset.url)
      })
    }
  }

  // ==== Channels

}
