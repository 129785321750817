import ApplicationController             from '../../support/application_controller'
import { enableElement, enableElements } from '../../support/helpers'

export default class extends ApplicationController {

  // ==== Controllers

  static targets = [
    'subject'
  ]

  static values = {
    includeChildren: Boolean
  }

  // ==== Actions

  disable({ params: { includeChildren = this.includeChildren } }) {
    this.#disable({ includeChildren })
  }

  enable({ params: { includeChildren = this.includeChildren } }) {
    this.#enable({ includeChildren })
  }

  toggleCheckbox({ currentTarget, params: { inverse = false, includeChildren = this.includeChildren } }) {
    let value = currentTarget.checked
    if (inverse) value = !value
    this.#enable({ value, includeChildren })
  }

  // ==== Getters

  get includeChildren() {
    if (this.hasIncludeChildrenValue) return this.includeChildrenValue
    return true
  }

  // ==== Setters

  // ==== Private

  #enable({ value = true, includeChildren = this.includeChildren } = {}) {
    for (const subject of this.subjectTargets) {
      enableElement(subject, value)
      if (includeChildren) enableElements(subject, value)
    }
  }

  #disable({ value = true, ...options } = {}) {
    this.#enable(!value, options)
  }

  // ==== Channels

}
