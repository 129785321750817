import debounce              from 'lodash/debounce'
import { post }              from '@rails/request.js'
import ApplicationController from '../../frontend/support/application_controller'
import $fq                   from '../../frontend/support/fake_query'

export default class extends ApplicationController {

  static targets = [
    'form',
    'icon',
    'query',
    'results',
    'spinner'
  ]

  static values = {
    ajaxSearch:    Boolean,
    autofocus:     Boolean,
    autosearch:    Boolean,
    reloadOnClear: Boolean,
    url:           String
  }

  static classes = [
    'active'
  ]

  initialize() {
    if (this.autofocusValue) {
      this.queryTarget.focus()
    }

    if (this.autosearchValue) {
      this.performSearch()
    }

    this.performSearch = debounce(this.performSearch.bind(this), 250)
  }

  connect() {}

  // ==== Controllers

  // ==== Actions

  hideSpinner(event) {
    if (event) { event.stopPropagation() }

    const self = this

    setTimeout(() => {
      $fq(this.iconTarget).show()
      $fq(self.spinnerTarget).hide()
    }, 500)
  }

  showSpinner(event) {
    if (event) { event.stopPropagation() }

    $fq(this.iconTarget).hide()
    $fq(this.spinnerTarget).show()
  }

  clearSearch() {
    $fq(this.resultsTarget).innerHTML('')

    if (this.query) {
      this.queryTarget.value = ''
    }

    this.queryTarget.focus()

    if (this.reloadOnClearValue) {
      this.performSearch()
    } else {
      this.setState()
    }
  }

  performSearch() {
    this.setState()

    if (this.ajaxSearchValue) {
      this.performAjaxSearch()
    } else {
      this.formTarget.requestSubmit()
    }
  }

  renderResults(event) {
    if (event) { event.stopPropagation() }

    const [_data, _status, xhr] = event.detail

    $fq(this.resultsTarget).innerHTML(xhr.response)
  }

  enterSubmit() {} // noop action for stimulus to bind to 'keydown.enter': :'enterSubmit:prevent'

  // ==== Getters

  get query() {
    return this.queryTarget.value
  }

  // ==== Setters

  // ==== Private

  async performAjaxSearch() {
    this.showSpinner()

    const response = await post(
      this.urlValue,
      {
        body: this.formData()
      }
    )

    if (response.ok) {
      const body = await response.html

      $fq(this.resultsTarget).innerHTML(body)
      this.hideSpinner()
    }
  }

  setState() {
    if (this.query) {
      $fq(this.element).addClass(this.activeClass)
    } else {
      $fq(this.element).removeClass(this.activeClass)
    }
  }

  formData() {
    const data = new FormData()
    data.append('q', this.query)

    return data
  }

  // ==== Channels

}
