import debounce              from 'lodash/debounce'
import ApplicationController from '../../support/application_controller'
import { autoReFocus }       from '../../support/helpers'

let focusField
let autoSaveTriggered

export default class extends ApplicationController {

  static targets = [
    'ghostSubmit',
    'medication',
    'medicationInput',
    'noneChronicMedication',
    'output',
    'pregnancyHistoryString',
    'smoker',
    'smokingHabitsString',
    'submit'
  ]

  initialize() {
    this.autoSave = debounce(this.autoSave, 1000)
  }

  // ==== Controllers

  // ==== Actions

  ajaxSuccess(event) {
    const [_data, _status, xhr] = event.detail
    this.renderFlashMessage(xhr)

    this.outputTarget.innerHTML = xhr.response
    this.autoSave.cancel()

    if (autoSaveTriggered) {
      autoReFocus(focusField)
    }
  }

  autoSave(event) {
    focusField = event.target.id
    autoSaveTriggered = true
    this.ghostSubmitTarget.click()
  }

  disableStartButton() {
    const button = document.querySelector('button[data-start-consultation]')

    if (button) {
      button.setAttribute('disabled', 'disabled')
    }
  }

  enableStartButton() {
    const button = document.querySelector('button[data-start-consultation]')

    if (button) {
      button.removeAttribute('disabled')
    }
  }

  addMedicationField() {
    const medicationInput = this.medicationInputTargets[0]
    const clone = medicationInput.cloneNode(true)

    clone.classList.remove('u-hide')
    const input = clone.querySelector('input')
    input.value = ''
    input.id    = `ch-${this.medicationInputTargets.length + 1}`

    this.medicationTarget.appendChild(clone)
    this.noneChronicMedicationTarget.checked = false
  }

  removeChronicMedication(event) {
    if (event.currentTarget.checked) {
      this.medicationInputTargets.forEach((element) => {
        element.classList.add('u-hide')
        element.querySelector('input').value = ''
      })
    }
  }

  selectTrait(event) {
    const element         = event.currentTarget
    const traitGroup      = element.getAttribute('data-trait-group')
    const checkboxes      = Array.from(document.querySelectorAll(`[id*=${traitGroup}]`))
    const other           = document.querySelector(`[id*=${traitGroup}_other]`)
    const noneCheckbox    = checkboxes.find((cb) => cb.value === 'none')
    const isTargetNoneBox = element.value === 'none'

    if (noneCheckbox !== undefined) {
      if (noneCheckbox.checked && isTargetNoneBox) {
        checkboxes.filter((cb) => cb.value !== 'none').forEach((el) => {
          el.checked = false
        })
      } else {
        noneCheckbox.checked = false
      }
    }

    if (traitGroup === 'pregnancy_history') {
      if (element.value === 'not_pregnant') {
        this.pregnancyHistoryStringTargets.forEach((el) => {
          el.value = ''
        })
      }
    }

    if (traitGroup === 'smoking_habits') {
      if (element.value === 'patient_is_non_smoker') {
        this.smokingHabitsStringTargets.forEach((el) => {
          el.value = ''
        })
      }
    }

    if (other && isTargetNoneBox) {
      other.value = ''
    }

    this.ghostSubmitTarget.click()
  }

  uncheckNoneChronicMedication(event) {
    if (this.medicationInputTargets.length === 1 &&
      this.noneChronicMedicationTarget.checked &&
      event.currentTarget.value !== '') {
      this.noneChronicMedicationTarget.checked = false
    }
  }

  // ==== Getters

  // ==== Setters

  // ==== Private

  // ==== Channels

}
