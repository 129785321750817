import $fq from './fake_query'

export default (klass) => class EscalationMixin extends klass {

  static targets = [
    ...super.targets,
    'bloodPressureDiastolic',
    'bloodPressureSystolic',
    'outOfRangeGlyph',
    'outOfRangeGlyphBloodPressureDiastolic',
    'outOfRangeGlyphBloodPressureSystolic',
    'outOfRangeGlyphHba1c',
    'outOfRangeGlyphHemoglobinLevel',
    'outOfRangeGlyphMidUpperArmCircumference',
    'outOfRangeGlyphOxygenSaturation',
    'outOfRangeGlyphPSA',
    'outOfRangeGlyphPulse',
    'outOfRangeGlyphRandomGlucose',
    'outOfRangeGlyphTemperature',
    'outOfRangeGlyphUricAcid',
    'outOfRangeGlyphUrineBilirubin',
    'outOfRangeGlyphUrineBlood',
    'outOfRangeGlyphUrineGlucose',
    'outOfRangeGlyphUrineKeytones',
    'outOfRangeGlyphUrineLeucocytes',
    'outOfRangeGlyphUrineProtein',
    'outOfRangePopover',
    'outOfRangePopoverBloodPressureSystolic',
    'outOfRangePopoverHba1c',
    'outOfRangePopoverHemoglobinLevel',
    'outOfRangePopoverMidUpperArmCircumference',
    'outOfRangePopoverOxygenSaturation',
    'outOfRangePopoverPSA',
    'outOfRangePopoverPulse',
    'outOfRangePopoverRandomGlucose',
    'outOfRangePopoverTemperature',
    'outOfRangePopoverUricAcid',
    'outOfRangePopoverUrineBilirubin',
    'outOfRangePopoverUrineBlood',
    'outOfRangePopoverUrineGlucose',
    'outOfRangePopoverUrineKeytones',
    'outOfRangePopoverUrineLeucocytes',
    'outOfRangePopoverUrineProtein'
  ]

  static values = {
    ...super.values,
    rangeCheckUrl: String
  }

  // ==== Actions

  toggleOutOfRangePopoverBloodPressureDiastolic() {
    // This must open the popover target for `blood_pressure_systolic`
    this.toggleOutOfRangePopover({ targetStr: 'BloodPressureSystolic' })
  }

  toggleOutOfRangePopoverBloodPressureSystolic() {
    this.toggleOutOfRangePopover({ targetStr: 'BloodPressureSystolic' })
  }

  toggleOutOfRangePopoverMidUpperArmCircumference() {
    this.toggleOutOfRangePopover({ targetStr: 'MidUpperArmCircumference' })
  }

  toggleOutOfRangePopoverOxygenSaturation() {
    this.toggleOutOfRangePopover({ targetStr: 'OxygenSaturation' })
  }

  toggleOutOfRangePopoverHba1c() {
    this.toggleOutOfRangePopover({ targetStr: 'Hba1c' })
  }

  toggleOutOfRangePopoverHemoglobinLevel() {
    this.toggleOutOfRangePopover({ targetStr: 'HemoglobinLevel' })
  }

  toggleOutOfRangePopoverPSA() {
    this.toggleOutOfRangePopover({ targetStr: 'PSA' })
  }

  toggleOutOfRangePopoverPulse() {
    this.toggleOutOfRangePopover({ targetStr: 'Pulse' })
  }

  toggleOutOfRangePopoverRandomGlucose() {
    this.toggleOutOfRangePopover({ targetStr: 'RandomGlucose' })
  }

  toggleOutOfRangePopoverTemperature() {
    this.toggleOutOfRangePopover({ targetStr: 'Temperature' })
  }

  toggleOutOfRangePopoverUricAcid() {
    this.toggleOutOfRangePopover({ targetStr: 'UricAcid' })
  }

  toggleOutOfRangePopoverUrineBlood() {
    this.toggleOutOfRangePopover({ targetStr: 'UrineBlood' })
  }

  toggleOutOfRangePopoverUrineGlucose() {
    this.toggleOutOfRangePopover({ targetStr: 'UrineGlucose' })
  }

  toggleOutOfRangePopoverUrineLeucocytes() {
    this.toggleOutOfRangePopover({ targetStr: 'UrineLeucocytes' })
  }

  toggleOutOfRangePopoverUrineProtein() {
    this.toggleOutOfRangePopover({ targetStr: 'UrineProtein' })
  }

  toggleOutOfRangePopoverUrineKeytones() {
    this.toggleOutOfRangePopover({ targetStr: 'UrineKeytones' })
  }

  toggleOutOfRangePopoverUrineBilirubin() {
    this.toggleOutOfRangePopover({ targetStr: 'UrineBilirubin' })
  }

  // ==== Private

  toggleOutOfRangePopover({ targetStr } = {}) {
    const popover = this[`outOfRangePopover${targetStr}Target`]

    if ($fq(popover).hasClass('u-hide')) {
      this.outOfRangePopoverTargets.forEach((elPopover) => {
        if (elPopover !== popover && !$fq(elPopover).hasClass('u-hide')) {
          $fq(elPopover).hide()
        }
      })

      this.outOfRangeGlyphTargets.forEach((elGlyph) => {
        $fq(elGlyph).changeGlyph('#exclamation-circle')
      })

      if (targetStr === 'BloodPressureSystolic') {
        this.toggleOutOfRangeGlyph({ glyphStr: '#exclamation-circle-outline', targetStr: 'BloodPressureSystolic' })
        this.toggleOutOfRangeGlyph({ glyphStr: '#exclamation-circle-outline', targetStr: 'BloodPressureDiastolic' })
      } else {
        this.toggleOutOfRangeGlyph({ glyphStr: '#exclamation-circle-outline', targetStr })
      }

      $fq(popover).show()
    } else {
      if (targetStr === 'BloodPressureSystolic') {
        this.toggleOutOfRangeGlyph({ glyphStr: '#exclamation-circle', targetStr: 'BloodPressureSystolic' })
        this.toggleOutOfRangeGlyph({ glyphStr: '#exclamation-circle', targetStr: 'BloodPressureDiastolic' })
      } else {
        this.toggleOutOfRangeGlyph({ glyphStr: '#exclamation-circle', targetStr })
      }

      $fq(popover).hide()
    }
  }

  toggleOutOfRangeGlyph({ glyphStr, targetStr } = {}) {
    const glyph = this[`outOfRangeGlyph${targetStr}Target`]

    $fq(glyph).changeGlyph(glyphStr)
  }

}
