import ApplicationController from '../../frontend/support/application_controller'

export default class extends ApplicationController {

  static targets = [
    'button',
    'switch'
  ]

  static values = {}

  static classes = [
    'active',
    'disabled'
  ]

  initialize() {}

  connect() {}

  // ==== Controllers

  // ==== Actions

  submit() {
    this.switchTarget.classList.add(this.disabledClass)
    this.buttonTarget.click()
  }

  toggleActive() {
    this.switchTarget.classList.toggle(this.activeClass)
  }

  // ==== Getters

  // ==== Setters

  // ==== Private

  // ==== Channels

}
