import Dropzone          from 'dropzone'
import { DirectUpload }  from '@rails/activestorage'

export class HfDropzoneDirectUpload {

  constructor(source, file) {
    this.directUpload = new DirectUpload(file, source.url, this)
    this.source       = source
    this.file         = file
  }

  start() {
    this.file.controller = this
    this.hiddenInput     = this.createHiddenInput()

    this.directUpload.create((error, attributes) => {
      if (error) {
        this.hiddenInput.remove()
        this.emitDropzoneError(error)
      } else {
        this.hiddenInput.value = attributes.signed_id
        this.emitDropzoneSuccess()
      }
    })
  }

  createHiddenInput() {
    const hiddenInput = document.createElement('input')

    hiddenInput.type = 'hidden'
    hiddenInput.name = this.source.fileInputTarget.name
    this.source.fileInputTarget.insertAdjacentElement('beforebegin', hiddenInput)

    return hiddenInput
  }

  directUploadWillStoreFileWithXHR(xhr) {
    this.bindProgressEvent(xhr)
    this.emitDropzoneUploading()
  }

  bindProgressEvent(xhr) {
    this.xhr = xhr
    this.xhr.upload.addEventListener('progress', (event) => {
      this.uploadRequestDidProgress(event)
    })
  }

  uploadRequestDidProgress(event) {
    const progress        = (event.loaded / event.total) * 100
    const previewTemplate = this.file.previewTemplate
    let progressText      = `${Math.round(progress)}% complete`

    if (progress === 100) {
      progressText = 'Complete'
    }

    previewTemplate.querySelector('[data-dz-uploadprogresstext]').textContent = progressText
    previewTemplate.querySelector('[data-dz-uploadprogress]').style.width = `${progress}%`
  }

  emitDropzoneUploading() {
    this.file.status = Dropzone.UPLOADING
    this.source.dropZone.emit('processing', this.file)
  }

  emitDropzoneError(error) {
    this.file.status = Dropzone.ERROR
    this.source.dropZone.emit('error', this.file, error)
    this.source.dropZone.emit('complete', this.file)
  }

  emitDropzoneSuccess() {
    this.file.status = Dropzone.SUCCESS
    this.source.dropZone.emit('success', this.file)
    this.source.dropZone.emit('complete', this.file)
  }

}

export default HfDropzoneDirectUpload
