import ApplicationController from '../../frontend/support/application_controller'

export default class extends ApplicationController {

  connect() {
    if (this.thead) this.updateScrollOffset()
    this.observer?.observe(this.element)
  }

  disconnect() {
    this.observer?.unobserve(this.element)
  }

  // ==== Controllers

  // ==== Actions

  // ==== Getters

  get thead() {
    return this.element.querySelector('thead')
  }

  get scrolling() {
    return this.element.querySelector('[data-scrollbars-target="content"]')
  }

  get observer() {
    if (!window.IntersectionObserver) return null

    this.obs ??= new IntersectionObserver(
      (entries) => this.observed(entries),
      { root: document.documentElement }
    )
    return this.obs
  }

  // ==== Private

  updateScrollOffset() {
    const theadHeight = this.thead.clientHeight
    this.element.style.setProperty('--scrollbar-offset-top', `${theadHeight}px`)
    this.scrolling?.overlayScrollbars?.update(true)
  }

  observed(entries) {
    if (entries.some((e) => e.isIntersecting)) {
      this.updateScrollOffset()
    }
  }

  // ==== Channels

}
