import WizardController from '../wizard_controller'

export default class extends WizardController {

  static targets = [
    'allergiesDetails',
    'asthmaDetails',
    'cancerDetails',
    'cancerGrandparentDetails',
    'cancerMotherDetails',
    'cancerFatherDetails',
    'cancerDaughterDetails',
    'cancerSonDetails',
    'diabetesDetails',
    'highCholesterolDetails',
    'hypertensionDetails',
    'noneInverse',
    'otherConditionsDetails',
    'otherConditionsDetailsInput'
  ]

  static values = {}

  static classes = []

  initialize() {}

  connect() {}

  // ==== Controllers

  // ==== Actions

  toggleNoneInverse(event) {
    this.toggleNoneInverseSelection({
      input:  event.currentTarget,
      target: this.noneInverseTarget
    })
  }

  toggleAllergiesDetails(event) {
    this.toggleCheckboxMoreDetails({
      input:  event.currentTarget,
      target: this.allergiesDetailsTarget
    })
  }

  toggleAsthmaDetails(event) {
    this.toggleCheckboxMoreDetails({
      input:  event.currentTarget,
      target: this.asthmaDetailsTarget
    })
  }

  toggleCancerDetails(event) {
    this.toggleCheckboxMoreDetails({
      input:  event.currentTarget,
      target: this.cancerDetailsTarget
    })
  }

  toggleCancerGrandparentDetails(event) {
    this.toggleCheckboxMoreDetails({
      input:  event.currentTarget,
      target: this.cancerGrandparentDetailsTarget
    })
  }

  toggleCancerMotherDetails(event) {
    this.toggleCheckboxMoreDetails({
      input:  event.currentTarget,
      target: this.cancerMotherDetailsTarget
    })
  }

  toggleCancerFatherDetails(event) {
    this.toggleCheckboxMoreDetails({
      input:  event.currentTarget,
      target: this.cancerFatherDetailsTarget
    })
  }

  toggleCancerDaughterDetails(event) {
    this.toggleCheckboxMoreDetails({
      input:  event.currentTarget,
      target: this.cancerDaughterDetailsTarget
    })
  }

  toggleCancerSonDetails(event) {
    this.toggleCheckboxMoreDetails({
      input:  event.currentTarget,
      target: this.cancerSonDetailsTarget
    })
  }

  toggleDiabetesDetails(event) {
    this.toggleCheckboxMoreDetails({
      input:  event.currentTarget,
      target: this.diabetesDetailsTarget
    })
  }

  toggleHighCholesterolDetails(event) {
    this.toggleCheckboxMoreDetails({
      input:  event.currentTarget,
      target: this.highCholesterolDetailsTarget
    })
  }

  toggleHypertensionDetails(event) {
    this.toggleCheckboxMoreDetails({
      input:  event.currentTarget,
      target: this.hypertensionDetailsTarget
    })
  }

  toggleOtherConditionsDetails(event) {
    this.toggleCheckboxMoreDetails({
      input:  event.currentTarget,
      target: this.otherConditionsDetailsTarget
    })
  }

  addOtherConditionsDetailsItem() {
    this.addNestedForm({
      list:      this.otherConditionsDetailsInputTargets,
      inputType: 'textarea',
      wrapper:   this.otherConditionsDetailsTarget
    })
  }

  removeOtherConditionsDetailsItem(event) {
    this.removeNestedForm({
      button:    event.currentTarget,
      wrapper:   this.otherConditionsDetailsTarget,
      list:      this.otherConditionsDetailsInputTargets,
      inputType: 'textarea'
    })
  }

  // ==== Getters

  // ==== Setters

  // ==== Private

  // ==== Channels

}
