import Dropzone               from 'dropzone'
import Rails                  from '@rails/ujs'
import ApplicationController  from '../support/application_controller'
import $fq                    from '../support/fake_query'
import HfDropzoneDirectUpload from '../support/hf_dropzone_direct_upload'

export default class extends ApplicationController {

  static targets = [
    'dropZoneArea',
    'fileInput',
    'form',
    'previewTemplate',
    'uploadedDocuments'
  ]

  initialize() {}

  connect() {
    Dropzone.autoDiscover = false // Prevent Error: Dropzone already attached.

    this.dropZone = this.initializeDropzone()
    this.hideFileInput()
    this.bindEvents()
  }

  // ==== Controllers

  // ==== Actions

  uploadComplete(event) {
    const [_response, _status, xhr] = event.detail

    $fq(this.uploadedDocumentsTarget).html(xhr.response)
  }

  destroyComplete(event) {
    const [_response, _status, xhr] = event.detail

    $fq(this.uploadedDocumentsTarget).html(xhr.response)
  }

  // ==== Getters

  get headers() {
    const metaTag = document.head.querySelector('meta[name="csrf-token"]')

    return { 'X-CSRF-Token': metaTag.dataset.content }
  }

  get url() {
    return this.fileInputTarget.dataset.directUploadUrl
  }

  get maxFiles() {
    return this.data.get('maxFiles') || 1
  }

  get maxFileSize() {
    return this.data.get('maxFileSize') || 256
  }

  get acceptedFiles() {
    return this.data.get('acceptedFiles')
  }

  get previewTemplate() {
    const node = document.importNode(this.previewTemplateTarget.content, true)

    return node.firstChild.innerHTML
  }

  // ==== Setters

  // ==== Private

  initializeDropzone() {
    return new Dropzone(this.dropZoneAreaTarget, {
      url:             this.url,
      headers:         this.headers,
      maxFiles:        this.maxFiles,
      maxFilesize:     this.maxFileSize,
      acceptedFiles:   this.acceptedFiles,
      autoQueue:       false,
      previewTemplate: this.previewTemplate,
      dictFileTooBig:  'File is too big ({{filesize}}MB). Max filesize is {{maxFilesize}}MB.'
    })
  }

  hideFileInput() {
    this.fileInputTarget.disabled      = true
    this.fileInputTarget.style.display = 'none'
  }

  bindEvents() {
    this.dropZone.on('addedfile', (file) => {
      setTimeout(() => {
        if (file.accepted) {
          new HfDropzoneDirectUpload(this, file).start()
        }
      }, 500)
    })

    this.dropZone.on('removedfile', (file) => {
      if (file.controller) {
        file.controller.hiddenInput.remove()
      }
    })

    this.dropZone.on('canceled', (file) => {
      if (file.controller) {
        file.controller.xhr.abort()
      }
    })

    this.dropZone.on('queuecomplete', () => {
      if (this.hasFormTarget) {
        Rails.fire(this.formTarget, 'submit')

        setTimeout(() => {
          this.dropZone.removeAllFiles(true)
        }, 1000)
      }
    })
  }

  // ==== Channels

}
