import { get }               from '@rails/request.js'
import ApplicationController from '../support/application_controller'

export default class extends ApplicationController {

  static targets = [
    'placeholder',
    'results'
  ]

  initialize() {}

  connect() {
    this.loading = false
    this.shrinkResultsIfNecessary()
  }

  placeholderTargetConnected(placeholder) {
    this.observer?.observe(placeholder)
  }

  placeholderTargetDisconnected(placeholder) {
    this.observer?.unobserve(placeholder)
  }

  // ==== Controllers

  // ==== Actions

  // ==== Getters

  #observer = null

  get observer() {
    this.#observer ??= new IntersectionObserver(
      (entries) => this.observed(entries),
      { root: this.element }
    )
    return this.#observer
  }

  // ==== Setters

  // ==== Private

  observed(entries) {
    if (entries.some((e) => e.isIntersecting)) {
      this.loadNext()
    }
  }

  async loadNext() {
    if (this.loading) return

    this.loading = true
    await get(this.placeholderTarget.dataset.nextUrl, { responseKind: 'turbo-stream' })
    this.loading = false
  }

  shrinkResultsIfNecessary() {
    if (!this.hasResultsTarget) return

    if (this.resultsTarget.clientHeight < this.element.clientHeight) {
      this.element.style.height = `${this.resultsTarget.clientHeight}px`
    }
  }

  // ==== Channels

}
