import $fq              from '../../support/fake_query'
import WizardController from '../wizard_controller'

export default class extends WizardController {

  static targets = [
    'reasonOtherInput',
    'reasonOther'
  ]

  static values = {}

  static classes = [
    'hide'
  ]

  initialize() {}

  connect() {}

  // ==== Controllers

  // ==== Actions

  toggleReasonOther(event) {
    const element = event.currentTarget

    if (element.value === 'other') {
      $fq(this.reasonOtherTarget).removeClass(this.hideClass)
    } else {
      this.reasonOtherInputTarget.value = ''
      $fq(this.reasonOtherTarget).addClass(this.hideClass)
    }
  }

  // ==== Getters

  // ==== Setters

  // ==== Private

  // ==== Channels

}
