import debounce              from 'lodash/debounce'
import Rails                 from '@rails/ujs'
import ApplicationController from '../../support/application_controller'

export default class extends ApplicationController {

  static targets = [
    'loader',
    'membershipNumber',
    'membershipNumberWrapper',
    'profile',
    'results',
    'skip',
    'toggle'
  ]

  static outlets = [
    'patients--medical-scheme'
  ]

  initialize() {
    this.startSearch = debounce(this.startSearch.bind(this), 250)

    if (this.autoSearch) {
      this.startSearch()
    }
  }

  // ==== Actions

  startSearch(_event) {
    const membershipNumber = this.membershipNumberTarget.value

    if (membershipNumber === '' || membershipNumber.length < 9) {
      this.resultsTarget.innerHTML = ''
      return
    }

    this.membershipNumberWrapperTarget.classList.remove('-error')
    this.patientsMedicalSchemeOutlet.toggleSelectionError('hide')

    Rails.ajax({
      url:        this.url,
      type:       'GET',
      data:       this.params({ membership_number: membershipNumber }),
      dataType:   'script',
      beforeSend: (xhr, options) => {
        this.resultsTarget.innerHTML = ''
        this.showLoader()
        return (xhr, options)
      },
      success:    (_response, _status, xhr) => {
        this.resultsTarget.innerHTML = xhr.response
      },
      error:      (_response, _status, xhr) => {
        this.resultsTarget.innerHTML = xhr.response
      },
      complete:   (_xhr, _status) => {
        this.hideLoader()
      }
    })
  }

  hideLoader() {
    this.loaderTarget.classList.add('u-hide')
  }

  showLoader() {
    this.loaderTarget.classList.remove('u-hide')
  }

  select(event) {
    const element      = event.currentTarget
    const labelElement = element.closest(`[for='${element.id}']`)

    this.profileTargets.forEach((target) => {
      this.toggleInputs(target, 'disable')
    })

    this.toggleTargets.forEach((target) => {
      if (target.id !== element.id) {
        target.checked = false
      }
    })

    const selectedProfiles = this.toggleTargets.filter((input) => (input.checked)).length

    if (selectedProfiles === 0) {
      this.valid = 'false'
    } else {
      this.valid = 'true'
    }

    this.toggleError()

    if (labelElement) {
      if (element.checked) {
        this.toggleInputs(labelElement, 'enable')
        this.toggleSkip('hide')
      } else {
        this.toggleInputs(labelElement, 'disable')
        this.toggleSkip('show')
      }
    }
  }

  addTestData(event) {
    const element = event.target

    this.membershipNumberTarget.value = element.dataset.membershipNumber

    this.startSearch()
  }

  // ==== Getters

  get autoSearch() {
    return this.data.get('autoSearch') === 'true'
  }

  get url() {
    return this.data.get('url')
  }

  get valid() {
    return this.data.get('valid')
  }

  // ==== Setters

  set valid(value) {
    this.data.set('valid', value)
  }

  // ==== Private

  toggleInputs(element, state) {
    element.querySelectorAll("[type='hidden']").forEach((input) => {
      if (state === 'disable') {
        input.setAttribute('disabled', 'disabled')
      } else if (state === 'enable') {
        input.removeAttribute('disabled')
      }
    })
  }

  toggleSkip(state) {
    if (this.hasSkipTarget) {
      if (state === 'hide') {
        this.skipTarget.classList.add('u-hide')
      } else if (state === 'show') {
        this.skipTarget.classList.remove('u-hide')
      }
    }
  }

  toggleProfiles(event) {
    const element = event.currentTarget

    if (element.checked) {
      this.valid = 'true'
    } else {
      this.valid = 'false'
    }

    this.toggleError()

    this.profileTargets.forEach((target) => {
      this.toggleInputs(target, 'disable')
      if (element.checked) {
        target.classList.add('u-hide')
      } else {
        target.classList.remove('u-hide')
      }
    })
  }

  toggleError() {
    if (this.valid === 'true') {
      this.patientsMedicalSchemeOutlet.toggleSelectionError('hide')
    } else if (this.valid === 'false') {
      this.patientsMedicalSchemeOutlet.toggleSelectionError('show')
    }
  }

  params(data) {
    return Object.keys(data).map((key) => `${key}=${encodeURIComponent(data[key])}`).join('&')
  }

}
