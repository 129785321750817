import ApplicationController from '../support/application_controller'

export default class extends ApplicationController {

  static targets = [
    'searchResult',
    'search'
  ]

  // ==== Controllers

  // ==== Actions

  select(event) {
    const searchUrl    = this.searchTarget.dataset['searchComponent-SearchUrlValue']
    const targetId     = event.srcElement.value
    const targetStatus = event.srcElement.checked

    this.searchTarget.dataset['searchComponent-SearchUrlValue'] = this.changeUrl(searchUrl, targetId, targetStatus)
    this.changeResultsParams(this.searchResultTargets, targetId, targetStatus)
  }

  // ==== Getters

  // ==== Setters

  // ==== Private

  changeUrl(url, id, add) {
    const urlParams = new URLSearchParams(url.split('?')[1])
    let selectedIds = urlParams.getAll('checked_icd10_codes_ids[]')

    if (add) {
      selectedIds.push(id)
    } else {
      selectedIds = selectedIds.filter((currentId) => currentId !== id)
    }

    return this.newUrl(url, urlParams, selectedIds)
  }

  newUrl(url, urlParams, ids) {
    urlParams.delete('checked_icd10_codes_ids[]')
    ids.forEach((code) => urlParams.append('checked_icd10_codes_ids[]', code))

    return `${url.split('?')[0]}?${urlParams.toString()}`
  }

  changeResultsParams(results, id, add) {
    results.forEach((result) => {
      const resultParams = JSON.parse(result.dataset.ajaxTurboStreamParamsValue)

      if (add) {
        resultParams.checked_icd10_codes_ids.push(id)
      } else {
        resultParams.checked_icd10_codes_ids = resultParams.checked_icd10_codes_ids.filter((currentId) => currentId !== id)
      }

      result.dataset.ajaxTurboStreamParamsValue = JSON.stringify(resultParams)
    })
  }

  // ==== Channels

}
