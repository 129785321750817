import ApplicationController from '../../support/application_controller'

export default class extends ApplicationController {

  static targets = [
    'submit'
  ]

  // ==== Controllers

  // ==== Actions

  submitForm(event) {
    const element = event.target

    this.submitTarget.click()

    element.disabled = true
    element.closest('div').classList.add('disabled')
  }

  // ==== Getters

  // ==== Setters

  // ==== Private

  // ==== Channels

}
