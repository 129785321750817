import ConsultationNoteController from '../consultation_note_controller'

export default class extends ConsultationNoteController {

  static targets = this.targets.concat([
    'dataSharingConsentPhotoSection',
    'procedureConsentPhotoSection'
  ])

  initialize() {
    super.initialize()
  }

  // ==== Controllers

  // ==== Actions

  showDataSharingConsentPhotoSection(event) {
    this.dataSharingConsentPhotoSectionTarget.hidden = event.target.value === 'false'
  }

  showProcedureConsentPhotoSection(event) {
    this.procedureConsentPhotoSectionTarget.hidden = event.target.value === 'false'
  }

  // ==== Getters

  // ==== Setters

  // ==== Private

  // ==== Channels

  // ==== Callbacks

}
