import { get }               from '@rails/request.js'
import ApplicationController from '../support/application_controller'

export default class extends ApplicationController {

  static values = {
    refreshUrl:   String,
    refreshTimer: { type: Number, default: 3 }
  }

  connect() {
    this.startRefreshTimer()
  }

  startRefreshTimer() {
    this.refreshTimer = setInterval(() => {
      this.refreshContent()
    }, this.refreshTimerValue * 1000)
  }

  refreshContent() {
    clearInterval(this.refreshTimer)

    get(this.refreshUrlValue, { responseKind: 'turbo-stream' })
  }

}
