import ApplicationController from '../support/application_controller'
import { playSound } from '../support/audio_sound'

import {
  metaContent
} from '../support/helpers'

/**
 * NOTE: replaces doctor_lobby when new_gp_lobby is enabled
 */
export default class extends ApplicationController {

  static targets = [
    'answerButton',
    'counter',
    'videomedCall'
  ]

  videomedCallTargetConnected(videomedCall) {
    this.removeOffHoldCards(videomedCall)
    this.#updateCounter(videomedCall.dataset.listName)
  }

  videomedCallTargetDisconnected(videomedCall) {
    this.#updateCounter(videomedCall.dataset.listName)
  }

  answerButtonTargetConnected(answerButton) {
    // don't ring if answer button is gone
    if (!answerButton.isConnected) return
    // only ring if queue tab is open
    if (!answerButton.checkVisibility()) return

    // stfu, i'm devving!
    if (window.debugMode) {
      HF.consoleLog('RING RING!!!')
    } else {
      playSound('#notification')
    }
  }

  removeOffHoldCards(callCard) {
    const calls = this.#getVideomedCalls(callCard.dataset.listName)

    if (callCard.dataset.doctorId !== '' && callCard.dataset.doctorId !== this.currentUserId) {
      callCard.remove()
    }

    if (callCard.id !== calls[0].id) {
      callCard.querySelector('[data-gp-lobby-target="answerButton"]')?.remove()
    }
  }

  // ==== Actions

  // ==== Getters

  get currentUserId() {
    return metaContent('app:user:id')
  }

  // ==== Setters

  // ==== Private

  #updateCounter(listName) {
    this.#getCounterTarget(listName).textContent = this.#getVideomedCallCount(listName)
  }

  #getCounterTarget(listName) {
    return this.counterTargets
      .find((counter) => counter.dataset.listName === listName)
  }

  #getVideomedCallCount(listName) {
    return this.#getVideomedCalls(listName).length
  }

  #getVideomedCalls(listName) {
    return this.videomedCallTargets
      .filter((videomedCall) => videomedCall.dataset.listName === listName)
  }

}
