import ApplicationController from '../support/application_controller'
import CreateChannel         from '../client/cable'

export default class extends ApplicationController {

  static targets = [
    'callsList',
    'consultationQueue',
    'tab'
  ]

  connect() {}

  initialize() {
    this.subscribeNurseLobbyChannel()
  }

  disconnect() {}

  // ==== Controllers

  // ==== Actions

  switchTab(event) {
    const element = event.currentTarget
    const [_data, _status, xhr] = event.detail
    this.resetActiveStates()
    element.classList.add('-active')

    this.consultationQueueTarget.innerHTML = xhr.response
  }

  // ==== Setters

  // ==== Private

  insertCall(html) {
    if (this.hasCallsListTarget) {
      this.callsListTarget.insertAdjacentHTML('beforeend', html)
    }
  }

  removeCall(id) {
    if (this.hasCallsListTarget) {
      const item = this.callsListTarget.querySelector(`[data-videomed-call-id='${id}']`)
      item.forEach((element) => {
        element.remove()
      })
    }
  }

  resetActiveStates() {
    this.tabTargets.forEach((element) => {
      element.classList.remove('-active')
    })
  }

  // ==== Channels

  subscribeNurseLobbyChannel() {
    if (this.channelSubscription === undefined) {
      const self = this

      this.channelSubscription = CreateChannel({
        channel: 'NurseLobbyChannel'
      }, {
        received(data) {
          switch (data.action) {
            case 'force:on_hold':
            case 'transition:answered:on_hold':
            case 'transition:ringing:on_hold':
              self.removeCall(data.item_id)
              self.insertCall(data.item_html)
              break

            case 'transition:ringing:answered':
            case 'transition:on_hold:ringing':
            case 'abandoned':
            case 'cancelled':
            case 'rejected':
            case 'closed':
              self.removeCall(data.item_id)
              break

            default:
              break
          }
        }
      })
    }

    return this.channelSubscription
  }

}
