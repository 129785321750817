import ApplicationController from '../support/application_controller'

export default class extends ApplicationController {

  connect() {
    this.timeoutClose = setTimeout(this.close, 5000)
  }

  disconnect() {
    clearTimeout(this.timeoutClose)
  }

  // ==== Controllers

  // ==== Actions

  close = () => {
    const flashes = document.querySelectorAll("[data-controller='flash']")
    flashes.forEach((el) => {
      el.remove()
    })
  }

  // ==== Getters

  // ==== Setters

  // ==== Private

  // ==== Channels

}
