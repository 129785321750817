import ApplicationController from '../support/application_controller'

export default class extends ApplicationController {

  static targets = [
    'fileField',
    'spinner',
    'button',
    'submit'
  ]

  // ==== Controllers

  // ==== Actions

  addFile() {
    this.fileFieldTarget.click()
  }

  submitForm() {
    this.buttonTarget.classList.add('u-hide')
    this.spinnerTarget.classList.remove('u-hide')
    this.submitTarget.click()
  }

  // ==== Getters

  // ==== Setters

  // ==== Private

  // ==== Channels

}
