import Autosize              from 'autosize'
import debounce              from 'lodash/debounce'
import ApplicationController from '../../support/application_controller'

export default class ConsultationNoteController extends ApplicationController {

  static targets = [
    'autosize',
    'form',
    'submit'
  ]

  initialize() {
    this.autoSave = debounce(this.autoSave, 1000)
  }

  connect() {
    Autosize(this.autosizeTargets)
  }

  // ==== Actions

  ajaxComplete(event) {
    const [_response, _status, xhr] = event.detail
    this.renderFlashMessage(xhr)
  }

  autoSave(_event) {
    this.submitTarget.click()
  }

  // ==== Getters

  // ==== Setters

  // ==== Private

  // ==== Channels

}
