import camelCase                 from 'lodash/camelCase'
import ApplicationController     from '../support/application_controller'
import CreateChannel             from '../client/cable'
import {
  HTMLtoDOM, disableElement, enableElement, replaceDOM
} from '../support/helpers'

export default class extends ApplicationController {

  static targets = [
    'callDoctor',
    'consultingHours',
    'doctorListWrapper',
    'doctorLobbyList',
    'hcpLobbyList',
    'onlineCount',
    'onlineStatus'
  ]

  static outlets = [
    'doctor-lobby'
  ]

  connect() {}

  initialize() {
    this.subscribeDoctorsAppearChannel()
  }

  disconnect() {
    // this.channelSubscription.unsubscribe()
    // this.channelSubscription.disconnected()
  }

  // ==== Actions

  toggleOnlineStatus() {
    if (this.onlineStatus) {
      this.channelSubscription.on_call()
      this.onlineStatus = true
    } else {
      this.channelSubscription.off_duty()
      this.onlineStatus = false
    }
  }

  // ==== Getters / Setters

  get currentUserId() {
    return this.data.get('currentUserId')
  }

  get onlineCount() {
    return this.data.get('onlineCount')
  }

  set onlineCount(value) {
    this.data.set('onlineCount', value)
  }

  get onlineStatus() {
    return this.onlineStatusTarget.checked
  }

  set onlineStatus(value) {
    this.data.set('online', value)
  }

  // ==== Private

  disableCallDoctor() {
    if (!this.hasCallDoctorTarget) return
    disableElement(this.callDoctorTarget)
  }

  enableCallDoctor() {
    if (!this.hasCallDoctorTarget) return
    enableElement(this.callDoctorTarget)
  }

  updateOnlineCount(title, count) {
    if (!this.hasOnlineCountTarget) return
    this.onlineCount                   = parseInt(count, 10)
    this.onlineCountTarget.textContent = title
  }

  toggleConsultingHours(count) {
    if (this.hasConsultingHoursTarget) {
      if (count === 0) {
        this.consultingHoursTarget.classList.remove('u-hide')
      } else {
        this.consultingHoursTarget.classList.add('u-hide')
      }
    }

    if (this.hasDoctorListWrapperTarget) {
      if (count === 0) {
        this.doctorListWrapperTarget.classList.add('u-hide')
      } else {
        this.doctorListWrapperTarget.classList.remove('u-hide')
      }
    }
  }

  // ==== Channels

  subscribeDoctorsAppearChannel() {
    if (this.channelSubscription === undefined) {
      const self = this

      this.channelSubscription = CreateChannel({
        channel: 'DoctorsAppearChannel'
      }, {
        connected() {
          if (self.hasOnlineStatusTarget && self.onlineStatusTarget.hasAttribute('disabled')) {
            self.onlineStatusTarget.removeAttribute('disabled')
          }
        },

        disconnected() {},

        received(data) {
          const countInt = parseInt(data.online_count, 10)

          this.handleCallButton(countInt)
          self.updateOnlineCount(data.online_title, data.online_count)

          switch (data.status) {
            case 'offline':
              this.insertComponent(data.user_id, 'doctorLobbyListTarget', data.doctor_lobby_component)
              this.removeComponent(data.user_id, 'hcpLobbyListTarget')
              break

            case 'on_call':
              this.insertComponent(data.user_id, 'doctorLobbyListTarget', data.doctor_lobby_component)
              this.insertComponent(data.user_id, 'hcpLobbyListTarget', data.hcp_lobby_component)
              break

            case 'off_duty':
              this.insertComponent(data.user_id, 'doctorLobbyListTarget', data.doctor_lobby_component)
              this.removeComponent(data.user_id, 'hcpLobbyListTarget')
              break

            case 'status_update':
              this.insertComponent(data.user_id, 'doctorLobbyListTarget', data.doctor_lobby_component)
              this.insertComponent(data.user_id, 'hcpLobbyListTarget', data.hcp_lobby_component)
              break

            default:
              break
          }

          self.toggleConsultingHours(countInt)
        },

        on_call() {
          if (self.onlineStatus) {
            this.perform('on_call')
            self.doctorLobbyOutlet.enableFirstAnswerButton()
          }
        },

        off_duty() {
          this.perform('off_duty')
          self.doctorLobbyOutlet.removeAllAnswerButtons()
        },

        handleCallButton(count) {
          if (count === 0) {
            self.disableCallDoctor()
          } else {
            self.enableCallDoctor()
          }
        },

        insertComponent(id, targetStr, html) {
          if (self[camelCase(`has_${targetStr}`)]) {
            const target    = self[camelCase(targetStr)]
            const component = HTMLtoDOM(html)
            const userId    = component.dataset.userId
            let position    = 'afterBegin'

            if (targetStr === 'doctorLobbyListTarget' && self.currentUserId !== userId) {
              position = 'beforeEnd'
            }

            // Replace component if it already exists
            if (target.querySelector(`[data-user-id='${id}']`)) {
              this.replaceComponent(id, targetStr, html)
            } else {
              target.insertAdjacentHTML(position, component.outerHTML)
            }

            // Sort cards
            const doctors = target.childNodes

            if (targetStr === 'hcpLobbyListTarget') {
              if (doctors) {
                Array.from(doctors).sort(
                  (a, b) => a.dataset.userInitials.localeCompare(b.dataset.userInitials, undefined, { numeric: false })
                ).forEach((el, _x) => {
                  el.parentNode.appendChild(el)
                })
              }
            } else if (targetStr === 'doctorLobbyListTarget') {
              if (doctors) {
                Array.from(doctors).sort(
                  (a, b) => a.dataset.userSortKey.localeCompare(b.dataset.userSortKey, undefined, { numeric: false })
                ).forEach((el, _x) => {
                  el.parentNode.appendChild(el)
                })
              }
            }
          }
        },

        replaceComponent(id, targetStr, html) {
          if (self[camelCase(`has_${targetStr}`)]) {
            const element = self[camelCase(targetStr)].querySelector(`[data-user-id='${id}']`)
            if (element) {
              replaceDOM(element, html)
            }
          }
        },

        removeComponent(id, targetStr) {
          if (self[camelCase(`has_${targetStr}`)]) {
            const element = self[camelCase(targetStr)].querySelector(`[data-user-id='${id}']`)
            if (element) {
              element.remove()
            }
          }
        }

      })
    }

    return this.channelSubscription
  }

}
