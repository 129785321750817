import ApplicationController from '../../support/application_controller'

export default class extends ApplicationController {

  static targets = [
    'doctorCreditNote',
    'doctorInvoice',
    'nurseCreditNote',
    'nurseInvoice',
    'originalDoctorInvoice',
    'originalNurseInvoice',
    'specialistInvoice',
    'voucherReceipt'
  ]

  // ==== Controllers

  // ==== Actions

  updateField({ currentTarget }) {
    this[`${currentTarget.dataset.target}Target`].value = 1
  }

  // ==== Getters

  // ==== Setters

  // ==== Private

  // ==== Channels

}
