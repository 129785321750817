import ApplicationController from '../../support/application_controller'

export default class extends ApplicationController {

  static targets = [
    'open',
    'snoozed'
  ]

  // ==== Controllers

  // ==== Actions

  show({ params: { tab } }) {
    this[`${tab}Target`].classList.remove('u-hide')
  }

  // ==== Getters

  // ==== Setters

  // ==== Private

  // ==== Channels

}
