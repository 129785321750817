import moment                from 'moment'
import { get }               from '@rails/request.js'
import ApplicationController from '../../support/application_controller'

// Connects to data-controller="consultation-note--vaccine"
export default class extends ApplicationController {

  static targets = [
    'brand',
    'currentField',
    'dateField',
    'historicalField',
    'locationContainer',
    'locationField',
    'notesField',
    'nudgeContainer',
    'nudgeText',
    'routeField'
  ]

  static values = {
    captureType: String,
    nudgeUrl:    String
  }

  get selectedBrandOption() {
    return this.brandTarget.options[this.brandTarget.selectedIndex]
  }

  connect() {
    this.setDefaultState()
    this.assignEventListeners()
    this.displayNudge()
  }

  assignEventListeners() {
    if (this.hasDateFieldTarget) {
      this.dateFieldTarget.addEventListener('change', (event) => {
        if (event.target.value === '') return
        this.verifyDate(event.target.value)
      })
    }
  }

  nudgeAvailable() {
    return (
      this.hasLocationFieldTarget &&
      this.hasNudgeContainerTarget &&
      this.hasNudgeTextTarget &&
      this.hasRouteFieldTarget &&
      this.nudgeUrlValue !== ''
    )
  }

  verifyDate(date) {
    const dateMomentObject = moment(date, 'DD/MM/YYYY')

    if (moment().startOf('day').isAfter(dateMomentObject)) {
      this.setHistoricalState()
    } else {
      this.setCurrentState()
    }
  }

  setDefaultState() {
    if (this.captureTypeValue === 'current') {
      this.setCurrentState()
    } else if (this.captureTypeValue === 'historical') {
      this.setHistoricalState()
    } else if (this.hasDateFieldTarget) {
      this.verifyDate(this.dateFieldTarget.value)
    } else {
      this.setCurrentState()
    }
  }

  setHistoricalState() {
    this.historicalFieldTargets.forEach((target) => {
      this.disableField(target, false)
    })

    this.currentFieldTargets.forEach((target) => {
      this.disableField(target, true)
    })
  }

  setCurrentState() {
    this.historicalFieldTargets.forEach((target) => {
      this.disableField(target, true)
    })

    this.currentFieldTargets.forEach((target) => {
      this.disableField(target, false)
    })
  }

  disableField(target, disabled) {
    target.hidden = disabled

    target.querySelectorAll('input').forEach((input) => {
      input.disabled = disabled
    })
  }

  async displayNudge() {
    if (!this.nudgeAvailable()) return

    const nudgeUrl = `${this.nudgeUrlValue}?route=${this.routeFieldTarget.value}` +
                      `&location=${this.locationFieldTarget.value}&date=${this.dateFieldTarget.value}`
    const response = await get(nudgeUrl)

    if (response.ok) {
      const body = await response.text

      if (body !== '') {
        this.nudgeTextTarget.innerHTML = body
        this.nudgeContainerTarget.classList.remove('u-hide')
      } else {
        this.nudgeTextTarget.innerHTML = ''
        this.nudgeContainerTarget.classList.add('u-hide')
      }
    }
  }

  addNote({ currentTarget }) {
    currentTarget.remove()
    this.notesFieldTarget.classList.remove('u-hide')
  }

  updateStock() {
    if (this.selectedBrandOption.dataset.governmentOnly === 'true') {
      this.stockLabel('private')?.classList.add('u-hide')
      this.stockInput('government').checked = true
    } else {
      this.stockLabel('private')?.classList.remove('u-hide')
    }
  }

  stockInput(value) {
    return document.getElementById(`consultation_note_vaccine_stock_${value}`)
  }

  stockLabel(value) {
    return this.element.querySelector(`label[for="consultation_note_vaccine_stock_${value}"]`)
  }

}
