import ApplicationController from '../support/application_controller'
import CreateChannel         from '../client/cable'

export default class extends ApplicationController {

  initialize() {
    // this.createHeartbeatChannel()
  }

  // ==== Controllers

  // ==== Actions

  // ==== Getters

  // ==== Setters

  // ==== Private

  beat() {
    fetch('/heartbeat.json', { method: 'GET', headers: { 'content-type': 'application/json' } })
      .catch((_error) => {})
  }

  // ==== Channels

  createHeartbeatChannel = async () => {
    const self = this

    await CreateChannel({
      channel: 'HeartbeatChannel'
    }, {
      connected() {
        setInterval(() => self.beat(), 30 * 1000)
      },

      received(_data) {
        this.perform('seen')
      }
    })
  }

}
