import Rails                 from '@rails/ujs'
import { Turbo }             from '@hotwired/turbo-rails'
import ApplicationController from '../../support/application_controller'

export default class extends ApplicationController {

  static targets = [
  ]

  static outlets = [
    'tabs-component--tabs',
    'consultation-note--icd10-codes'
  ]

  // ==== Actions

  select(_event) {
    Rails.ajax({
      url:      this.href,
      type:     this.type,
      data:     null,
      success:  (response, _status, xhr) => {
        if (xhr.getResponseHeader('content-type').includes('text/vnd.turbo-stream.html')) {
          Turbo.renderStreamMessage(xhr.response)
        }

        if (this.hasConsultationNoteIcd10CodesOutlet) {
          this.consultationNoteIcd10CodesOutlet.addItem(xhr)
        }
      },
      error:    (_response, _status, _xhr) => { },
      complete: (_xhr, _status) => { }
    })
  }

  selectCodeSlotTab(_event) {
    this.codeSlotTarget.setAttribute('aria-selected', 'true')
    this.searchSlotTarget.setAttribute('aria-selected', 'false')

    this.tabsComponentTabsOutlet.updateSelected()
  }

  // ==== Getters

  get codeSlotTarget() {
    return this.consultationNoteIcd10CodesOutlet.codeSlotTarget
  }

  get searchSlotTarget() {
    return this.consultationNoteIcd10CodesOutlet.searchSlotTarget
  }

  get href() {
    return this.data.get('href')
  }

  get tabSlots() {
    return [this.codeSlotTarget, this.searchSlotTarget]
  }

  get type() {
    return this.data.get('method') || 'GET'
  }

  // ==== Setters

  // ==== Private

  // ==== Channels

}
