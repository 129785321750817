import ApplicationController from '../../support/application_controller'

export default class extends ApplicationController {

  static targets = []

  static values = {}

  static classes = []

  initialize() {}

  connect() {}

  // ==== Controllers

  // ==== Actions

  // ==== Getters / Setters

  // ==== Private

  // ==== Channels

}
