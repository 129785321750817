import SimpleAssessmentController from './simple_assessment_controller'
import $fq                        from '../../support/fake_query'

export default class extends SimpleAssessmentController {

  static targets = [
    'hba1cDetails',
    'hba1cInput',
    'pathologyTestDetails',
    'pathologyTestInput'
  ]

  connect() {}

  initialize() {}

  disconnect() {}

  // ==== Controllers

  // ==== Actions

  toggleHba1c(event) {
    const element = event.currentTarget

    if (parseInt(element.value, 10) >= 6) {
      $fq(this.hba1cDetailsTarget).show()
    } else {
      this.resetInputs(this.hba1cInputTargets)
      $fq(this.hba1cDetailsTarget).hide()
    }
  }

  toggleConfirmatoryHivTest(event) {
    const element = event.currentTarget

    if (parseInt(element.value, 10) > 0) {
      $fq(this.confirmatoryHivTestDetailsTarget).show()
    } else {
      this.resetInputs(this.confirmatoryHivTestInputTargets)
      this.resetInputs(this.pathologyTestInputTargets)
      $fq(this.confirmatoryHivTestDetailsTarget).hide()
      $fq(this.pathologyTestDetailsTarget).hide()
    }
  }

  togglePathologyTest(event) {
    const element = event.currentTarget

    if (parseInt(element.value, 10) === 100) {
      $fq(this.pathologyTestDetailsTarget).show()
    } else {
      this.resetInputs(this.pathologyTestInputTargets)
      $fq(this.pathologyTestDetailsTarget).hide()
    }
  }

  // ==== Getters

  // ==== Setters

  // ==== Private

  // ==== Channels

}
