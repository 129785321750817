import { get }                    from '@rails/request.js'
import $fq                        from '../../support/fake_query'
import BmiCalculator              from '../../support/bmi_calculator'
import ConsultationNoteController from '../consultation_note_controller'
import EscalationMixin            from '../../support/escalation_mixin'

export default class extends EscalationMixin(ConsultationNoteController) {

  static targets = [
    ...super.targets,
    'bmi',
    'editFormContainer',
    'height',
    'hivConfirmationSection',
    'testStripDetails',
    'weight'
  ]

  static values = {
    ...super.values,
    showTestStrip: Boolean
  }

  static classes = [
    'hideTestStrip'
  ]

  initialize() {
    super.initialize()
  }

  connect() {
    BmiCalculator(this)
  }

  // ==== Controllers

  // ==== Actions

  async checkRange(event) {
    const element = event.currentTarget

    if (element.value === element.dataset.capturedValue) return

    const queryData = new FormData()

    queryData.append(element.name, element.value)

    const response = await get(
      this.rangeCheckUrlValue,
      {
        query:        queryData,
        responseKind: 'turbo-stream'
      }
    )

    if (response.ok) {
      this.outOfRangePopoverTargets.forEach((elPopover) => {
        if (!$fq(elPopover).hasClass('u-hide')) {
          $fq(elPopover).hide()
        }
      })

      this.outOfRangeGlyphTargets.forEach((elGlyph) => {
        $fq(elGlyph).changeGlyph('#exclamation-circle')
      })
    }
  }

  togglePrint(event) {
    const element = event.currentTarget

    if (element.innerHTML === '') {
      $fq(this.editFormContainerTarget).removeClass('u-hide')
      this.autoSaveEnabledValue = true
    } else {
      $fq(this.editFormContainerTarget).addClass('u-hide')
      this.autoSaveEnabledValue = false
    }
  }

  // ==== Getters

  // ==== Setters

  // ==== Private

  toggleTestStrip(event) {
    const element = event.currentTarget
    this.showTestStripValue = element.checked
  }

  toggleHivSelections(event) {
    const value = event.currentTarget.value

    if (value === '3' || value === '1') {
      $fq(this.hivConfirmationSectionTarget).removeClass('u-hide')
    } else {
      $fq(this.hivConfirmationSectionTarget).addClass('u-hide')
    }
  }

  // ==== Channels

  // ==== Callbacks

  showTestStripValueChanged() {
    if (this.showTestStripValue) {
      $fq(this.testStripDetailsTarget).removeClass(this.hideTestStripClass)
    } else {
      $fq(this.testStripDetailsTarget).addClass(this.hideTestStripClass)
    }
  }

}
