import ApplicationController from '../support/application_controller'

export default class extends ApplicationController {

  static targets = [
    'glyph',
    'spinner'
  ]

  initialize() {
    this.hideSpinner = this.hideSpinner.bind(this)
  }

  connect() {
    window.addEventListener('turbo:frame-render', this.hideSpinner, true)
  }

  disconnect() {
    window.removeEventListener('turbo:frame-render', this.hideSpinner, true)
  }

  // ==== Controllers

  // ==== Actions

  showSpinner({ detail: { url } }) {
    this.pendingUrl = url
    this.spinnerTarget.classList.remove('u-hide')
    this.glyphTarget.classList.add('u-hide')
  }

  hideSpinner({ target, detail: { fetchResponse: { location } } }) {
    if (target === this.element || this.pendingUrl === location.toString()) {
      this.pendingUrl = null
      this.spinnerTarget.classList.add('u-hide')
      this.glyphTarget.classList.remove('u-hide')
    }
  }

  // ==== Getters

  // ==== Setters

  // ==== Private

  // ==== Channels

}
