import ApplicationController from '../support/application_controller'
import $fq                   from '../support/fake_query'

export default class extends ApplicationController {

  static targets = [
    'container'
  ]

  static values = {
    collapsed: Boolean
  }

  static classes = [
    'collapsed'
  ]

  initialize() {}

  connect() {}

  // ==== Controllers

  // ==== Actions

  toggle(_event) {
    this.collapsedValue = !this.collapsedValue
  }

  // ==== Getters

  // ==== Setters

  // ==== Private

  // ==== Channels

  // ==== Callbacks

  collapsedValueChanged() {
    if (this.collapsedValue) {
      $fq(this.element).addClass(this.collapsedClass)
    } else {
      $fq(this.element).removeClass(this.collapsedClass)
    }
  }

}
