import ApplicationController from '../../frontend/support/application_controller'
import Axis from './axis'

export default class extends ApplicationController {

  static targets = [
    'barX',
    'barY',
    'content'
  ]

  static values = {
  }

  static classes = [
    'visibleX',
    'visibleY'
  ]

  initialize() {
    this.update = this.update.bind(this)
    this.resizeObserver = new ResizeObserver(() => {
      requestAnimationFrame(this.update)
    })
    this.mutationObserver = new MutationObserver(() => {
      requestAnimationFrame(this.update)
    })
  }

  connect() {
    requestAnimationFrame(this.update)
    this.resizeObserver.observe(this.contentTarget)
    this.mutationObserver.observe(this.contentTarget, {
      childList:     true,
      subtree:       true,
      characterData: true
    })
    this.axisX = new Axis(this.contentTarget, this.barXTarget, 'x')
    this.axisY = new Axis(this.contentTarget, this.barYTarget, 'y')
  }

  disconnect() {
    this.resizeObserver.disconnect()
    this.mutationObserver.disconnect()
    this.axisX.disconnect()
    this.axisY.disconnect()
  }

  // ==== Controllers

  // ==== Actions

  // ==== Getters

  // ==== Setters

  // ==== Private

  update() {
    const height = Math.max(this.contentTarget.clientHeight ** 2 / this.contentTarget.scrollHeight, 32)
    const width = Math.max(this.contentTarget.clientWidth ** 2 / this.contentTarget.scrollWidth, 32)
    this.contentTarget.style.setProperty('--scrollbar-length-y', `${height}px`)
    this.contentTarget.style.setProperty('--scrollbar-length-x', `${width}px`)
    this.contentTarget.classList.toggle(this.visibleYClass, this.contentTarget.clientHeight !== this.contentTarget.scrollHeight)
    this.contentTarget.classList.toggle(this.visibleXClass, this.contentTarget.clientWidth !== this.contentTarget.scrollWidth)
  }

  // ==== Channels

}
