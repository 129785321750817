import Autosize              from 'autosize'
import ApplicationController from '../support/application_controller'

export default class extends ApplicationController {

  initialize() {
    Autosize(this.element)
  }

  // ==== Controllers

  // ==== Actions

  // ==== Getters

  // ==== Setters

  // ==== Private

  // ==== Channels

}
