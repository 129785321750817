import ApplicationController from '../../support/application_controller'

export default class extends ApplicationController {

  static targets = [
    'button'
  ]

  static values = {
    confirmation: String
  }

  // ==== Controllers

  // ==== Actions

  validateInput(event) {
    const element = event.currentTarget

    if (element.value.trim() === this.confirmationValue) {
      this.buttonTarget.disabled = false
    } else {
      this.buttonTarget.disabled = true
    }
  }

  // ==== Getters

  // ==== Setters

  // ==== Private

  // ==== Channels

}
