import Autosize              from 'autosize'
import Rails                 from '@rails/ujs'
import debounce              from 'lodash/debounce'
import camelCase             from 'lodash/camelCase'
import I18n                  from '../support/i18n'
import ApplicationController from '../support/application_controller'
import $fq                   from '../support/fake_query'

export default class extends ApplicationController {

  static targets = [
    'favouritesTab',
    'formulariesTab',
    'freeTextTab',
    'medicationsTab',
    'patientsMedicationTab',

    'autosize',
    'freeTextForm',
    'freeTextInput',

    'medicationAdditionalInstructions',
    'medicationCount',
    'medicationForm',
    'medicationFormInput',
    'medicationSubmitButton',

    'search',
    'selection'
  ]

  static outlets = [
    'search-v2'
  ]

  initialize() {
    this.freeTextAutoSave = debounce(this.freeTextAutoSave, 8000)
    Autosize(this.autosizeTargets)
  }

  connect() {}

  // ==== Actions

  switchTab(event) {
    const [_data, _status, xhr] = event.detail

    $fq(this.element).replaceWith(xhr.response)
  }

  // ==== Favourites actions

  favouriteSelect(event) {
    const element = event.currentTarget

    $fq(element.closest('tr')).addClass('-loaderActive')

    Rails.ajax({
      url:      element.dataset.href,
      type:     'GET',
      data:     null,
      success:  (_response, _status, xhr) => {
        this.toggleTabClass('favourites', 'inactive')
        this.toggleTabClass('patients_medication', 'inactive')
        this.toggleTabClass('formularies', 'active')
        this.renderSelection(xhr.response)
      },
      error:    (_response, _status, _xhr) => { },
      complete: (_xhr, _status) => { }
    })
  }

  // ==== Formularies actions

  searchCallback(xhr) {
    // this.element.scrollIntoView()
    this.renderSelection(xhr.response)
    this.renderForm()
  }

  changeMedication(_event) {
    this.renderSelection('')
    this.toggleSearch('show')
    this.searchV2Outlet.queryTarget.focus()
  }

  toggleAdditionalInstructions(event) {
    const element           = event.currentTarget
    const glyphSvg          = element.querySelector('.a-ds2-glyph__svg')
    const glyphLabel        = element.querySelector('.a-ds2-glyph__label')
    const instructionTarget = this.medicationAdditionalInstructionsTarget

    if ($fq(instructionTarget).hasClass('u-hide')) {
      $fq(instructionTarget).show()
      $fq(glyphSvg).changeGlyph('clear')
      $fq(glyphLabel).text(I18n.t('layouts.medication_selection.clear_instructions'))
    } else {
      $fq(instructionTarget).hide()
      $fq(glyphSvg).changeGlyph('add')
      $fq(glyphLabel).text(I18n.t('layouts.medication_selection.add_instructions'))
      instructionTarget.querySelector('textarea').value = ''
    }
  }

  // ==== Free text actions

  freeTextAutoSave() {
    this.freeTextSubmit()
  }

  freeTextUpdateComplete(event) {
    const [_data, _status, xhr] = event.detail

    this.renderFlashMessage(xhr)
    $fq(this.element).replaceWith(xhr.response)

    if (this.freeTextValue !== '' && this.freeTextCount === 0) {
      this.updateMedicationCount(1)
      this.freeTextCount = 1
    }

    if (this.freeTextValue === '' && this.freeTextCount === 1) {
      this.updateMedicationCount(-1)
      this.freeTextCount = 0
    }
  }

  freeTextSubmit() {
    if (this.hasFreeTextFormTarget) {
      Rails.fire(this.freeTextFormTarget, 'submit')
    }
  }

  // ==== Medication actions

  medicationEdit(event) {
    const [_response, _status, xhr] = event.detail

    this.renderSelection(xhr.response)
    this.renderForm()
    this.handleDirtyMedicationForm()
  }

  medicationCreateComplete(event) {
    const [_response, _status, xhr] = event.detail

    if (xhr.status === 200) {
      this.toggleTabClass('medications', 'active')
      this.updateMedicationCount(1)
    }

    this.renderSelection(xhr.response)
  }

  medicationUpdateComplete(event) {
    const [_response, _status, xhr] = event.detail

    if (xhr.status === 200) {
      this.toggleTabClass('medications', 'active')
    }

    this.renderSelection(xhr.response)
  }

  medicationDeleteComplete(event) {
    const [_response, _status, xhr] = event.detail

    this.renderSelection(xhr.response)
    this.updateMedicationCount(-1)
  }

  // ==== Getters

  get freeTextCount() {
    return parseInt(this.data.get('freeTextCount') || 0, 10)
  }

  get freeTextValue() {
    return this.freeTextInputTarget.value
  }

  get medicationsTabPath() {
    return this.data.get('medicationsTabPath')
  }

  // ==== Setters

  set freeTextCount(value) {
    this.data.set('freeTextCount', value)
  }

  // ==== Private

  removeForm() {
    this.toggleSearch('show')
    this.renderSelection('')

    this.toggleTabClass('inactive')
  }

  renderForm() {
    this.toggleSearch('hide')
    this.clearSearch()

    this.toggleTabClass('formularies', 'active')
  }

  handleDirtyMedicationForm() {
    if (this.hasMedicationFormTarget && this.hasMedicationSubmitButtonTarget) {
      const originalText = this.medicationSubmitButtonTarget.textContent
      $fq(this.medicationSubmitButtonTarget.querySelector('div')).text(I18n.t('app.cancel'))

      this.medicationFormTarget.addEventListener('input', () => {
        $fq(this.medicationSubmitButtonTarget.querySelector('div')).text(originalText)
      })
    }
  }

  renderSelection(html) {
    if (!this.hasSelectionTarget) return

    $fq(this.selectionTarget).innerHTML(html)
  }

  clearSearch() {
    if (!this.hasSearchV2Outlet) return

    this.searchV2Outlet.clearSearch()
  }

  toggleTabClass(tab, state) {
    const targetTabStr = `${tab}_tab_target`

    if (!this[camelCase(`has_${targetTabStr}`)]) return

    if (state === 'active') {
      $fq(this[camelCase(targetTabStr)]).addClass('-active')
    } else if (state === 'inactive') {
      $fq(this[camelCase(targetTabStr)]).removeClass('-active')
    }

    if (tab === 'medications') {
      this.toggleTabClass('favourites', 'inactive')
      this.toggleTabClass('formularies', 'inactive')
      this.toggleTabClass('free_text', 'inactive')
      this.toggleTabClass('patients_medication', 'inactive')
    }
  }

  toggleSearch(state) {
    if (!this.hasSearchTarget) return

    if (state === 'show') {
      $fq(this.searchTarget).show()
    } else if (state === 'hide') {
      $fq(this.searchTarget).hide()
    }
  }

  updateMedicationCount(value) {
    if (this.hasMedicationCountTarget) {
      const count = parseInt(this.medicationCountTarget.textContent, 10)
      const total = count + value

      $fq(this.medicationCountTarget).textContent(total)

      if (total === 0) {
        $fq(this.medicationCountTarget).hide()
      } else {
        $fq(this.medicationCountTarget).show()
      }
    }
  }

  // ==== Channels

}
