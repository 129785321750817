import moment                from 'moment'
import partition             from 'lodash/partition'
import ApplicationController from '../../support/application_controller'
import I18n                  from '../../support/i18n'

export default class extends ApplicationController {

  static values = {
    showSpecialist: Boolean
  }

  static targets = [
    'responseBody',
    'responseBodyDoctor',
    'responseBodyDoctorsOnline',
    'responseBodyExpress',
    'templBlankSlate',
    'templDoctorRow',
    'templVideomedCallRow'
  ]

  initialize() {
    this.fetchJSON()
    this.fetchOnlineDoctors()

    setInterval(() => this.fetchJSON(), 30 * 1000)
    setInterval(() => this.fetchOnlineDoctors(), 30 * 1000)
  }

  // ==== Controllers

  // ==== Actions

  // ==== Getters

  // ==== Setters

  // ==== Private

  fetchJSON() {
    const self = this
    const baseUrl = '/api/v1/videomed-queue'

    const searchParams = new URLSearchParams()

    searchParams.append('status[]', 'ringing')
    searchParams.append('status[]', 'on_hold')

    if (this.showSpecialistValue) {
      searchParams.append('show_specialist', 'true')
    }

    const url = `${baseUrl}?${searchParams.toString()}`
    self.responseBodyTarget.innerHTML = ''
    self.responseBodyExpressTarget.innerHTML = ''

    fetch(url, { method: 'GET' })
      .then((resp) => resp.json())
      .then((json) => {
        const [expressCalls, compCalls] = partition(json.data, ['attributes.call_type', 'videomed_scripting'])

        self.addCallRows(compCalls, self.responseBodyTarget)
        self.addCallRows(expressCalls, self.responseBodyExpressTarget)
      })
      .catch((_error) => {})
  }

  fetchOnlineDoctors() {
    const self    = this
    const baseUrl = '/api/v1/doctors'

    const searchParams = new URLSearchParams({ status: 'online' })

    if (this.showSpecialistValue) {
      searchParams.append('show_specialist', 'true')
    }

    const url = `${baseUrl}?${searchParams.toString()}`

    this.responseBodyDoctorTarget.innerHTML = ''

    fetch(url, { method: 'GET' })
      .then((resp) => resp.json())
      .then((json) => {
        if (json.data.length === 0) {
          self.addBlankSlate(self.responseBodyDoctorTarget, `No ${this.doctorType().toLowerCase()} are online`)
        }

        self.addDoctorsOnline(self.responseBodyDoctorsOnlineTarget, json.meta.active_doctors)

        json.data.forEach((rowData) => {
          self.addDoctorData(rowData.attributes)
        })
      })
      .catch((_error) => {})
  }

  addCallRows(data, target) {
    if (data.length === 0) {
      this.addBlankSlate(target, 'No ringing Videomed calls')
    } else {
      data.forEach((rowData) => {
        this.addCallData(rowData.attributes, target)
      })
    }
  }

  addBlankSlate(target, text) {
    const row = document.importNode(this.templBlankSlateTarget.content, true)
    row.querySelector('td').textContent = text

    target.appendChild(row)
  }

  addCallData(data, target) {
    const row = document.importNode(this.templVideomedCallRowTarget.content, true)

    const eventTime   = moment(data.joined_at, 'YYYY-MM-DD HH-mm-ss')
    const currentTime = moment()
    const duration    = moment.duration(currentTime.diff(eventTime, 'ms'))
    let location      = data.medical_centre_location

    const h = `0${duration.hours()}`.substr(-2)
    const m = `0${duration.minutes()}`.substr(-2)
    const s = `0${duration.seconds()}`.substr(-2)

    if (duration.hours() > 1 || duration.minutes() >= 7) {
      row.querySelector('tr').classList.add('u-bgc-warning')
    }

    if (data.medical_centre_location.toLowerCase().match(/life/)) {
      row.querySelector('tr').classList.add('u-fw-bold')
      location = `* ${data.medical_centre_location}`
    }

    row.querySelector('[data-1]').textContent = data.queue_position
    row.querySelector('[data-2]').textContent = data.shared_queue_position
    row.querySelector('[data-3]').textContent = location
    row.querySelector('[data-4]').textContent = data.hcp_full_name
    row.querySelector('[data-5]').textContent = data.patient_full_name
    row.querySelector('[data-6]').textContent = `${h} hours ${m} minutes ${s} seconds`.replace(/^(?:00:)/, '')
    row.querySelector('[data-7]').textContent = data.status
    row.querySelector('[data-8]').textContent = this.translateCallType(data.call_type)

    target.appendChild(row)
  }

  addDoctorData(data) {
    const row = document.importNode(this.templDoctorRowTarget.content, true)

    row.querySelector('[data-1]').textContent = data.full_name
    row.querySelector('[data-2]').textContent = this.translateCallType(data.recent_call_type)
    row.querySelector('[data-3]').textContent = data.recent_answered_at
    row.querySelector('[data-4]').textContent = data.recent_call_hcp
    row.querySelector('[data-5]').textContent = data.recent_call_patient
    row.querySelector('[data-6]').textContent = data.recent_call_location
    row.querySelector('[data-7]').textContent = data.status

    this.responseBodyDoctorTarget.appendChild(row)
  }

  addDoctorsOnline(target, onlineCount) {
    target.innerHTML = `${this.doctorType()} online today: ${onlineCount}`
  }

  doctorType() {
    if (this.showSpecialistValue) {
      return 'Gynaecologists'
    }

    return 'Doctors'
  }

  translateCallType(callType) {
    if (!callType) return '-'
    if (callType === 'videomed_scripting') return 'Videomed Express'

    return `Videomed Call ${I18n.t(`activerecord.enums.consultation/videomed_call.call_types.${callType}`)}`
  }

  // ==== Channels

}
