import ApplicationController from '../support/application_controller'

export default class extends ApplicationController {

  static targets = []

  static values = {
    autoOpen: Boolean,
    url:      String
  }

  static classes = []

  initialize() {}

  connect() {
    if (this.autoOpenValue) {
      this.open()
    }
  }

  // ==== Controllers

  // ==== Actions

  open(event) {
    if (event) {
      event.preventDefault()
      event.currentTarget.blur()
    }

    this.openSecondScreen()
  }

  openGreeting(videomedId) {
    this.urlValue = `/videomed-greeting/${videomedId}?hfassopen=true`
    this.open()
  }

  openScreensaver() {
    this.urlValue = '/screensaver?hfassopen=true'
    this.open()
  }

  openVideoPlayer(roomId) {
    this.urlValue = `/video-player?room_id=${roomId}&hfassopen=true`
    this.open()
  }

  // ==== Getters

  // ==== Setters

  // ==== Private

  openSecondScreen() {
    const secondScreenWindow = window.open(this.urlValue, 'hfSecondScreenWindow', this.secondScreenWindowFeatureString())
    if (secondScreenWindow) {
      secondScreenWindow.focus()
    }
  }

  secondScreenWindowFeatureString() {
    const width          = 920
    const height         = Math.ceil(width / (16 / 9)) + 72
    const screenLeft     = window.screenLeft !== undefined ? window.screenLeft : window.screenX
    const screenRight    = window.screenTop !== undefined ? window.screenTop : window.screenY
    const left           = (window.screen.width / 2) - (width / 2) + screenLeft
    const top            = (window.screen.height / 2) - (height / 2) + screenRight
    const location       = 0
    const resizable      = 1
    const scrollbars     = 0
    const windowFeatures = {
      height,
      left,
      location,
      resizable,
      scrollbars,
      top,
      width
    }

    const featureString = Object.keys(windowFeatures).map((feature) => (
      `${feature}=${windowFeatures[feature]}`
    )).join(',')

    return featureString
  }

  // ==== Channels

}
