import ApplicationController from '../support/application_controller'

export default class extends ApplicationController {

  static targets = []

  static values = {}

  static classes = []

  static outlets = [
    'consultation'
  ]

  initialize() {}

  connect() {}

  // ==== Actions

  toggle(event) {
    const element = event.currentTarget

    if (element.innerHTML === '') {
      this.removeBackButton()
      this.consultationOutlet.showElements()
      this.consultationOutlet.removeInframe()
      this.consultationOutlet.restoreActions()
    } else {
      this.consultationOutlet.clearResponseBody()
      this.consultationOutlet.hideElements()
      this.consultationOutlet.addInframe()
      this.consultationOutlet.switchActions()
    }
  }

  // ==== Getters / Setters

  // ==== Private

  removeBackButton() {
    const backButton = document.getElementById('frameBackToConsultation')

    if (backButton && backButton.innerHTML !== '') {
      backButton.innerHTML = ''
    }
  }

  // ==== Channels

}
