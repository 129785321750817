import ApplicationController from '../support/application_controller'

export default class extends ApplicationController {

  static values = {
    videomedExpress: Boolean
  }

  static outlets = [
    'consultation'
  ]

  connect() {
    if (this.videomedExpressValue) {
      this.consultationOutlet?.disableElements()
    }
  }

  disconnect() {
    this.consultationOutlet?.enableElements()
  }

  // ==== Controllers

  // ==== Actions

  // ==== Getters

  // ==== Setters

  // ==== Private

  // ==== Channels

}
