import WizardMixin from '../../support/wizard_mixin'

export default (klass) => class SmokingHabitsMixin extends WizardMixin(klass) {

  static targets = [
    ...super.targets,
    'cbdDetails',
    'eCigarettesDetails',
    'isASmoker',
    'marijuanaDetails',
    'neverSmoked',
    'tobaccoDetails',
    'usedToSmoke'
  ]

  toggleSmokingSelections(event) {
    [
      { value: 'used_to_smoke', target: this.usedToSmokeTarget },
      { value: 'is_a_smoker', target: this.isASmokerTarget }
    ].forEach((obj) => {
      if (event.currentTarget.value === obj.value) {
        this.showRadioButtonMoreDetails({
          target: obj.target
        })
      } else {
        this.hideRadioButtonMoreDetails({
          target: obj.target
        })
      }
    })
  }

  toggleCbdDetails(event) {
    this.toggleCheckboxMoreDetails({
      input:  event.currentTarget,
      target: this.cbdDetailsTarget
    })
  }

  toggleECigarettesDetails(event) {
    this.toggleCheckboxMoreDetails({
      input:  event.currentTarget,
      target: this.eCigarettesDetailsTarget
    })
  }

  toggleMarijuanaDetails(event) {
    this.toggleCheckboxMoreDetails({
      input:  event.currentTarget,
      target: this.marijuanaDetailsTarget
    })
  }

  toggleTobaccoDetails(event) {
    this.toggleCheckboxMoreDetails({
      input:  event.currentTarget,
      target: this.tobaccoDetailsTarget
    })
  }

}
