import ApplicationController from '../../../support/application_controller'

export default class extends ApplicationController {

  static targets = [
    'complicationsTextField',
    'otherTextField'
  ]

  connect() {}

  // ==== Controllers

  // ==== Actions

  toggleTextField({ currentTarget: { value } }) {
    this.complicationsTextFieldTarget.hidden = (value !== 'complications')
    this.otherTextFieldTarget.hidden = (value !== 'other')
  }

  // ==== Getters

  // ==== Setters

  // ==== Private

  // ==== Channels

  // ==== Callbacks

}
