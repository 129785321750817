import WizardController from '../wizard_controller'

export default class extends WizardController {

  static targets = [
    'currentlyBreastfeedingSection',
    'currentlyUsingContraceptivesSection',
    'gravidityDetailsSection',
    'lastBreastExamDateSection',
    'lastBreastExamSection',
    'lastMenstrualPeriodDate',
    'lastMenstrualPeriodSection',
    'lastPapSmearExamDateSection',
    'lastPapSmearSection',
    'noMenstrualHistoryReason',
    'notTested',
    'pregnancyConfirmation',
    'pregnancyHistorySection',
    'pregnancyStatusSection',
    'yesHint'
  ]

  static values = {}

  static classes = []

  initialize() {}

  connect() {}

  // ==== Controllers

  // ==== Actions

  togglePregnancyStatusSelections(event) {
    const element = event.currentTarget

    if (element.value === 'not_tested') {
      this.showRadioButtonMoreDetails({
        target: this.notTestedTarget
      })
      this.hideRadioButtonMoreDetails({
        target: this.pregnancyConfirmationTarget
      })
    } else {
      this.showRadioButtonMoreDetails({
        target: this.pregnancyConfirmationTarget
      })
      this.hideRadioButtonMoreDetails({
        target: this.notTestedTarget
      })
    }

    this.clearNestedInputs()
  }

  toggleMenstrualStatusSelections(event) {
    const element = event.currentTarget

    switch (element.value) {
      case 'not_yet_had_first_period':
        this.handleFirstPeriodSelection()
        break
      case 'menstruating':
        this.handleMenstruatingSelection()
        break
      case 'reached_menopause':
        this.handleReachedMenopauseSelection()
        break
      default:
        break
    }
  }

  toggleYesHint(event) {
    const element = event.currentTarget

    if (element.value === 'yes') {
      this.showRadioButtonMoreDetails({
        target: this.yesHintTarget
      })
    } else {
      this.hideRadioButtonMoreDetails({
        target: this.yesHintTarget
      })
    }
  }

  togglePregnancyHistoryGravidity(event) {
    const element = event.currentTarget

    if (element.value > 0) {
      this.showTarget(this.gravidityDetailsSectionTarget)
    } else {
      this.hideTarget(this.gravidityDetailsSectionTarget)
    }
  }

  toggleLastBreastExamNeverHadSelections(event) {
    const element = event.currentTarget

    if (element.checked) {
      this.hideTarget(this.lastBreastExamDateSectionTarget)
    } else {
      this.showTarget(this.lastBreastExamDateSectionTarget)
    }
  }

  toggleLastPapSmearExamNeverHadSelections(event) {
    const element = event.currentTarget

    if (element.checked) {
      this.hideTarget(this.lastPapSmearExamDateSectionTarget)
    } else {
      this.showTarget(this.lastPapSmearExamDateSectionTarget)
    }
  }

  toggleMenstrualHistory(event) {
    const element = event.currentTarget

    if (element.checked) {
      this.hideTarget(this.lastMenstrualPeriodDateTarget)
      this.showTarget(this.noMenstrualHistoryReasonTarget)
      this.removeErrors(this.noMenstrualHistoryReasonTarget)
      this.lastMenstrualPeriodDateTarget.querySelector('input').value = ''
    } else {
      this.showTarget(this.lastMenstrualPeriodDateTarget)
      this.hideTarget(this.noMenstrualHistoryReasonTarget)
      this.removeErrors(this.lastMenstrualPeriodDateTarget)
      this.noMenstrualHistoryReasonTarget.querySelector('input').value = ''
    }
  }

  // ==== Getters

  // ==== Setters

  // ==== Private

  clearNestedInputs() {
    const inputs       = this.pregnancyStatusSectionTarget.querySelectorAll("input[type='text']")
    const radioButtons = this.pregnancyConfirmationTarget.querySelectorAll("input[type='radio']")

    radioButtons.forEach((element) => {
      element.checked = false
    })

    inputs.forEach((element) => {
      element.value = ''
    })
  }

  handleFirstPeriodSelection() {
    [
      this.currentlyBreastfeedingSectionTarget,
      this.currentlyUsingContraceptivesSectionTarget,
      this.lastBreastExamSectionTarget,
      this.lastPapSmearSectionTarget,
      this.lastMenstrualPeriodSectionTarget,
      this.pregnancyHistorySectionTarget,
      this.pregnancyStatusSectionTarget
    ].forEach((obj) => {
      this.hideRadioButtonMoreDetails({
        target: obj
      })
    })
  }

  handleMenstruatingSelection() {
    [
      this.currentlyBreastfeedingSectionTarget,
      this.currentlyUsingContraceptivesSectionTarget,
      this.lastBreastExamSectionTarget,
      this.lastMenstrualPeriodSectionTarget,
      this.lastPapSmearSectionTarget,
      this.pregnancyHistorySectionTarget,
      this.pregnancyStatusSectionTarget
    ].forEach((obj) => {
      this.showRadioButtonMoreDetails({
        target: obj
      })
    })
  }

  handleReachedMenopauseSelection() {
    [
      this.lastBreastExamSectionTarget,
      this.lastPapSmearSectionTarget,
      this.pregnancyHistorySectionTarget
    ].forEach((obj) => {
      this.showRadioButtonMoreDetails({
        target: obj
      })
    });

    [
      this.currentlyBreastfeedingSectionTarget,
      this.currentlyUsingContraceptivesSectionTarget,
      this.lastMenstrualPeriodSectionTarget,
      this.pregnancyStatusSectionTarget
    ].forEach((obj) => {
      this.hideRadioButtonMoreDetails({
        target: obj
      })
    })
  }

  // ==== Channels

}
