import ApplicationController from '../../frontend/support/application_controller'
import $fq                   from '../../frontend/support/fake_query'

export default class extends ApplicationController {

  static targets = [
    'button'
  ]

  static values = {}

  static classes = [
    'selected'
  ]

  initialize() {}

  connect() {}

  // ==== Controllers

  // ==== Actions

  toggle(event) {
    const element = event.currentTarget

    this.buttonTargets.forEach((target) => {
      if (target === element) {
        target.setAttribute('aria-selected', 'true')
      } else {
        target.setAttribute('aria-selected', 'false')
      }
    })

    this.updateSelected()
  }

  close(event) {
    const element        = event.currentTarget
    const wrapperElement = element.closest('.c-ds2-tabs__tab-wrapper')
    const tab            = wrapperElement.querySelector('.c-ds2-tabs__tab')
    const bodyId         = tab.getAttribute('aria-controls')
    const bodyElement    = this.element.querySelector(`#${bodyId}`)
    const wasSelected    = this.isSelected(tab)

    wrapperElement.remove()
    if (bodyElement) {
      bodyElement.remove()
    }

    if (wasSelected && this.hasButtonTarget) {
      this.buttonTargets[0].setAttribute('aria-selected', 'true')
      this.updateSelected()
    }

    const closeEvent = new CustomEvent('tabs:close', {
      bubbles: true,
      detail:  { tab, wrapper: wrapperElement, body: bodyElement }
    })
    tab.dispatchEvent(closeEvent)
  }

  // ==== Getters

  // ==== Setters

  // ==== Private

  isSelected(element) {
    return element.getAttribute('aria-selected') === 'true'
  }

  updateSelected() {
    this.buttonTargets.forEach((target) => {
      const bodyId         = target.getAttribute('aria-controls')
      const bodyElement    = this.element.querySelector(`#${bodyId}`)
      const wrapperElement = target.closest('.c-ds2-tabs__tab-wrapper')

      if (this.isSelected(target)) {
        target.setAttribute('aria-selected', 'true')
        $fq(wrapperElement).addClass(this.selectedClass)
        if (bodyElement) {
          bodyElement.setAttribute('aria-hidden', 'false')
          bodyElement.removeAttribute('hidden')
          $fq(bodyElement).addClass(this.selectedClass)
        }
      } else {
        target.setAttribute('aria-selected', 'false')
        $fq(wrapperElement).removeClass(this.selectedClass)
        if (bodyElement) {
          bodyElement.setAttribute('aria-hidden', 'true')
          bodyElement.setAttribute('hidden', '')
          $fq(bodyElement).removeClass(this.selectedClass)
        }
      }
    })
  }

  // ==== Channels

}
