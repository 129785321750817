import { getGlobalMute } from './global_mute'

/**
 * Finds a sound from the sound sheet. Warns if the sound is not found.
 * @param {string} sound id of the sound, can be prepended with a #
 * @returns {HTMLAudioElement} the audio element if found
 */
export function findSound(sound) {
  if (!sound.startsWith('#')) sound = `#${sound}`
  const audio = document.querySelector(`audio${sound}`)
  if (!audio) console.warn(`No audio element in sound sheet for '${sound}'`)

  return audio
}

/**
 * Plays a given audio element. Warns if the browser blocks playback.
 * Does not play sound if global mute is active (e.g. during videomed call)
 *
 * @see {@link https://developer.chrome.com/blog/autoplay/}
 * @param {HTMLAudioElement} audio the audio element to play
 */
export async function playAudio(audio) {
  if (getGlobalMute()) return

  try {
    await audio.play()
  } catch (error) {
    console.warn(error)
  }
}

/**
 * Finds and plays a sound from the sound sheet. Warns if the sound is not found.
 * Does not play sound if global mute is active (e.g. during videomed call)
 *
 * @see {@link findSound}
 * @see {@link playAudio}
 * @param {string} sound id of the sound, can be prepended with a #
 */
export function playSound(sound) {
  playAudio(findSound(sound))
}
