import ApplicationController from '../support/application_controller'
import CreateChannel         from '../client/cable'
import HfDirectUpload        from '../support/hf_direct_upload'
import HfDirectUploadEvents  from '../support/hf_direct_upload_events'

let directUploadEvents

export default class extends ApplicationController {

  static targets = [
    'addButton',
    'fileForm',
    'fileInput',
    'filePreview',
    'submitButton',
    'success',
    'upload'
  ]

  initialize() {
    directUploadEvents = new HfDirectUploadEvents()
    this.createClinicalPhotoChannel()
  }

  connect() {
    directUploadEvents.uploadController     = this
    directUploadEvents.fileInputTarget      = this.fileInputTarget
    directUploadEvents.progressWrapperClass = 'u-clinicalPhotoSmartphoneProgressBar'
    directUploadEvents.submitButtonTarget   = this.submitButtonTarget
    directUploadEvents.useGallery           = false
    directUploadEvents.bindAllEvents()
  }

  // ==== Controllers

  get gallery() {
    return this.data.get('gallery')
  }

  // ==== Actions

  addNewPhoto(event) {
    event.currentTarget.classList.add('u-hide')

    this.uploadTarget.classList.remove('-pending')
    this.filePreviewTarget.innerHTML = ''
    this.addButtonTarget.classList.add('u-hide')
    this.successTarget.classList.add('u-hide')
  }

  takePhoto(_event) {
    const fileBlob = this.fileInputTarget.files[0]
    this.fileInputTarget.value = ''

    if (fileBlob) {
      const uploader = new HfDirectUpload(this.fileInputTarget, fileBlob)
      this.uploadTarget.classList.add('-pending')
      uploader.start()
    }
  }

  // ==== Getters

  get consultationId() {
    return this.data.get('consultationId')
  }

  // ==== Setters

  // ==== Private

  showPreview(image) {
    this.filePreviewTarget.appendChild(image)
  }

  // ==== Channels

  createClinicalPhotoChannel = async () => {
    const self = this

    await CreateChannel({
      channel:         'ClinicalPhotoChannel',
      consultation_id: self.consultationId
    }, {
      received(data) {
        self.filePreviewTarget.innerHTML = data.smartphone_image_html
        self.addButtonTarget.classList.remove('u-hide')
        self.successTarget.classList.remove('u-hide')
      }
    })
  }

}
