import I18n                        from '../../support/i18n'
import NotificationsControllerBase from './notifications_controller_base'

/**
 * Notification indicator and unread message count
 * for ContentButtonComponent/c_content_button
 */
export default class extends NotificationsControllerBase {

  static targets = [
    'subtitle'
  ]

  // ==== Controllers

  // ==== Actions

  // ==== Getters

  // ==== Setters

  // ==== Private

  onUnread(count) {
    this.subtitleTarget.classList.add('-with-indicator')
    this.subtitleTarget.textContent = ''
    const countMessage = document.createElement('span')
    countMessage.textContent = I18n.t('app.new_message', { count })
    this.subtitleTarget.append(countMessage)
  }

  // ==== Channels

}
