import ApplicationController from '../../support/application_controller'
import $fq                   from '../../support/fake_query'

export default class SimpleAssessmentController extends ApplicationController {

  static targets = [
    'confirmatoryHivTestDetails',
    'confirmatoryHivTestInput'
  ]

  static outlets = [
    'consultation'
  ]

  connect() {}

  initialize() {}

  disconnect() {}

  // ==== Actions

  renderResponse(event) {
    const [_response, _status, xhr] = event.detail

    $fq(this.consultationOutlet.responseBodyTarget).html(xhr.response)
  }

  // ==== Getters

  // ==== Setters

  // ==== Private

  resetInputs(inputs) {
    inputs.forEach((element) => {
      const type = element.getAttribute('type')

      if (type === 'checkbox' || type === 'radio') {
        element.checked = false
      } else {
        element.value = ''
      }
    })
  }

  toggleConfirmatoryHivTest(event) {
    const element = event.currentTarget

    if (parseInt(element.value, 10) > 0) {
      $fq(this.confirmatoryHivTestDetailsTarget).show()
    } else {
      this.resetInputs(this.confirmatoryHivTestInputTargets)
      $fq(this.confirmatoryHivTestDetailsTarget).hide()
    }
  }

  // ==== Channels

}
