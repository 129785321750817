import WizardController from '../../../wizard_controller'

export default class extends WizardController {

  // ==== Controllers

  // ==== Actions

  // ==== Getters

  // ==== Setters

  // ==== Private

  // ==== Channels

}
