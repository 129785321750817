import ApplicationController from '../../../support/application_controller'
import CreateChannel         from '../../../client/cable'

export default class extends ApplicationController {

  static targets = [
    'cardContent'
  ]

  static values = {
    noteId: String
  }

  connect() {}

  initialize() {
    this.subscribeItemsUpdateChannel()
  }

  disconnect() {
    this.channelSubscription.unsubscribe()
    this.channelSubscription.disconnected()
  }

  // ==== Controllers

  // ==== Actions

  // ==== Getters

  // ==== Setters

  // ==== Private

  // ==== Channels

  subscribeItemsUpdateChannel() {
    if (this.channelSubscription === undefined) {
      const self = this

      this.channelSubscription = CreateChannel({
        channel: 'MedicationAndInjectableItemsUpdateChannel',
        note_id: self.noteIdValue
      }, {
        connected() { },
        disconnected() { },
        received(data) {
          if (self.hasCardContentTarget) {
            self.cardContentTarget.innerHTML = data.items_html
          }
        }
      })
    }

    return this.channelSubscription
  }

}
