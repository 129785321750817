import ApplicationController from '../../../support/application_controller'
import $fq                   from '../../../support/fake_query'

export default class extends ApplicationController {

  static targets = [
    'question1',
    'question1Input',
    'question2',
    'question2Input',
    'question3',
    'question3Input',
    'question4',
    'question4Input',
    'question5',
    'question5Input',
    'skipMessage'
  ]

  connect() { }

  answerQuestion1(event) {
    this.questionAnswered(
      this.question1Target,
      event.currentTarget.value
    )
  }

  answerQuestion2(event) {
    this.questionAnswered(
      this.question2Target,
      event.currentTarget.value
    )
  }

  answerQuestion3(event) {
    this.questionAnswered(
      this.question3Target,
      event.currentTarget.value
    )
  }

  answerQuestion4(event) {
    this.questionAnswered(
      this.question4Target,
      event.currentTarget.value
    )
  }

  answerQuestion5(event) {
    this.questionAnswered(
      this.question5Target,
      event.currentTarget.value
    )
  }

  questionAnswered(question, answer) {
    this.resetFollowingQuestions(question)

    if (answer === '0') {
      this
        .findFollowingQuestions(question)
        .forEach((wrapper) => {
          $fq(wrapper).hide()
        })

      if (question !== this.question5Target) {
        $fq(this.skipMessageTarget).show()
      }
    } else if (answer === '1') {
      const wrapper = this.findFollowingQuestions(question)[0]

      if (wrapper) {
        $fq(wrapper).show()
      }

      $fq(this.skipMessageTarget).hide()
    }
  }

  findFollowingQuestions(questionWrapperTarget) {
    let questionFound = false

    return [
      this.question1Target,
      this.question2Target,
      this.question3Target,
      this.question4Target,
      this.question5Target
    ].reduce((prev, wrapper) => {
      const followingQuestions = questionFound ? [...prev, wrapper] : prev
      questionFound = questionFound || wrapper === questionWrapperTarget
      return followingQuestions
    }, [])
  }

  resetFollowingQuestions(question) {
    if (question === this.question5target) return

    this
      .findFollowingQuestions(question)
      .forEach((wrapper) => {
        const inputs = wrapper.querySelectorAll('input[type="radio"]')
        inputs.forEach((input) => { input.checked = false })
      })
  }

}
