import ApplicationController from '../../frontend/support/application_controller'
import $fq                   from '../../frontend/support/fake_query'
import { disableElement, enableElement } from '../../frontend/support/helpers'

export default class extends ApplicationController {

  static targets = [
    'button'
  ]

  static values = {
    timeout: Number,
    href:    String
  }

  static classes = [
    'loading'
  ]

  initialize() {
    this.loaded = this.loaded.bind(this)
  }

  disconnect() {
    this.frame?.removeEventListener('turbo:before-fetch-response', this.loaded)
  }

  // ==== Controllers

  // ==== Actions

  loading() {
    this.frame?.addEventListener('turbo:before-fetch-response', this.loaded)

    $fq(this.buttonElement).addClass(this.loadingClass)

    this.loadingTimeout = setTimeout(() => {
      this.loaded()
    }, this.timeoutValue)

    // Small timeout to allow the link to serialize and perform its action
    // before removing the href/ disabling
    setTimeout(() => {
      disableElement(this.element)
    }, 13)
  }

  loaded() {
    this.frame?.removeEventListener('turbo:before-fetch-response', this.loaded)

    if (this.loadingTimeout) {
      clearTimeout(this.loadingTimeout)
      this.loadingTimeout = null
    }

    enableElement(this.element)

    $fq(this.buttonElement).removeClass(this.loadingClass)
  }

  // ==== Getters

  get buttonElement() {
    if (this.hasButtonTarget) return this.buttonTarget

    return this.element
  }

  get frame() {
    if (this.element.dataset.turboFrame) {
      return document.getElementById(this.element.dataset.turboFrame)
    }
    return this.element.closest('turbo-frame')
  }

  // ==== Setters

  // ==== Private

  // ==== Channels

}
