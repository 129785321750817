import { Turbo }             from '@hotwired/turbo-rails'
import ApplicationController from '../support/application_controller'

export default class extends ApplicationController {

  // ==== Controllers

  // ==== Actions

  selectPatient() {
    Turbo.visit(this.url, { historyChanged: this.historyChanged })
  }

  // ==== Getters

  get url() {
    return this.data.get('url')
  }

  get historyChanged() {
    return this.data.get('use-turbo') === 'true'
  }
  // ==== Setters

  // ==== Private

  // ==== Channels

}
