import debounce              from 'lodash/debounce'
import ApplicationController from '../../support/application_controller'
import { autoReFocus }       from '../../support/helpers'

let focusField
let autoSaveTriggered

export default class extends ApplicationController {

  static targets = [
    'submit',
    'responseBody'
  ]

  static outlets = [
    'consultation'
  ]

  initialize() {
    this.autoSave = debounce(this.autoSave, 8000)
  }

  // ==== Actions

  ajaxComplete(event) {
    const [_response, _status, xhr] = event.detail

    this.autoSave.cancel()
    this.renderFlashMessage(xhr)

    if (autoSaveTriggered) {
      autoReFocus(focusField)
    }

    if (xhr.status === 200) {
      // Editing a profile during a visible consultation requires different rendering
      // targets because the consultation's DOM is occupying the responseBodyActive target and
      // can't be overwritten to ensure it can be restored when the back button is
      // clicked. All of this must get re-assessed when we do historical consultation
      // views during an active consultation
      if (this.consultationId) {
        this.consultationOutlet.responseBodyTarget.classList.remove('u-hide')
        this.consultationOutlet.responseBodyActiveTarget.classList.add('u-hide')
        this.consultationOutlet.responseBodyTarget.innerHTML = xhr.response
      } else {
        this.consultationOutlet.responseBodyTarget.classList.add('u-hide')
        this.consultationOutlet.responseBodyTarget.innerHTML = ''
        this.consultationOutlet.responseBodyActiveTarget.innerHTML = xhr.response
        this.consultationOutlet.restoreActions()
        this.consultationOutlet.showElements()
        this.consultationOutlet.showConsultButton()
      }
    } else {
      this.consultationOutlet.responseBodyTarget.classList.remove('u-hide')
      this.consultationOutlet.responseBodyTarget.innerHTML = xhr.response
    }
  }

  autoSave(event) {
    focusField = event.target.id
    autoSaveTriggered = true
    this.submitTarget.click()
  }

  manualSave() {
    this.submitTarget.click()
  }

  // ==== Getters

  get consultationId() {
    return this.data.get('consultationId')
  }

  // ==== Setters

  // ==== Private

  // ==== Channels

}
