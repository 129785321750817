import ApplicationController from '../../support/application_controller'
import CreateChannel         from '../../client/cable'
import $fq                   from '../../support/fake_query'

// Any controller that requires but strays from this implementation needs to extend
// from this controller.

export default class extends ApplicationController {

  static targets = [
    'statusUpdate'
  ]

  static values = {
    consultationId: String,
    noteId:         String
  }

  connect() {
    this.createIntegrationSubmissionsChannel()
  }

  initialize() {}

  disconnect() {
    this.channelSubscription.unsubscribe()
    this.channelSubscription.disconnected()
  }

  // ==== Controllers

  // ==== Actions

  // ==== Getters

  // ==== Setters

  // ==== Private

  // ==== Channels

  createIntegrationSubmissionsChannel() {
    if (this.channelSubscription === undefined) {
      const self = this

      this.channelSubscription = CreateChannel({
        channel:         'IntegrationSubmissionsChannel',
        consultation_id: self.consultationIdValue,
        note_id:         self.noteIdValue
      }, {
        connected() {},
        disconnected() {},
        received(data) {
          switch (data.action) {
            case 'status_update':
              if (self.hasStatusUpdateTarget) {
                $fq(self.statusUpdateTarget).innerHTML(data.html)
              }
              break

            case 'complete':
            case 'error':
            case 'timeout':
              $fq(self.element).innerHTML(data.html)
              self.disconnect()
              break

            default:
              break
          }
        }
      })
    }

    return this.channelSubscription
  }

}
