import ApplicationController from '../../support/application_controller'

export default class extends ApplicationController {

  static targets = [
    'text'
  ]

  // ==== Controllers

  // ==== Actions

  toggleTextField(event) {
    const element = event.currentTarget

    if (element.value === 'other') {
      this.textTarget.hidden = false
    } else {
      this.textTarget.hidden = true
    }
  }

  // ==== Getters

  // ==== Setters

  // ==== Private

  // ==== Channels

}
