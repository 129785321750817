import mixpanel              from 'mixpanel-browser'
import ApplicationController from '../support/application_controller'

export default class extends ApplicationController {

  static values = {
    consultationId:    String,
    extraAttributes:   String,
    groupId:           String,
    groupName:         String,
    medicalCentreId:   String,
    medicalCentreName: String,
    patientId:         String,
    token:             String,
    userId:            String
  }

  static targets = [
    'tracker',
    'timedTracker'
  ]

  initialize() {
    this.timers = {}

    if (this.hasTokenValue && this.tokenValue.length > 0) {
      mixpanel.init(this.tokenValue)
      if (this.hasUserIdValue) {
        mixpanel.identify(this.userIdValue)
      }
    }
  }

  // ==== Actions

  trackEvent() {
    if (this.hasTrackerTarget && this.hasTokenValue && this.tokenValue.length > 0) {
      mixpanel.track(this.trackerTarget.dataset.event, this.trackingData(this.trackerTarget))
    }
  }

  trackTimedEvent() {
    if (this.hasTimedTrackerTarget && this.hasTokenValue && this.tokenValue.length > 0) {
      this.queueTimedTrackingEvent(this)
    }
  }

  trackTimedEventEnd() {
    if (this.hasTimedTrackerTarget) {
      // This sets the global trigger value for this timer to false
      // If this happens before the timeout lapses then we will not track the event
      // It gets set in queueTimedTrackingEvent
      this.timers[this.timedTrackerTarget.dataset.timer_id] = { trigger: false }
    }
  }

  // ==== Private

  queueTimedTrackingEvent(self) {
    const timerId = this.timedTrackerTarget.dataset.timer_id
    const event   = this.timedTrackerTarget.dataset.event
    const data    = this.trackingData(this.timedTrackerTarget)

    this.timers[timerId] = { trigger: true }

    setTimeout(() => {
      if (self.timers[timerId].trigger) {
        mixpanel.track(event, data)
      }
    }, this.timedTrackerTarget.dataset.timer_seconds * 1000)
  }

  trackingData(target) {
    const data = {}
    let extraAttributes

    if (this.hasConsultationIdValue) { data.consultation_id = this.consultationIdValue }
    if (this.hasGroupIdValue) { data.group_id = this.groupIdValue }
    if (this.hasGroupNameValue) { data.group_name = this.groupNameValue }
    if (this.hasMedicalCentreIdValue) { data.medical_centre_id = this.medicalCentreIdValue }
    if (this.hasMedicalCentreNameValue) { data.medical_centre_name = this.medicalCentreNameValue }
    if (this.hasPatientIdValue) { data.patient_id = this.patientIdValue }

    // Extra attributes added to the controller
    if (this.hasExtraAttributesValue) {
      extraAttributes = JSON.parse(this.extraAttributesValue)
      for (const key in extraAttributes) {
        if (extraAttributes[key].length > 0) {
          data[key] = extraAttributes[key]
        }
      }
    }

    // Extra attributes added to the target
    if ('extra_attributes' in target.dataset) {
      extraAttributes = JSON.parse(target.dataset.extra_attributes)
      for (const key in extraAttributes) {
        if (extraAttributes[key].toString().length > 0) {
          data[key] = extraAttributes[key]
        }
      }
    }

    return data
  }

}
