import * as Sentry       from '@sentry/browser'
import { getParser }     from 'bowser-jr'
import { browserParser } from 'bowser-jr/browser'
import { osParser }      from 'bowser-jr/os'
import { metaContent }   from '../../support/helpers'

const SENTRY_DSN = metaContent('app:sentry_dsn')

const bowserResult = getParser(window.navigator.userAgent, { use: [browserParser, osParser] }).getResult()

if (SENTRY_DSN) {
  try {
    Sentry.init({
      dsn:                 SENTRY_DSN,
      environment:         HF.current.env,
      release:             HF.current.release,
      defaultIntegrations: false,

      beforeSend(event, _hint) {
        if (!event.extra.explicit) return null

        Object.assign(event.extra, HF.current.documentState)

        return event
      }
    })
    Sentry.configureScope((scope) => {
      scope.setUser(HF.current.user)
      scope.setTag('user_role', HF.current.user.role)
      scope.setContext('browser', bowserResult.browser)
      scope.setContext('os', bowserResult.os)
      scope.setExtra('userAgent', window.navigator.userAgent)
    })
  } catch (error) {
    console.error('Sentry.init() error:', error) // eslint-disable-line no-console
  }
}
