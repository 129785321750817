import Rails                               from '@rails/ujs'
import { Turbo }                           from '@hotwired/turbo-rails'
import AdditionalItemsIcd10CodesController from './additional_items_icd10_codes_controller'

export default class extends AdditionalItemsIcd10CodesController {

  static targets = this.targets.concat([
    'addedCodes'
  ])

  // ==== Controllers

  // ==== Actions

  select(event) {
    this.disableCheckboxInputs()

    Rails.ajax({
      url:      this.href,
      type:     this.type,
      data:     this.params({ icd10_code_id: event.srcElement.value, checked: event.srcElement.checked }),
      success:  (response, _status, xhr) => {
        if (xhr.getResponseHeader('content-type').includes('text/vnd.turbo-stream.html')) {
          Turbo.renderStreamMessage(xhr.response)
        }
      },
      error:    (_response, _status, _xhr) => { },
      complete: (_xhr, _status) => { }
    })
  }

  // ==== Getters

  get href() {
    return this.data.get('href')
  }

  get type() {
    return this.data.get('method') || 'GET'
  }

  // ==== Setters

  // ==== Private

  disableCheckboxInputs() {
    document.querySelectorAll('input[type=checkbox]').forEach((input) => { input.disabled = true })
  }

  hideAddedCodes() {
    if (this.hasAddedCodesTarget) {
      this.addedCodesTarget.classList.add('u-hide')
    }
  }

  params(data) {
    const oldParams = JSON.parse(this.data.get('params'))
    const newParams = { ...oldParams, ...data }

    return Object.keys(newParams).map((key) => {
      if (Array.isArray(newParams[key])) {
        return `${key}=${encodeURIComponent(JSON.stringify(newParams[key]))}`
      }
      return `${key}=${encodeURIComponent(newParams[key])}`
    }).join('&')
  }

  showAddedCodes() {
    if (this.hasAddedCodesTarget) {
      this.addedCodesTarget.classList.remove('u-hide')
    }
  }

  // ==== Channels

}
