import ApplicationController from '../../frontend/support/application_controller'
import $fq                   from '../../frontend/support/fake_query'

export default class extends ApplicationController {

  static targets = [
    'button'
  ]

  static values = {
    multiExpand: Boolean
  }

  static classes = [
    'expanded'
  ]

  initialize() {}

  connect() {}

  // ==== Controllers

  // ==== Actions

  toggle(event) {
    const element = event.currentTarget

    if (this.multiExpandValue) {
      element.setAttribute('aria-selected', !this.isSelected(element))
    } else {
      this.buttonTargets.forEach((target) => {
        if (target === element) {
          target.setAttribute('aria-selected', !this.isSelected(target))
        } else {
          target.setAttribute('aria-selected', 'false')
        }
      })
    }

    this.updateExpanded()
  }

  collapseAll() {
    this.buttonTargets.forEach((target) => {
      target.setAttribute('aria-selected', 'false')
    })

    this.updateExpanded()
  }

  // ==== Getters

  // ==== Setters

  // ==== Private

  isSelected(element) {
    return element.getAttribute('aria-selected') === 'true'
  }

  updateExpanded() {
    this.buttonTargets.forEach((target) => {
      const bodyId      = target.getAttribute('aria-controls')
      const bodyElement = this.element.querySelector(`[id='${bodyId}']`)

      if (this.isSelected(target)) {
        target.setAttribute('aria-expanded', 'true')
        bodyElement.setAttribute('aria-hidden', 'false')
        bodyElement.removeAttribute('hidden')
        $fq(target).addClass(this.expandedClass)
        $fq(bodyElement).addClass(this.expandedClass)
      } else {
        target.setAttribute('aria-expanded', 'false')
        bodyElement.setAttribute('aria-hidden', 'true')
        bodyElement.setAttribute('hidden', '')
        $fq(target).removeClass(this.expandedClass)
        $fq(bodyElement).removeClass(this.expandedClass)
      }
    })
  }

  // ==== Channels

}
