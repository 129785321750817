import moment                from 'moment'
import Video                 from 'twilio-video'
import ApplicationController from '../support/application_controller'
import I18n                  from '../support/i18n'
import HfDirectUpload        from '../support/hf_direct_upload'
import HfDirectUploadEvents  from '../support/hf_direct_upload_events'

const videoOptions = {
  aspectRatio: 4 / 3,
  facingMode:  'user',
  width:       { exact: 640 },
  frameRate:   {
    ideal: 25,
    min:   10
  }
}

let testTracks
let directUploadEvents
let countdownInterval = null
let streaming         = false
const interval        = 10

export default class extends ApplicationController {

  static targets = [
    'autoRecordingButton',
    'canvas',
    'capture',
    'countdown',
    'countdownNumber',
    'fileForm',
    'fileInput',
    'hintText',
    'photo',
    'settings',
    'submitButton',
    'video',
    'selectVideoInput',
    'videoTest',
    'otoscopeVideoTrack'
  ]

  static outlets = [
    'clinical-photo-upload'
  ]

  initialize() {
    directUploadEvents = new HfDirectUploadEvents()
    this.spaceKeyTrigger()
  }

  async connect() {
    this.getMediaStream()
    this.clearPhoto()

    directUploadEvents.uploadController     = this.clinicalPhotoUploadOutlet
    directUploadEvents.fileInputTarget      = this.fileInputTarget
    directUploadEvents.progressWrapperClass = 'm-fileGallery__cell'
    directUploadEvents.submitButtonTarget   = this.submitButtonTarget
    directUploadEvents.useGallery           = true
    directUploadEvents.bindAllEvents()
  }

  // ==== Actions

  clearPhoto() {
    const context     = this.canvasTarget.getContext('2d')
    context.fillStyle = '#000'
    context.fillRect(0, 0, this.canvasTarget.width, this.canvasTarget.height)
  }

  takePhoto(event) {
    const context = this.canvasTarget.getContext('2d')

    if (event) {
      event.currentTarget.blur()
    }

    if (streaming) {
      this.canvasTarget.width  = this.canvasWidth
      this.canvasTarget.height = this.canvasHeight
      context.drawImage(this.otoscopeVideoTrackTarget, 0, 0, this.canvasWidth, this.canvasHeight)

      this.canvasTarget.toBlob((canvasBlob) => {
        canvasBlob.name = `otoscope-${moment().format('YYYY-MM-DD--HH-mm-ss')}`
        const uploader  = new HfDirectUpload(this.fileInputTarget, canvasBlob)
        uploader.start()
      }, 'image/jpeg')
    } else {
      this.clearPhoto()
    }
  }

  toggleAutoRecording(event) {
    event.currentTarget.blur()
    if (event.currentTarget.getAttribute('data-started') === '0') {
      event.currentTarget.setAttribute('data-started', '1')
      event.currentTarget.innerHTML = I18n.t('dictionary.stop_camera')
      this.hintTextTarget.innerHTML = I18n.t('otoscope_uploads.new.timer_hint_stop_html')
      this.startCountdown()
    } else {
      event.currentTarget.setAttribute('data-started', '0')
      event.currentTarget.innerHTML = I18n.t('dictionary.start_camera')
      this.hintTextTarget.innerHTML = I18n.t('otoscope_uploads.new.timer_hint_start_html')
      this.stopCountdown()
    }
  }

  toggleSettings(event) {
    event.currentTarget.blur()
    this.settingsTarget.classList.toggle('u-hide')

    if (!this.settingsTarget.classList.contains('u-hide')) {
      this.settingStatus = 'open'
      this.enumerateDevices()
      this.attachTestTracks()
    } else {
      this.settingStatus = 'closed'
      this.detachTestTracks()
    }
  }

  // ==== Settings

  enumerateDevices() {
    navigator.mediaDevices.enumerateDevices().then((mediaDevices) => { this.handleEnumeratedDevices(mediaDevices) })
  }

  handleEnumeratedDevices(mediaDevices) {
    this.selectVideoInputTarget.querySelectorAll('.tmpOption').forEach((el) => { el.remove() })

    mediaDevices.forEach((mediaDevice) => {
      const option = document.createElement('option')
      option.value = mediaDevice.deviceId
      option.classList.add('tmpOption')

      if (mediaDevice.deviceId === 'default') return

      switch (mediaDevice.kind) {
        case 'videoinput':
          if (this.otoscopeVideoInput === mediaDevice.deviceId) option.selected = true
          option.text = mediaDevice.label || `Camera ${this.selectVideoInputTarget.length - 1}`
          this.selectVideoInputTarget.appendChild(option)
          break

        default:
          break
      }
    })
  }

  // ==== Switch tracks

  refreshDevices(event) {
    event.currentTarget.blur()
    this.enumerateDevices()
  }

  switchVideoInput(event) {
    const value = event.currentTarget.value
    if (value === '') return

    this.otoscopeVideoInput = value
    this.detachTestTracks()
    this.attachTestTracks()
    this.getMediaStream()
  }

  // ==== Video tracks

  getMediaStream() {
    videoOptions.deviceId = this.otoscopeVideoInput
    Video.createLocalVideoTrack(videoOptions)
      .then((videoTrack) => {
        this.attachPreviewTracks([videoTrack])
      })
  }

  attachTestTracks() {
    if (this.settingStatus !== 'open') return

    Video.createLocalTracks({
      audio: false,
      video: { name: 'testVideo', deviceId: this.otoscopeVideoInput, width: { exact: 160 } }
    }).then((localTracks) => {
      testTracks = localTracks

      localTracks.forEach((track) => {
        this.videoTestTarget.appendChild(track.attach())
      })
    })
  }

  detachTestTracks() {
    testTracks.forEach((track) => {
      track.stop()
      track.detach().forEach((element) => element.remove())
    })
  }

  attachPreviewTracks(tracks) {
    tracks.forEach((track) => {
      if (track.kind === 'video') {
        const videoElement = track.attach()
        videoElement.dataset.target = `${this.identifier}.otoscopeVideoTrack`
        videoElement.addEventListener('canplay', (_event) => {
          if (!streaming) {
            this.canvasTarget.setAttribute('width', this.canvasWidth)
            this.canvasTarget.setAttribute('height', this.canvasHeight)
            streaming = true
          }
        }, false)

        this.videoTarget.appendChild(videoElement)
      }
    })
  }

  // ==== Getters

  get canvasHeight() {
    return 1200
  }

  get canvasWidth() {
    return 1600
  }

  get settingStatus() {
    return this.data.get('settings') || 'closed'
  }

  get otoscopeVideoInput() {
    return localStorage.getItem('otoscopeVideoInput')
  }

  // ==== Setters

  set settingStatus(value) {
    this.data.set('settings', value)
  }

  set otoscopeVideoInput(value) {
    localStorage.setItem('otoscopeVideoInput', value)
  }

  // ==== Private

  countdown(countdown) {
    if (countdown === 0) {
      this.takePhoto()
      this.countdownTarget.classList.add('-captured')
      this.countdownTarget.classList.remove('-started')
      this.countdownTarget.classList.remove('-stopped')
    } else {
      this.countdownNumberTarget.innerHTML = countdown
      this.countdownTarget.classList.remove('-captured')
      this.countdownTarget.classList.add('-started')
      this.countdownTarget.classList.remove('-stopped')
    }
  }

  spaceKeyTrigger() {
    document.addEventListener('keydown', (event) => {
      const charList = ' '
      const key      = event.key.toLowerCase()

      if (charList.indexOf(key) === -1) return

      this.autoRecordingButtonTarget.click()
    })
  }

  startCountdown() {
    let countdown = interval
    this.countdownTarget.classList.remove('-captured')
    this.countdownTarget.classList.add('-started')
    this.countdownTarget.classList.remove('-stopped')
    this.countdownNumberTarget.innerHTML = countdown
    countdownInterval = setInterval(() => {
      // eslint-disable-next-line
      countdown = --countdown < 0 ? interval : countdown
      this.countdown(countdown)
    }, 1000)
  }

  stopCountdown() {
    clearInterval(countdownInterval)
    this.countdownTarget.classList.remove('-captured')
    this.countdownTarget.classList.remove('-started')
    this.countdownTarget.classList.add('-stopped')
    this.countdownNumberTarget.innerHTML = interval
  }

  // ==== Channels

}
