import ApplicationController from '../../support/application_controller'

export default class extends ApplicationController {

  static targets = [
    'fileInput',
    'form',
    'uploadButton',
    'uploadButtonLoader'
  ]

  // ==== Controllers

  // ==== Actions

  selectDocument() {
    this.fileInputTarget.click()
  }

  uploadDocument() {
    this.setButtonLoader()
    this.formTarget.requestSubmit()
  }

  // ==== Getters

  // ==== Setters

  // ==== Private

  setButtonLoader() {
    this.uploadButtonTarget.classList.add('u-hide')
    this.uploadButtonLoaderTarget.classList.remove('u-hide')
    this.uploadButtonLoaderTarget.click()
  }

  // ==== Channels

  // ==== Callbacks

}
