import ApplicationController from '../support/application_controller'
import CreateChannel         from '../client/cable'

export default class extends ApplicationController {

  static targets = [
    'blankSlate',
    'counter',
    'item',
    'list'
  ]

  initialize() {
    this.createMedicalCentreChannel()
  }

  // ==== Controllers

  // ==== Actions

  // ==== Getters

  // ==== Setters

  // ==== Private

  // pingQueuedPatient() {
  //   const self = this
  //
  //   if (this.hasItemTarget) {
  //     this.itemTarget.classList.add('-ping')
  //     setTimeout(() => { self.itemTarget.classList.remove('-ping') }, 3000)
  //   }
  // }

  // ==== Channels

  createMedicalCentreChannel = async () => {
    const self = this

    await CreateChannel({
      channel: 'MedicalCentreChannel'
    }, {
      received(data) {
        switch (data.action) {
          case 'queue':
            this.insertQueueCard(data.queue_card_html, data.patient_id)
            this.updateCounter()
            break

          case 'remove':
            this.removeQueueCard(data.patient_id)
            this.updateCounter()
            break

          default:
            break
        }
      },

      updateCounter() {
        if (self.hasCounterTarget) {
          self.counterTarget.textContent = self.itemTargets.length
        }

        this.toggleBlankSlate()
        this.updatePositions()
      },

      toggleBlankSlate() {
        if (self.hasBlankSlateTarget) {
          if (self.itemTargets.length === 0) {
            self.blankSlateTarget.classList.remove('u-hide')
          } else {
            self.blankSlateTarget.classList.add('u-hide')
          }
        }
      },

      insertQueueCard(html, id) {
        if (self.hasListTarget) {
          const element = self.listTarget.querySelector(`[data-patient-id='${id}']`)
          if (element) {
            element.remove()
          }
          self.listTarget.insertAdjacentHTML('beforeend', html)
        }
      },

      removeQueueCard(id) {
        if (self.hasListTarget) {
          const element = self.listTarget.querySelector(`[data-patient-id='${id}']`)
          if (element) {
            element.remove()
          }
        }
      },

      updatePositions() {
        const cards = document.querySelectorAll('[data-queue-position]')
        cards.forEach((el, x) => {
          el.textContent = x + 1
        })
      }

    })
  }

}
