import WizardController   from '../wizard_controller'
import SmokingHabitsMixin from './smoking_habits_mixin'

export default class extends SmokingHabitsMixin(WizardController) {

  static targets = [
    ...super.targets,
    'drinks',
    'recreationalDrugsUse',
    'stoppedDrinking'
  ]

  static values = {}

  static classes = []

  initialize() {}

  connect() {}

  // ==== Controllers

  // ==== Actions

  toggleSmokingSelections(event) {
    [
      { value: 'used_to_smoke', target: this.usedToSmokeTarget },
      { value: 'is_a_smoker', target: this.isASmokerTarget }
    ].forEach((obj) => {
      if (event.currentTarget.value === obj.value) {
        this.showRadioButtonMoreDetails({
          target: obj.target
        })
      } else {
        this.hideRadioButtonMoreDetails({
          target: obj.target
        })
      }
    })
  }

  toggleDrinkingSelections(event) {
    [
      { value: 'stopped_drinking', target: this.stoppedDrinkingTarget },
      { value: 'drinks', target: this.drinksTarget }
    ].forEach((obj) => {
      if (event.currentTarget.value === obj.value) {
        this.showRadioButtonMoreDetails({
          target: obj.target
        })
      } else {
        this.hideRadioButtonMoreDetails({
          target: obj.target
        })
      }
    })
  }

  toggleRecreationalDrugsSelections(event) {
    [
      { value: 'yes', target: this.recreationalDrugsUseTarget }
    ].forEach((obj) => {
      if (event.currentTarget.value === obj.value) {
        this.showRadioButtonMoreDetails({
          target: obj.target
        })
      } else {
        this.hideRadioButtonMoreDetails({
          target: obj.target
        })
      }
    })
  }

  // ==== Getters

  // ==== Setters

  // ==== Private

  // ==== Channels

}
