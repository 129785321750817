import Flickity              from 'flickity'
import ApplicationController from '../support/application_controller'
import CreateChannel         from '../client/cable'

export default class extends ApplicationController {

  static targets = [
    'blankSlate',
    'gallery',
    'item'
  ]

  initialize() {
    this.imageSlider = new Flickity(this.galleryTarget, {
      arrowShape:     `M23.2434449,47.0123023 L64.6494203,6.23901582 C66.3014414,4.58699473
      68.9727947,4.58699473 70.6248157,6.23901582 L73.1204221,8.73462214
      C74.7724432,10.3866432 74.7724432,13.0579965 73.1204221,14.7100176
      L37.1626013,50 L73.0852727,85.2899824 C74.7372938,86.9420035 74.7372938,89.6133568
      73.0852727,91.2653779 L70.5896664,93.7609842 C68.9376453,95.4130053 66.266292,95.4130053
      64.6142709,93.7609842 L23.2082955,52.9876977 C21.5914238,51.3356766 21.5914238,48.6643234
      23.2434449,47.0123023 Z`,
      cellAlign:      'left',
      contain:        true,
      draggable:      '>1',
      freeScroll:     true,
      fullscreen:     false,
      lazyload:       true,
      pageDots:       false,
      setGallerySize: false
    })

    this.createClinicalPhotoChannel()
  }

  connect() {
    this.handleGalleryVisibility()
  }

  // ==== Actions

  showLoader(event) {
    const element = event.currentTarget
    element.classList.add('-inProgress')
  }

  toggleFullscreen() {
    this.imageSlider.toggleFullscreen()
  }

  // ==== Getters

  get consultationId() {
    return this.data.get('consultationId')
  }

  get addConsultationTarget() {
    return document.querySelector('[data-tmp-add-consultation]')
  }

  // ==== Setters

  // ==== Private

  handleGalleryVisibility(force) {
    if (this.hasItemTarget || force === 'add') {
      if (this.addConsultationTarget) this.addConsultationTarget.classList.remove('u-hide')
      if (this.hasBlankSlateTarget) this.blankSlateTarget.classList.add('u-hide')
      this.galleryTarget.classList.remove('u-v-hidden')
    } else {
      if (this.addConsultationTarget) this.addConsultationTarget.classList.add('u-hide')
      if (this.hasBlankSlateTarget) this.blankSlateTarget.classList.remove('u-hide')
      this.galleryTarget.classList.add('u-v-hidden')
    }
  }

  prependCell(cell) {
    this.imageSlider.prepend([cell])
    this.imageSlider.select(0, false, true)
    this.imageSlider.resize()
    this.handleGalleryVisibility('add')
  }

  removeCell(cell) {
    if (cell) {
      this.imageSlider.remove([cell])
      this.handleGalleryVisibility()
    }
  }

  // ==== Channels

  createClinicalPhotoChannel = async () => {
    const self = this

    await CreateChannel({
      channel:         'ClinicalPhotoChannel',
      consultation_id: self.consultationId
    }, {
      connected() {},
      received(data) {
        const galleryItem = document.querySelector(`[data-attachment-id='${data.id}']`)

        switch (data.action) {
          case 'add': {
            const cell             = document.createElement('div')
            cell.innerHTML         = data.image_html
            const directUploadFile = document.querySelector(`[data-direct-upload-file='${data.filename}']`)

            self.removeCell(directUploadFile)
            self.prependCell(cell.firstChild)
            break
          }
          case 'remove':
            self.removeCell(galleryItem)
            break

          default:
            break
        }
      }
    })
  }

}
