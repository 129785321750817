import ApplicationController from '../support/application_controller'

export default class extends ApplicationController {

  static targets = [
    'submitButton'
  ]

  submitForm(_event) {
    this.submitButtonTarget.click()
  }

}
