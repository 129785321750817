import ApplicationController from '../../support/application_controller'
import { clearInputErrors } from '../../support/helpers'

export default class extends ApplicationController {

  static targets = [
    'list',
    'subform'
  ]

  static values = {
    allowDestroy: Boolean
  }

  // ==== Controllers

  // ==== Actions

  add({ currentTarget }) {
    currentTarget.blur()

    const clone =  this.subformTarget.cloneNode(true)

    clone.classList.remove('u-hide')

    clearInputErrors(clone)

    this.updateAttributes(clone, this.subformTargets.length)
    for (const input of clone.querySelectorAll('input')) {
      if (input.type !== 'hidden') {
        input.value = ''
      } else if (input.name.includes('[id]')) {
        input.remove()
      } else if (input.name.includes('[_destroy]')) {
        input.value = '0'
      }
    }

    this.listTarget.appendChild(clone)
  }

  remove({ target }) {
    const wrapper = target.closest('.l-nested-form')

    if (this.subformTargets.length === 1) return

    if (this.allowDestroyValue && wrapper.querySelector('[type="hidden"][name*="[id]"]')?.value) {
      const destroyInput = wrapper.querySelector('[type="hidden"][name*="[_destroy]"]')
      destroyInput.value = '1'
      wrapper.classList.add('u-hide')
    } else {
      wrapper.remove()
    }

    this.subformTargets.forEach((subform, index) => {
      this.updateAttributes(subform, index)
    })
  }

  // ==== Getters

  // ==== Setters

  // ==== Private

  updateAttributes(subform, index) {
    for (const label of subform.querySelectorAll('label')) {
      let forAttr = label.getAttribute('for')
      forAttr     = forAttr.replace(/\d+/, index)
      label.setAttribute('for', forAttr)
    }

    for (const input of subform.querySelectorAll('input')) {
      input.id   = input.id.replace(/\d+/, index)
      input.name = input.name.replace(/\d+/, index)
    }
  }

  // ==== Channels

}
