import ApplicationController from '../support/application_controller'
import WizardMixin           from '../support/wizard_mixin'

export default class extends WizardMixin(ApplicationController) {

  static targets = [
    'button',
    'form',
    'navigationTextLinkButton',
    'step'
  ]

  // ==== Controllers

  // ==== Actions

  navigationLinkButton(event) {
    event.preventDefault()
    if (this.hasNavigationTextLinkButtonTarget) {
      this.navigationTextLinkButtonTarget.click()
    }
  }

  navigateStep(event) {
    const element = event.currentTarget

    this.buttonTarget.value = element.dataset.button
    this.stepTarget.value   = element.dataset.step
    this.formTarget.requestSubmit()
  }

  // ==== Getters

  get errorClass() {
    return '-error'
  }

  get hideClass() {
    return 'u-hide'
  }

  // ==== Setters

  // ==== Private

  // ==== Channels

}
