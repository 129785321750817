import ApplicationController from '../../support/application_controller'
import CreateChannel         from '../../client/cable'

export default class extends ApplicationController {

  static targets = [
    'responseBody',
    'statusUpdate'
  ]

  connect() {
    this.subscribeVHCSubmissionUpdatesChannel()
  }

  initialize() {}

  disconnect() {
    this.channelSubscription.unsubscribe()
    this.channelSubscription.disconnected()
  }

  // ==== Controllers

  // ==== Actions

  // ==== Getters
  get consultationId() {
    return this.data.get('consultationId')
  }

  // ==== Setters

  // ==== Private

  // ==== Channels

  subscribeVHCSubmissionUpdatesChannel() {
    if (this.channelSubscription === undefined) {
      const self = this

      this.channelSubscription = CreateChannel({
        channel:         'VHCSubmissionUpdatesChannel',
        consultation_id: self.consultationId
      }, {
        connected() {},
        disconnected() {},
        received(data) {
          switch (data.action) {
            case 'status_update':
              if (self.hasStatusUpdateTarget) {
                self.statusUpdateTarget.innerHTML = data.html
              }
              break
            case 'complete':
            case 'error':
            case 'timeout':
              if (self.hasResponseBodyTarget) {
                self.responseBodyTarget.innerHTML = data.html
                self.disconnect()
              }
              break
            default:
              // Do nothing
          }
        }
      })
    }

    return this.channelSubscription
  }

}
