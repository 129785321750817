import { Turbo }             from '@hotwired/turbo-rails'
import ApplicationController from '../support/application_controller'

export default class extends ApplicationController {

  static targets = []

  connect() {}

  initialize() {
    this.startPoller()
  }

  disconnect() {
    this.stopPoller()
  }

  // ==== Controllers

  // ==== Actions

  startPoller() {
    if (this.interval === 0) return

    this.intervalPoller = setInterval(() => {
      Turbo.clearCache()
      Turbo.visit(this.location, { action: this.action })
    }, this.interval)
  }

  stopPoller() {
    clearInterval(this.intervalPoller)
  }

  // ==== Getters

  get action() {
    return this.data.get('action') || 'replace'
  }

  get location() {
    return this.data.get('location')
  }

  get interval() {
    return parseInt(this.data.get('interval') || 40000, 10)
  }

  // ==== Setters

  // ==== Private

  // ==== Channels

}
