import ConsultationNoteController from '../consultation_note_controller'
import I18n                       from '../../support/i18n'

export default class extends ConsultationNoteController {

  static targets = this.targets.concat([
    'allied',
    'alliedAlertText',
    'alliedOtherText',
    'ampathItems',
    'ampathItemsTotal',
    'gynaecologistReasonModal',
    'investigation',
    'investigationOtherText',
    'otherConsultation',
    'pathology',
    'pathologyAlertText',
    'pathologyOtherText',
    'referralCategory',
    'referralLetter',
    'sendCopyOfReport',
    'specialist',
    'specialistAlertText',
    'specialistOtherText',
    'submitButton'
  ])

  initialize() {
    super.initialize()
    this.originalSubmitButtonText = this.submitButtonText
  }

  // ==== Controllers

  // ==== Actions

  toggleReferralType(event) {
    const objects = [
      { value: 'investigation', target: this.investigationTarget },
      { value: 'other_consultation', target: this.otherConsultationTarget }
    ]

    this.toggleHidden(event, objects)
  }

  toggleReferralSubType(event) {
    let objects = [
      { value: 'allied', target: this.alliedTarget },
      { value: 'specialist', target: this.specialistTarget }
    ]

    if (this.hasPathologyTarget) {
      objects = objects.concat([{ value: this.referralTypePathology, target: this.pathologyTarget }])
    }

    this.toggleHidden(event, objects)
  }

  toggleAlliedOtherText(event) {
    this.alliedOtherTextTarget.hidden = (event.currentTarget.value !== this.referralTypeOther)
  }

  toggleInvestigationOtherText(event) {
    this.investigationOtherTextTarget.hidden = (event.currentTarget.value !== this.referralTypeOther)
  }

  togglePathologyOtherText(event) {
    this.pathologyOtherTextTarget.hidden = (event.currentTarget.value !== this.referralTypeOther)
  }

  toggleSpecialistOtherText(event) {
    this.specialistOtherTextTarget.hidden = (event.currentTarget.value !== this.referralTypeOther)
  }

  toggleSpecialistAlertText(event) {
    this.specialistAlertTextTargets.forEach((target) => {
      target.classList.toggle('u-hide', event.currentTarget.value !== target.dataset.type)
    })
  }

  toggleAlliedAlertText(event) {
    this.alliedAlertTextTargets.forEach((target) => {
      target.classList.toggle('u-hide', event.currentTarget.value !== target.dataset.type)
    })
  }

  togglePathologyAlertText(event) {
    this.pathologyAlertTextTargets.forEach((target) => {
      target.classList.toggle('u-hide', event.currentTarget.value !== target.dataset.type)
    })
  }

  toggleHidden(event, objects) {
    this.toggleSendCopyOfReport(event)

    objects.forEach((obj) => {
      obj.target.hidden = event.currentTarget.value !== obj.value
    })
  }

  showReferralCategory() {
    this.referralCategoryTarget.hidden = false
  }

  toggleAmpathItems() {
    this.ampathItemsTarget.hidden = !this.ampathSelected()

    if (this.ampathSelected()) {
      this.submitButtonText = I18n.t('consultation_note.referral_letters.edit.send_to_ampath')
    } else {
      this.submitButtonText = this.originalSubmitButtonText
    }
  }

  toggleSendCopyOfReport(event) {
    this.sendCopyOfReportTarget.hidden = (event.target.value !== this.referralTypeRadiology)
  }

  toggleReferralLetter(event) {
    const targetValue = event.target.value

    if (this.ampathSelected()) {
      this.referralLetterTarget.hidden = true
    } else if (!Number.isNaN(Number(targetValue)) || targetValue === 'emergency') {
      this.referralLetterTarget.hidden = false
    }
  }

  showFinalSection(event) {
    if (this.hasAmpathItemsTarget) {
      this.toggleAmpathItems()
    }
    this.toggleReferralLetter(event)
  }

  calculateAmpathItemsTotal(event) {
    let updatedValue = 0

    if (!this.hasAmpathItemsTotalTarget) { return }

    const totalTarget  = this.ampathItemsTotalTarget.querySelector('strong')
    const initialValue = Number(totalTarget.textContent.replace(/ /g, '').replace('R', ''))
    const targetValue  = Number(event.target.getAttribute('data-ampath-item-price'))

    if (event.target.checked) {
      updatedValue = initialValue + targetValue
    } else {
      updatedValue = initialValue - targetValue
    }

    const finalValue = updatedValue.toLocaleString('en-US', { minimumFractionDigits: 2, useGrouping: true }).replace(/,/g, ' ')

    totalTarget.textContent = `R ${finalValue}`
  }

  closeGynaecologistReasonModal() {
    this.gynaecologistReasonModalTarget.remove()
  }

  // ==== Getters

  get referralTypeOther() {
    return this.data.get('referralTypeOther')
  }

  get referralTypeGynaecologist() {
    return this.data.get('referralTypeGynaecologist')
  }

  get referralTypePathology() {
    return this.data.get('referralTypePathology')
  }

  get referralTypeRadiology() {
    return this.data.get('referralTypeRadiology')
  }

  get originalSubmitButtonText() {
    return this.data.get('originalSubmitButtonText')
  }

  get submitButtonText() {
    return this.submitButtonTarget.textContent
  }

  // ==== Setters

  set submitButtonText(value) {
    this.submitButtonTarget.textContent = value
  }

  set originalSubmitButtonText(value) {
    this.data.set('originalSubmitButtonText', value)
  }

  // ==== Private

  ampathSelected() {
    if (!this.hasPathologyTarget) { return false }

    const ampathPathology        = this.pathologyTarget.querySelector('#consultation_note_referral_letter_pathology_type_ampath')
    const investigationCategory  = this.referralCategoryTarget.querySelector('#consultation_note_referral_letter_referral_category_investigation')
    const pathologyInvestigation = this.investigationTarget.querySelector('#consultation_note_referral_letter_investigation_type_052000')

    if (!ampathPathology) { return false }

    return investigationCategory.checked && pathologyInvestigation.checked && ampathPathology.checked
  }

  // ==== Channels

  // ==== Callbacks

}
