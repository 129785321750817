import Flickity              from 'flickity'
import ApplicationController from '../support/application_controller'

export default class extends ApplicationController {

  static targets = []

  static values = {}

  static classes = []

  initialize() {}

  connect() {
    this.flickitySlider = new Flickity(this.element, {
      autoPlay:             1000 * 60,
      cellAlign:            'left',
      contain:              true,
      draggable:            false,
      fullscreen:           false,
      pageDots:             false,
      pauseAutoPlayOnHover: false,
      percentPosition:      true,
      prevNextButtons:      false,
      setGallerySize:       false,
      wrapAround:           true
    })
  }

  disconnect() {
    this.flickitySlider.destroy()
  }

  // ==== Controllers

  // ==== Actions

  // ==== Getters

  // ==== Setters

  // ==== Private

  // ==== Channels

}
