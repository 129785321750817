import Rails                 from '@rails/ujs'
import ApplicationController from '../../support/application_controller'
import $fq                   from '../../support/fake_query'

export default class extends ApplicationController {

  static targets = [
  ]

  static outlets = [
    'medication'
  ]

  // ==== Actions

  select(event) {
    const element = event.currentTarget

    $fq(element).addClass('-loaderActive')

    Rails.ajax({
      url:      this.href,
      type:     'GET',
      data:     null,
      success:  (_response, _status, xhr) => {
        this.medicationOutlet.searchCallback(xhr)
      },
      error:    (_response, _status, _xhr) => { },
      complete: (_xhr, _status) => { }
    })
  }

  // ==== Getters

  get href() {
    return this.data.get('href')
  }

  // ==== Setters

  // ==== Private

  // ==== Channels

}
