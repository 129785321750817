import AdvancedSearchController from './advanced_search_controller'

export default class extends AdvancedSearchController {

  static targets = this.targets.concat([
    'cardLoader',
    'chapterFilter',
    'countLoader',
    'resultsCard',
    'resultsCount'
  ])

  // Actions

  // Getters

  get chapterFilterValue() {
    if (!this.hasChapterFilterTarget) return null

    return this.chapterFilterTarget.value
  }

  get resultsCard() {
    return this.progressiveScrollTarget
  }

  get params() {
    const data = super.params

    this.chapterFilterTargets.forEach((input) => {
      if (input.checked) data.append(input.name, input.value)
    })

    return data
  }

  // ==== Private

  disableFilterButtons() {
    this.chapterFilterTargets.forEach((input) => {
      input.disabled = true
    })
  }

  hideResultsCard() {
    this.resultsCard.classList.add('u-hide')
  }

  hideResultsCount() {
    this.resultsCountTarget.classList.add('u-hide')
  }

  showCardLoader() {
    this.cardLoaderTarget.classList.remove('u-hide')
  }

  showCountLoader() {
    this.countLoaderTarget.classList.remove('u-hide')
  }

  submit() {
    super.submit()
    if (this.chapterFilterValue) {
      this.showChapterLoader()
    }
  }

  showChapterLoader() {
    this.disableFilterButtons()
    this.hideResultsCard()
    this.showCardLoader()
    this.hideResultsCount()
    this.showCountLoader()
  }

  submitStart(event) {
    if (this.chapterFilterValue) {
      this.showChapterLoader()
    } else {
      super.submitStart(event)
    }
  }

}
