import debounce              from 'lodash/debounce'
import ApplicationController from '../support/application_controller'
import { getParamByName }    from '../support/helpers'

export default class extends ApplicationController {

  static targets = [
    'completedTab',
    'currentPage',
    'loader',
    'query',
    'results',
    'resultsUrl',
    'resultsTooShort',
    'resultsTooShortNumeric',
    'savedForLaterTab',
    'submit'
  ]

  static values = {
    baseUrl:         String,
    history:         Boolean,
    testEnvironment: Boolean
  }

  static outlets = [
    'consultation',
    'medication'
  ]

  initialize() {
    this.startSearch = debounce(this.startSearch, 800)
  }

  connect() {
    this.setFormState()
  }

  // ==== Actions

  ajaxTabSuccess(event) {
    const element = event.currentTarget
    const [_data, _status, xhr] = event.detail
    this.resetActiveStates()
    element.classList.add('-active')

    this.responseBodyTarget.innerHTML = xhr.response
  }

  clearSearch(event) {
    if (this.historyValue) {
      window.history.pushState({ turbo: true }, null, this.baseUrlValue)
    }

    this.resultsTarget.innerHTML = ''
    this.element.classList.remove('-active')

    if (event && event.type === 'click' && this.hasMedicationOutlet) {
      this.medicationOutlet.showActiveForm()
    }

    if (this.query) {
      this.queryTarget.value = ''
    }
  }

  hideLoader() {
    setTimeout(() => {
      this.loaderTarget.classList.add('u-hide')
    }, 800)
  }

  reloadConsultationInframe(event) {
    const [_response, _status, xhr] = event.detail
    this.consultationOutlet.renderResponse(xhr.response)
    this.clearSearch()
  }

  renderResponse(event) {
    const [response, _status, xhr] = event.detail

    if (this.query) {
      this.renderResults(xhr.response)
      this.currentPage = this.getCurrentPageParam(response.responseURL)
      this.setHistory()
      this.setFormState()
      this.hideLoader()
    } else {
      this.clearSearch()
    }
  }

  renderResults(html) {
    this.resultsTarget.innerHTML = html
  }

  setFormState() {
    if (this.query) {
      this.element.classList.add('-active')
    } else {
      this.element.classList.remove('-active')
    }
  }

  setHistory() {
    if (!this.historyValue) return
    if (this.hasResultsUrlTarget) {
      window.history.pushState({ turbo: true, page: this.currentPage }, null, this.resultsURL)
      document.title = `Search results - Page (${this.currentPage})`
    }
  }

  showLoader() {
    this.loaderTarget.classList.remove('u-hide')
  }

  manualSearch() {
    this.resultsTooShortNumericTarget.classList.add('-squared')
    this.resultsTooShortTarget.classList.add('-squared')
  }

  startSearch(_event) {
    if (this.queryTarget.value.startsWith(' ')) {
      this.queryTarget.value = this.query
    }

    this.resultsTooShortNumericTarget.classList.remove('-squared')
    this.resultsTooShortTarget.classList.remove('-squared')

    if (this.query === '*' && this.testEnvironmentValue) {
      // Wildcard search
      this.submitTarget.click()
    } else if (this.query.length === 0) {
      // Blank search string
      this.setFormState()
      this.renderResults('')
      this.resultsTooShortTarget.classList.add('u-hide')
      this.resultsTooShortNumericTarget.classList.add('u-hide')
    } else if (!this.isNumericValue(this.query) && this.query.length < 7) {
      // Too short numeric search string
      this.setFormState()
      this.renderResults('')
      this.resultsTooShortNumericTarget.classList.remove('u-hide')
      this.resultsTooShortTarget.classList.add('u-hide')
    } else if (this.isNumericValue(this.query) && this.query.length < 4) {
      // Too short word search string
      this.setFormState()
      this.renderResults('')
      this.resultsTooShortTarget.classList.remove('u-hide')
      this.resultsTooShortNumericTarget.classList.add('u-hide')
    } else {
      // Valid search string
      this.resultsTooShortTarget.classList.add('u-hide')
      this.resultsTooShortNumericTarget.classList.add('u-hide')

      if (this.hasMedicationOutlet) {
        this.medicationOutlet.hideForm()
      }

      this.submitTarget.click()
    }
  }

  // ==== Getters/Setters

  get currentPage() {
    return this.currentPageTarget.value
  }

  set currentPage(value) {
    this.currentPageTarget.value = value
  }

  get query() {
    return this.queryTarget.value.trim()
  }

  get resultsURL() {
    return this.resultsUrlTarget.getAttribute('data-results-url')
  }

  // ==== Private

  getCurrentPageParam(url) {
    return getParamByName('page', url) || 1
  }

  resetActiveStates() {
    if (this.hasSavedForLaterTabTarget) this.savedForLaterTabTarget.classList.remove('-active')
    if (this.hasCompletedTabTarget) this.completedTabTarget.classList.remove('-active')
  }

  isNumericValue(val) {
    return Number.isNaN(Number(val))
  }

  // ==== Channels

}
