import ApplicationController from '../support/application_controller'
// import Pristine              from 'pristinejs'

export default class extends ApplicationController {

  static targets = []

  // initialize() {
  //   const config = {
  //     classTo:         'a-input',
  //     errorClass:      '-error',
  //     successClass:    'has-success',
  //     errorTextParent: 'a-input',
  //     errorTextTag:    'p',
  //     errorTextClass:  'a-input__error'
  //   }
  //
  //   const form     = this.element
  //   const pristine = new Pristine(form, config, true)
  //
  //   form.addEventListener('submit', function (e) {
  //     e.preventDefault()
  //     const valid = pristine.validate()
  //   })
  // }

  // ==== Controllers

  // ==== Actions

  // ==== Getters

  // ==== Setters

  // ==== Private

  // ==== Channels

}
