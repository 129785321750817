import ApplicationController from '../../frontend/support/application_controller'
import $fq                   from '../../frontend/support/fake_query'

export default class extends ApplicationController {

  static targets = [
    'subtract',
    'add',
    'value'
  ]

  static values = {
    href:    String,
    max:     Number,
    min:     Number,
    spinner: Boolean,
    timeout: Number
  }

  static classes = [
    'addLoading',
    'atMax',
    'atMin',
    'subtractLoading'
  ]

  initialize() {}

  connect() {}

  // ==== Controllers

  // ==== Actions

  subtract(event) {
    if (this.hasMinValue && this.value <= this.minValue) {
      event.preventDefault()
      return
    }

    if (!this.spinnerValue) {
      this.value -= 1
      this.updateState()
    } else {
      this.loading('subtract', event)
    }
  }

  add(event) {
    if (this.hasMaxValue && this.value >= this.maxValue) {
      event.preventDefault()
      return
    }

    if (!this.spinnerValue) {
      this.value += 1
      this.updateState()
    } else {
      this.loading('add', event)
    }
  }

  // ==== Getters

  get value() {
    return parseInt(this.valueTarget.textContent, 10)
  }

  // ==== Setters

  set value(x) {
    this.valueTarget.textContent = x.toString()
  }

  // ==== Private

  updateState() {
    if (this.hasMaxValue) {
      this.element.classList.toggle(this.atMaxClass, this.value >= this.maxValue)
    }
    if (this.hasMinValue) {
      this.element.classList.toggle(this.atMinClass, this.value <= this.minValue)
    }
  }

  loading(type, event) {
    const nodeName = event.currentTarget.nodeName
    if (nodeName === 'A') {
      this.handleAnchor(type, event)
    } else if (nodeName === 'BUTTON' || nodeName === 'INPUT') {
      this.handleButton(type)
    }
  }

  handleAnchor(type, event) {
    const anchor = type === 'subtract' ? this.subtractTarget : this.addTarget
    const otherAnchor = type === 'subtract' ? this.addTarget : this.subtractTarget
    const loadingClass = this[`${type}LoadingClass`]

    // Prevent link from being clicked again if the href has been removed
    if (!anchor.hasAttribute('href')) event.preventDefault()

    $fq(this.element).addClass(loadingClass)

    // Re-enable link after `this.timeoutValue`ms. Default 27 seconds.
    setTimeout(() => {
      anchor.setAttribute('href', this.hrefValue)
      anchor.removeAttribute('aria-disabled')
      otherAnchor.removeAttribute('aria-disabled')
      $fq(this.element).removeClass(loadingClass)
      this.hrefValue = undefined
    }, this.timeoutValue)

    // Small timeout to allow the link to serialize and perform its action
    // before removing the href
    setTimeout(() => {
      this.hrefValue = anchor.href
      anchor.setAttribute('aria-disabled', 'true')
      otherAnchor.setAttribute('aria-disabled', 'true')
      anchor.removeAttribute('href')
    }, 13)
  }

  handleButton(type, event) {
    const button = type === 'subtract' ? this.subtractTarget : this.addTarget
    const otherButton = type === 'subtract' ? this.addTarget : this.subtractTarget
    const loadingClass = this[`${type}LoadingClass`]

    // Prevent button from being clicked again if it is disabled
    if (button.disabled) event.preventDefault()

    $fq(this.element).addClass(loadingClass)

    // Re-enable button after `this.timeoutValue`ms. Default 27 seconds.
    setTimeout(() => {
      button.disabled = false
      otherButton.disabled = false
      $fq(this.element).removeClass(loadingClass)
    }, this.timeoutValue)

    // Small timeout to allow the button to serialize and perform its action
    // before disabling.
    setTimeout(() => {
      button.disabled = true
      otherButton.disabled = true
    }, 13)
  }

  // ==== Channels

}
