import ApplicationController from '../support/application_controller'
import I18n                  from '../support/i18n'
import $fq                   from '../support/fake_query'

export default class extends ApplicationController {

  static targets = [
    'fallsExtraQuestion',
    'fallsExtraQuestionInput',
    'idDiscoveryNumber',
    'idLegalReference',
    'idVerified',
    'lipogramDetails',
    'lipogramInput',
    'nonSmokerDeclaration',
    'pneumococcalVaccination',
    'pneumococcalVaccinationInput',
    'qrCode',
    'resetDiscoveryInput',
    'resetIdInput',
    'resetInput',
    'responseBody',
    'shinglesVaccination',
    'shinglesVaccinationInput',
    'smokingDetails',
    'smokingHistory',
    'smokingHistoryTitle',
    'smokingInput',
    'standaloneAssessments',
    'vctConfirmation',
    'vctConfirmationInput',
    'vctDetails',
    'vctInput',
    'hba1cDetails',
    'hba1cInput'
  ]

  static outlets = [
    'consultation'
  ]

  connect() {}

  initialize() {}

  disconnect() {}

  // ==== Actions

  renderConsultation(event) {
    const [_response, _status, xhr] = event.detail

    $fq(this.consultationOutlet.responseBodyTarget).html(xhr.response)
  }

  renderDashboard(event) {
    const [_response, _status, xhr] = event.detail

    $fq(this.element).replaceWith(xhr.response)
  }

  renderForm(event) {
    const [_response, _status, xhr] = event.detail

    $fq(this.responseBodyTarget).html(xhr.response)
  }

  renderVerificationForm(event) {
    const [_response, _status, xhr] = event.detail

    $fq(this.consultationOutlet.responseBodyTarget).html(xhr.response)
  }

  toggleStandaloneAssessments(event) {
    const element = event.currentTarget

    $fq(element).hide()
    $fq(this.standaloneAssessmentsTarget).show()
  }

  toggleFallsExtraQuestion(event) {
    const element = event.currentTarget

    this.fallsExtraQuestionInputTarget.value = ''

    switch (element.value) {
      case '1':
        $fq(this.fallsExtraQuestionTarget).show()
        break
      case '0':
        $fq(this.fallsExtraQuestionTarget).hide()
        break
      default:
        return null
    }

    return null
  }

  toggleLipogram(event) {
    const element = event.currentTarget
    const showLipogram = !this.lipogramDetailsTarget.dataset.showLipogram

    if (parseInt(element.value, 10) >= 5 && showLipogram) {
      $fq(this.lipogramDetailsTarget).show()
    } else {
      this.resetInputs(this.lipogramInputTargets)
      $fq(this.lipogramDetailsTarget).hide()
    }
  }

  togglePatientVerification(event) {
    const element = event.currentTarget

    if (element.value === 'legal_reference') {
      $fq(this.idDiscoveryNumberTarget).hide()
      $fq(this.idLegalReferenceTarget).show()
      this.resetInputs(this.resetDiscoveryInputTargets)
    } else if (element.value === 'discovery_number') {
      $fq(this.idDiscoveryNumberTarget).show()
      $fq(this.idLegalReferenceTarget).hide()
      this.resetInputs(this.resetIdInputTargets)
    }

    if (this.hasIdVerifiedTarget) {
      $fq(this.idVerifiedTarget).show()
    }
  }

  togglePneumococcalVaccination(event) {
    const element = event.currentTarget

    this.resetInputs(this.pneumococcalVaccinationInputTargets)

    switch (element.value) {
      case '0':
        $fq(this.pneumococcalVaccinationTarget).hide()
        break
      case '1':
        $fq(this.pneumococcalVaccinationTarget).show()
        break
      default:
        return null
    }

    return null
  }

  toggleQRCode(_event) {
    if (this.hasQrCodeTarget) {
      $fq(this.qrCodeTarget).toggleClass('u-hide')
    }
  }

  toggleShingles(event) {
    const element = event.currentTarget

    this.resetInputs(this.shinglesVaccinationInputTargets)

    switch (element.value) {
      case '0':
        $fq(this.shinglesVaccinationTarget).hide()
        break
      case '1':
        $fq(this.shinglesVaccinationTarget).show()
        break
      default:
        return null
    }

    return null
  }

  toggleSmokingStatus(event) {
    const element = event.currentTarget

    this.resetInputs(this.smokingInputTargets)

    switch (element.value) {
      case '1':
        $fq(this.smokingDetailsTarget).show()
        $fq(this.smokingHistoryTarget).hide()
        $fq(this.nonSmokerDeclarationTarget).show()
        break
      case '2':
        $fq(this.smokingHistoryTitleTarget).text(I18n.t('consultation_note.vhc.standalone.smokers_declaration_form.smoking_period_ex_smoker'))
        $fq(this.smokingDetailsTarget).show()
        $fq(this.smokingHistoryTarget).show()
        $fq(this.nonSmokerDeclarationTarget).show()
        break
      case '3':
        $fq(this.smokingHistoryTitleTarget).text(I18n.t('consultation_note.vhc.standalone.smokers_declaration_form.smoking_period_smoker'))
        $fq(this.smokingDetailsTarget).show()
        $fq(this.smokingHistoryTarget).show()
        $fq(this.nonSmokerDeclarationTarget).hide()
        break
      default:
        return null
    }

    return null
  }

  toggleVctConfirmation(event) {
    const element = event.currentTarget

    this.resetInputs(this.vctConfirmationInputTargets)

    if (['1', '3'].includes(element.value)) {
      $fq(this.vctConfirmationTarget).show()
    } else {
      $fq(this.vctConfirmationTarget).hide()
    }
  }

  toggleVctDetails(event) {
    const element = event.currentTarget

    this.resetInputs(this.vctInputTargets)

    if (element.checked) {
      $fq(this.vctDetailsTarget).show()
    } else {
      $fq(this.vctDetailsTarget).hide()
    }
  }

  toggleHba1c(event) {
    const element = event.currentTarget
    const showHba1c = !this.hba1cDetailsTarget.dataset.showHba1c

    if ((parseFloat(element.value).toFixed(1) >= 7.8) && showHba1c) {
      $fq(this.hba1cDetailsTarget).show()
    } else {
      this.resetInputs(this.hba1cInputTargets)
      $fq(this.hba1cDetailsTarget).hide()
    }
  }

  // ==== Getters

  // ==== Setters

  // ==== Private

  resetInputs(inputs) {
    inputs.forEach((element) => {
      const type = element.getAttribute('type')

      if (type === 'checkbox' || type === 'radio') {
        element.checked = false
      } else {
        element.value = ''
      }
    })
  }

  // ==== Channels

}
