import WizardController from '../wizard_controller'

export default class extends WizardController {

  static targets = [
    'noneInverseSurgicalHistory',
    'noneInverseOtherHospitalHistory',
    'otherHospitalHistoryDetails',
    'otherHospitalHistoryDetailsInput',
    'surgicalHistoryDetails',
    'surgicalHistoryDetailsInput'
  ]

  static values = {}

  static classes = []

  initialize() {}

  connect() {}

  // ==== Controllers

  // ==== Actions

  toggleNoneInverseSurgicalHistory(event) {
    this.toggleNoneInverseSelection({
      input:  event.currentTarget,
      target: this.noneInverseSurgicalHistoryTarget
    })
  }

  toggleNoneInverseOtherHospitalHistory(event) {
    this.toggleNoneInverseSelection({
      input:  event.currentTarget,
      target: this.noneInverseOtherHospitalHistoryTarget
    })
  }

  toggleSurgicalHistoryDetails(event) {
    this.toggleCheckboxMoreDetails({
      input:  event.currentTarget,
      target: this.surgicalHistoryDetailsTarget
    })
  }

  addSurgicalHistoryDetailsItem() {
    this.addNestedForm({
      list:      this.surgicalHistoryDetailsInputTargets,
      inputType: "input[type='text']",
      wrapper:   this.surgicalHistoryDetailsTarget
    })
  }

  removeSurgicalHistoryDetailsItem(event) {
    this.removeNestedForm({
      button:    event.currentTarget,
      wrapper:   this.surgicalHistoryDetailsTarget,
      list:      this.surgicalHistoryDetailsInputTargets,
      inputType: "input[type='text']"
    })
  }

  toggleOtherHospitalHistoryDetails(event) {
    this.toggleCheckboxMoreDetails({
      input:  event.currentTarget,
      target: this.otherHospitalHistoryDetailsTarget
    })
  }

  addOtherHospitalHistoryDetailsItem() {
    this.addNestedForm({
      list:      this.otherHospitalHistoryDetailsInputTargets,
      inputType: "input[type='text']",
      wrapper:   this.otherHospitalHistoryDetailsTarget
    })
  }

  removeOtherHospitalHistoryDetailsItem(event) {
    this.removeNestedForm({
      button:    event.currentTarget,
      wrapper:   this.otherHospitalHistoryDetailsTarget,
      list:      this.otherHospitalHistoryDetailsInputTargets,
      inputType: "input[type='text']"
    })
  }

  // ==== Getters

  // ==== Setters

  // ==== Private

  // ==== Channels

}
