import camelCase             from 'lodash/camelCase'
import ApplicationController from '../support/application_controller'
import CreateChannel         from '../client/cable'

// TODO: get rid of this once :new_gp_lobby goes live permanently (HEAL-5834)
// DEPRECATED: replaced by gp_lobby_controller
export default class extends ApplicationController {

  static targets = [
    'closedItem',
    'closedList',
    'closedCount',
    'myClosedItem',

    'myCallsItem',
    'myCallsList',
    'myCallsCount',
    'myCallsDivider',

    'onHoldItem',
    'onHoldList',
    'onHoldCount',

    'ringingItem',
    'ringingList',
    'ringingCount'
  ]

  connect() {}

  initialize() {
    this.subscribeDoctorLobbyChannel()
  }

  disconnect() {}

  // ==== Controllers

  // ==== Actions

  // ==== Getters

  // ==== Setters

  // ==== Private

  // Call cards
  insertCall(target, position, html) {
    const targetStr = `${target}_list_target`

    if (this[camelCase(`has_${targetStr}`)]) {
      this[camelCase(targetStr)].insertAdjacentHTML(position, html)
    }
  }

  removeCall(id) {
    const item = this.element.querySelectorAll(`[data-videomed-call-card-call-id='${id}']`)
    item.forEach((element) => {
      element.remove()
    })
  }

  // Answer buttons
  enableFirstAnswerButton() {
    const button = this.ringingListTarget.firstChild
    if (button) {
      const elementController = this.getControllerForElement(button, 'videomed-call-card')
      if (elementController) {
        elementController.handleGlobalRingingButton()
      }
    }
  }

  removeAllAnswerButtons() {
    if (this.hasRingingItemTarget) {
      this.ringingItemTargets.forEach((item) => {
        item.querySelector('[data-videomed-call-card-target="action"]').innerHTML = ''
      })
    }
  }

  processCallList(positions) {
    const self         = this
    const ringingItems = this.ringingItemTargets

    // Toggle divider
    if (this.hasMyCallsDividerTarget) {
      if (this.myCallsListTarget.childNodes.length > 0 && this.ringingListTarget.childNodes.length > 0) {
        this.myCallsDividerTarget.classList.remove('u-hide')
      } else {
        this.myCallsDividerTarget.classList.add('u-hide')
      }
    }

    // Update all queue positions
    ringingItems.forEach((item) => {
      const lookupId = item.dataset.videomedCallCardCallId
      item.dataset.videomedCallCardQueuePosition = positions[lookupId] || 100
    })

    // Sort cards
    Array.from(ringingItems).sort(
      (a, b) => a.dataset.videomedCallCardQueuePosition.localeCompare(
        b.dataset.videomedCallCardQueuePosition,
        undefined,
        { numeric: true }
      )
    ).forEach((item, x) => {
      const statusCounter = item.querySelector('[data-status-counter]')

      if (statusCounter) {
        statusCounter.textContent = x + 1
      }

      // Set notification id on first card
      if (x === 0) {
        const lookupId = item.dataset.videomedCallCardCallId
        self.ringingListTarget.dataset.notificationId = lookupId
      }

      item.dataset.videomedCallCardStatusPosition = x + 1
      item.parentNode.appendChild(item)
    })

    this.removeAllAnswerButtons()

    this.updateCounter('ringing')
    this.updateCounter('on_hold')
    this.updateCounter('closed')
  }

  updateCounter(action) {
    let count = 0

    switch (action) {
      case 'ringing':
        count = parseInt(this.ringingItemTargets.length, 10) +
                parseInt(this.myCallsListTarget.children.length, 10)
        break

      case 'on_hold':
        count = this.onHoldItemTargets.length
        break

      case 'closed':
        count = this.myClosedItemTargets.length
        break

      default:
        break
    }

    if (this[camelCase(`has_${action}_count_target`)]) {
      const target = this[camelCase(`${action}_count_target`)]
      target.textContent = count
    }
  }

  // ==== Channels

  subscribeDoctorLobbyChannel() {
    if (this.channelSubscription === undefined) {
      const self = this

      this.channelSubscription = CreateChannel({
        channel: 'DoctorLobbyChannel'
      }, {
        connected() {},

        disconnected() {},

        received(data) {
          self.removeCall(data.item_id)

          switch (data.action) {
            case 'force:ringing':
            case 'transition:capture_medical_history:ringing':
              self.insertCall('ringing', 'beforeend', data.item_html)
              break

            case 'transition:answered:ringing':
              if (data.doctor_id) {
                self.insertCall('my_calls', 'beforeend', data.item_html)
              } else {
                self.insertCall('ringing', 'beforeend', data.item_html)
              }
              break

            // Transition from ringing
            case 'transition:ringing:answered':
              if (data.doctor_id) {
                self.insertCall('my_calls', 'beforeend', data.item_html)
              }
              break

            case 'transition:ringing:on_hold':
              self.insertCall('on_hold', 'afterbegin', data.item_html)
              break

            // Transition from on_hold
            case 'transition:on_hold:answered':
              if (data.doctor_id) {
                self.insertCall('my_calls', 'afterbegin', data.item_html)
              }
              break

            case 'transition:on_hold:ringing':
              if (data.doctor_id) {
                self.insertCall('my_calls', 'beforeend', data.item_html)
              } else {
                self.insertCall('ringing', 'beforeend', data.item_html)
              }
              break

            // Transition to cancelled or closed
            case 'cancelled':
            case 'closed':
              if (data.doctor_id) {
                self.insertCall('closed', 'afterbegin', data.item_html)
              }
              break

            default:
              break
          }

          self.processCallList(data.queue_positions)
        }
      })
    }

    return this.channelSubscription
  }

}
