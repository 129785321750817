import ApplicationController from '../support/application_controller'
import { playSound }         from '../support/audio_sound'
import I18n                  from '../support/i18n'
import displayNotification   from '../support/notifications'

export default class extends ApplicationController {

  static targets = [
    'action',
    'templAnswered',
    'templCompleted',
    'templRinging'
  ]

  static outlets = [
    'doctor-appear'
  ]

  connect() {
    this.handleGlobalRingingButton()
    this.handleLocalRingingButton()
    this.handleAnsweredButton()
    this.handleCompletedButton()
    this.handleCancelledButton()
  }

  // ==== Actions

  // ==== Getters

  get buttonAction() {
    return this.data.get('button-action')
  }

  get callId() {
    return this.data.get('call-id')
  }

  get status() {
    return this.data.get('status')
  }

  get statusPosition() {
    return this.data.get('status-position')
  }

  get queuePosition() {
    return this.data.get('queue-position')
  }

  get localAnsweredCallCount() {
    return document.querySelectorAll('[data-videomed-call-card-button-action="answered:local"]').length
  }

  get localRingingCallCount() {
    return document.querySelectorAll('[data-videomed-call-card-button-action="ringing:local"]').length
  }

  // ==== Setters

  // ==== Private

  addRingingButton() {
    const button = document.importNode(this.templRingingTarget.content, true)

    if (this.actionTarget.innerHTML === '') {
      this.actionTarget.appendChild(button)
      playSound('#notification')
      displayNotification({
        body: I18n.t('doctor_lobby.index.ringing_calls.notification_body')
      })
    }
  }

  addEditButton() {
    const button = document.importNode(this.templCompletedTarget.content, true)
    let html

    // This step is needed due to importNode cloning the contents of the <form>
    // tag and duplicating it outside of the <form> tag.
    // Example: <p>Some content</p><form><p>Some content</p></form>
    const formNode = button.querySelector('form')

    if (formNode) {
      html = formNode.outerHTML
    } else {
      const serializer = new XMLSerializer()
      html = serializer.serializeToString(button)
    }

    if (this.actionTarget.innerHTML === '') {
      this.actionTarget.insertAdjacentHTML('beforeend', html)
    }
  }

  handleLocalRingingButton() {
    if (!this.buttonAction || !this.buttonAction.match(/ringing:local/)) return
    if (this.hasDoctorAppearOutlet && !this.doctorAppearOutlet.onlineStatus) return
    this.addRingingButton()
  }

  handleGlobalRingingButton() {
    if (!this.buttonAction || !this.buttonAction.match(/ringing:global/)) return
    if (this.localRingingCallCount > 0) return
    if (this.localAnsweredCallCount > 0) return
    if (this.hasDoctorAppearOutlet && !this.doctorAppearOutlet.onlineStatus) return

    if (parseInt(this.statusPosition, 10) === 1) {
      this.addRingingButton()
    }
  }

  handleCompletedButton() {
    if (!this.buttonAction || !this.buttonAction.match(/closed:local/)) return
    this.addEditButton()
  }

  handleCancelledButton() {
    if (!this.buttonAction || !this.buttonAction.match(/cancelled:local/)) return
    this.addEditButton()
  }

  handleAnsweredButton() {
    if (!this.buttonAction || !this.buttonAction.match(/answered:local/)) return

    const button = document.importNode(this.templAnsweredTarget.content, true)

    if (this.actionTarget.innerHTML === '') {
      this.actionTarget.appendChild(button)
    }
  }

  // ==== Channels

}
