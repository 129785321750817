import WizardController from '../../../wizard_controller'

export default class extends WizardController {

  static targets = [
    'pregnancyNotTestedSubform',
    'urineTestStripSubform'
  ]

  // ==== Controllers

  // ==== Actions

  togglePregnancySelections({ currentTarget }) {
    if (currentTarget.value === 'not_tested') {
      this.showRadioButtonMoreDetails({ target: this.pregnancyNotTestedSubformTarget })
    } else {
      this.hideRadioButtonMoreDetails({ target: this.pregnancyNotTestedSubformTarget })
    }
  }

  toggleUrineTestStripSubform({ currentTarget }) {
    this.toggleCheckboxMoreDetails({
      input:  currentTarget,
      target: this.urineTestStripSubformTarget
    })
  }

  // ==== Getters

  // ==== Setters

  // ==== Private

  // ==== Channels

}
