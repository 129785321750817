import ApplicationController from '../support/application_controller'
import I18n                  from '../support/i18n'
import $fq                   from '../support/fake_query'

export default class extends ApplicationController {

  static targets = ['submitButton']

  static values = {}

  static classes = []

  initialize() { }

  connect() { }

  // ==== Controllers

  // ==== Actions

  formInput() {
    this.submitButtonTarget.textContent = I18n.t('app.continue')
    $fq(this.submitButtonTarget).removeClass('-style-secondary')
  }

  // ==== Getters

  // ==== Setters

  // ==== Private

  // ==== Channels

}
