import ApplicationController from '../support/application_controller'
import $fq                   from '../support/fake_query'

export default class extends ApplicationController {

  static targets = [
    'button'
  ]

  initialize() {
    const observer = new MutationObserver((mutations) => { this.handleRailsUJSStateChange(mutations) })
    observer.observe(this.buttonElement, {
      attributes:      true,
      attributeFilter: ['disabled'],
      childList:       false
    })
  }

  connect() {}

  // ==== Controllers

  // ==== Actions

  // ==== Getters

  get buttonElement() {
    if (this.hasButtonTarget) {
      return this.buttonTarget
    }

    return this.element
  }

  // ==== Setters

  // ==== Private

  async handleRailsUJSStateChange(mutations) {
    const buttonDisabled = mutations[0].target.disabled

    if (buttonDisabled) {
      this.addSpinnerClass()
    } else {
      this.removeSpinnerClass()
    }
  }

  addSpinnerClass() {
    $fq(this.buttonElement).addClass('-loaderActive')
  }

  removeSpinnerClass() {
    $fq(this.buttonElement).removeClass('-loaderActive')
  }

  // ==== Channels

}
