import ApplicationController from '../support/application_controller'

export default class extends ApplicationController {

  static targets = [
    'reviewSection',
    'formSubmit',
    'modalSubmit',
    'textField',
    'form'
  ]

  connect() {
    if (this.hasFormTarget) {
      this.formTarget.hidden = false
    }
  }

  // ==== Controllers

  // ==== Actions

  toggleReviewSection(event) {
    const element = event.currentTarget

    if (element.value === 'confirmation_no_charge') {
      this.reviewSectionTarget.hidden = true
      this.formSubmitTarget.hidden    = true
      this.modalSubmitTarget.hidden   = false
    } else {
      this.reviewSectionTarget.hidden = false
      this.formSubmitTarget.hidden    = false
      this.modalSubmitTarget.hidden   = true
    }
  }

  toggleTextField(event) {
    const element = event.currentTarget

    if (element.value === 'other') {
      this.textFieldTarget.hidden = false
    } else {
      this.textFieldTarget.hidden = true
    }
  }

  // ==== Getters

  // ==== Setters

  // ==== Private

  // ==== Channels

}
