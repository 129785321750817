import SimpleAssessmentController from './simple_assessment_controller'
import SmokingHabitsMixin         from '../medical_histories/smoking_habits_mixin'
import { clearInputErrors }       from '../../support/helpers'

export default class extends SmokingHabitsMixin(SimpleAssessmentController) {

  static targets = [
    ...super.targets,
    'fields',
    'section'
  ]

  static classes = [
    'hide'
  ]

  static values = {
    condition: String
  }

  conditionValueChanged() {
    this.showHideSections()
  }

  // ==== Controllers

  // ==== Actions

  selectCondition({ currentTarget: { value } }) {
    this.fieldsTarget.classList.remove('u-hide')
    this.conditionValue = value

    clearInputErrors(this.element)
  }

  // ==== Getters

  // ==== Setters

  // ==== Private

  sectionConditions(section) {
    return section.dataset.conditions.split(' ')
  }

  showHideSections() {
    this.sectionTargets.forEach((section) => {
      const active = this.sectionConditions(section).includes(this.conditionValue)
      this.toggleTarget(section, active)
    })
  }

  // ==== Channels

}
