import ApplicationController from '../../support/application_controller'

export default class extends ApplicationController {

  static targets = [
    'autoConsent',
    'mergeAccountRadio',
    'mergeAccountConsentForm',
    'patient',
    'submitContinue',
    'toggle'
  ]

  // ==== Controllers

  // ==== Actions

  select(event) {
    const element      = event.currentTarget
    const labelElement = element.closest('label')

    this.autoConsentTarget.value = labelElement.getAttribute('data-auto-consent')

    this.toggleTargets.forEach((target) => {
      if (target.value !== element.value) {
        target.checked = false
        target.closest('label').classList.add('u-hide')
      } else {
        target.closest('label').classList.remove('u-hide')
      }
    })

    if (this.autoConsentTarget.value === 'false') {
      this.mergeAccountRadioTarget.querySelectorAll('[type="radio"]').forEach((target) => {
        target.checked = false
      })
      this.toggleMergeAccountConsentForm('show')
    } else if (this.autoConsentTarget.value === 'true') {
      this.mergeAccountRadioTarget.querySelector('input[type="radio"][value="yes"]').checked = true
      this.toggleMergeAccountConsentForm('hide')
    }

    if (!element.checked) {
      this.resetForm()
      this.toggleMergeAccountConsentForm('hide')
      this.toggleTargets.forEach((target) => {
        target.closest('label').classList.remove('u-hide')
      })
    }
  }

  // ==== Getters

  // ==== Setters

  // ==== Private

  resetForm() {
    this.autoConsentTarget.value = ''
    this.mergeAccountConsentFormTarget.querySelector('input[type="radio"]').checked = false

    this.toggleTargets.forEach((target) => {
      target.checked = false
    })
  }

  toggleMergeAccountConsentForm(state) {
    if (state === 'hide') {
      this.mergeAccountConsentFormTarget.classList.add('u-hide')
    } else if (state === 'show') {
      this.mergeAccountConsentFormTarget.classList.remove('u-hide')
    }
  }

  // ==== Channels

}
