import ApplicationController from '../support/application_controller'

export default class extends ApplicationController {

  static targets = [
    'localVideoTrack',
    'participantAudioStatus',
    'participantIdentity',
    'preview',
    'remoteVideoTrack',
    'statusMessage'
  ]

  initialize() {
    if (window.opener) {
      window.opener.secondScreenController = this
    }

    this.trackListener()
    setInterval(() => this.trackListener(), 1000)
  }

  // ==== Controllers

  // ==== Actions

  trackListener() {
    if (!window.opener && !window.opener.mainScreenController) return

    const mainScreenController = window.opener.mainScreenController

    if (mainScreenController) {
      this.handlePlayerUI()

      this.attachLocalVideoTrack(mainScreenController)
      this.attachRemoteVideoTrack(mainScreenController)
    }
  }

  attachLocalVideoTrack(mainScreenController) {
    if (mainScreenController.hasLocalVideoTrackTarget) {
      this.previewTarget.classList.remove('u-hide')

      if (mainScreenController.localVideoTrackTarget.srcObject) {
        const thatSrcObject = mainScreenController.localVideoTrackTarget.srcObject
        const thisSrcObject = this.localVideoTrackTarget.srcObject

        if (thisSrcObject) {
          if (thisSrcObject.id !== thatSrcObject.id) {
            this.localVideoTrackTarget.srcObject = thatSrcObject
          }
        } else {
          this.localVideoTrackTarget.srcObject = thatSrcObject
        }

        // Reference for future UX handeling
        // var promise = document.querySelector('video').play();
        // if (promise !== undefined) {
        //   promise.then(_ => {
        //     // Autoplay started!
        //   }).catch(error => {
        //     // Autoplay was prevented.
        //     // Show a "Play" button so that user can start playback.
        //   });
        // }

        if (this.localVideoTrackTarget.paused) {
          this.localVideoTrackTarget.play()
        }
      }
    } else {
      this.previewTarget.classList.add('u-hide')
    }
  }

  attachRemoteVideoTrack(mainScreenController) {
    if (mainScreenController.hasRemoteVideoTrackTarget && mainScreenController.remoteVideoTrackTarget.srcObject) {
      const thatSrcObject = mainScreenController.remoteVideoTrackTarget.srcObject
      const thisSrcObject = this.remoteVideoTrackTarget.srcObject

      if (thisSrcObject) {
        if (thisSrcObject.id !== thatSrcObject.id) {
          this.remoteVideoTrackTarget.srcObject = thatSrcObject
        }
      } else {
        this.remoteVideoTrackTarget.srcObject = thatSrcObject
      }

      if (this.remoteVideoTrackTarget.paused) {
        this.remoteVideoTrackTarget.play()
      }
    }
  }

  // ==== Getters

  get localStream() {
    return window.opener.mainScreenController.localVideoTrackTarget.srcObject
  }

  get participantAudioStatus() {
    return window.opener.mainScreenController.participantAudioStatusTarget
  }

  get participantIdentity() {
    return window.opener.mainScreenController.participantIdentityTarget
  }

  get remoteStream() {
    return window.opener.mainScreenController.remoteVideoTrackTarget.srcObject
  }

  get statusMessage() {
    return window.opener.mainScreenController.statusMessageTarget
  }

  get videomedCallStatus() {
    return window.opener.mainScreenController.videomedCallStatus
  }

  // ==== Setters

  // ==== Private

  handlePlayerUI() {
    if (this.videomedCallStatus === 'answered') {
      this.element.classList.add('-active')
      this.element.classList.remove('-disabled')
    } else {
      this.element.classList.remove('-active')
      this.element.classList.add('-disabled')
    }

    this.setStatusMessage()
    this.setParticipantAudioStatus()
    this.setParticipantIdentity()
  }

  setStatusMessage() {
    const currentHTML = this.statusMessageTarget.innerHTML
    const newHTML     = this.statusMessage.innerHTML

    if (currentHTML !== newHTML) {
      this.statusMessageTarget.innerHTML = this.statusMessage.innerHTML
    }
  }

  setParticipantAudioStatus() {
    const currentClassList = this.participantAudioStatusTarget.classList
    const newClassList     = this.participantAudioStatus.classList

    if (currentClassList !== newClassList) {
      this.participantAudioStatusTarget.classList = this.participantAudioStatus.classList
    }
  }

  setParticipantIdentity() {
    const currentHTML = this.participantIdentityTarget.innerHTML
    const newHTML     = this.participantIdentity.innerHTML

    if (currentHTML !== newHTML) {
      this.participantIdentityTarget.classList = this.participantIdentity.classList
      this.participantIdentityTarget.innerHTML = this.participantIdentity.innerHTML
    }
  }

  // ==== Channels

}
