import ApplicationController from '../support/application_controller'

export default class extends ApplicationController {

  static targets = [
    'input'
  ]

  static classes = [
    'filled'
  ]

  connect() {
    this.update()
  }

  // ==== Controllers

  // ==== Actions

  clear() {
    this.inputTarget.value = ''
    this.inputTarget.focus()
    this.inputTarget.dispatchEvent(new Event('input', { bubbles: true }))
    this.update()
  }

  update() {
    setTimeout(() => {
      this.element.classList.toggle(this.filledClass, this.filled)
    })
  }

  // ==== Getters

  get filled() {
    return this.inputTarget.value.length > 0
  }

  // ==== Setters

  // ==== Private

  // ==== Channels

}
