import ApplicationController from '../../frontend/support/application_controller'

export default class extends ApplicationController {

  static targets = []

  static values = {
    autoOpen:     Boolean,
    closeOnClick: Boolean,
    closeOnEsc:   Boolean
  }

  static classes = [
    'open'
  ]

  initialize() {
    this.bindCloseOnEsc()
  }

  connect() {
    if (this.isOpen) this.dismissAnyPopovers()
  }

  // ==== Controllers

  // ==== Actions

  close() {
    this.element.classList.remove(this.openClass)
  }

  open() {
    this.element.classList.add(this.openClass)
    this.dismissAnyPopovers()
  }

  // ==== Getters

  get isOpen() {
    return this.element.classList.contains(this.openClass)
  }

  // ==== Setters

  // ==== Private

  bindCloseOnEsc() {
    if (!this.closeOnEscValue) return

    document.addEventListener('keydown', (event) => {
      if (event.key === 'Escape') {
        const isNotCombinedKey = !(event.ctrlKey || event.altKey || event.shiftKey)
        if (isNotCombinedKey) {
          this.close()
        }
      }
    })
  }

  dismissAnyPopovers() {
    for (const popover of document.querySelectorAll(':popover-open')) {
      popover.hidePopover()
    }
  }

  // ==== Channels

}
