import ApplicationController from '../support/application_controller'

export default class extends ApplicationController {

  static targets = [
    'printGlyph',
    'successGlyph'
  ]

  // ==== Controllers

  // ==== Actions

  toggleGlyphs() {
    this.printGlyphTarget.classList.add('u-hide')
    this.successGlyphTarget.classList.remove('u-hide')
  }

  // ==== Getters

  // ==== Setters

  // ==== Private

  // ==== Channels

}
