import ApplicationController from '../../support/application_controller'
import { disableElement } from '../../support/helpers'

export default class extends ApplicationController {

  static targets = [
    'disableOnConfirm',
    'submit'
  ]

  // ==== Controllers

  // ==== Actions

  selectQueue() {
    this.submitTarget.disabled = false
  }

  confirm() {
    for (const el of this.disableOnConfirmTargets) {
      disableElement(el)
    }
  }

  // ==== Getters

  // ==== Setters

  // ==== Private

  // ==== Channels

}
