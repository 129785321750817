import { debounce }          from 'lodash'
import ApplicationController from '../../support/application_controller'
import { timeout }           from '../../support/helpers'

export default class extends ApplicationController {

  static targets = [
    'loader',
    'results',
    'submit'
  ]

  #formSubmission = null

  initialize() {
    this.debouncedSubmit = debounce(this.debouncedSubmit.bind(this), 800)
  }

  // ==== Controllers

  // ==== Actions

  async input({ currentTarget, params: { min } }) {
    await timeout(2) // wait for cleave updates
    const { value } = currentTarget
    if (value.length >= min || typeof min !== 'number') {
      this.#autoSubmit()
    } else if (value.length === 0) {
      this.#clearResults()
    }
  }

  submitStart({ detail: { formSubmission } }) {
    this.#formSubmission = formSubmission
    this.#showLoader()
  }

  hideLoader() {
    this.loaderTarget.classList.add('u-hide')
  }

  // ==== Getters

  // ==== Setters

  // ==== Private

  debouncedSubmit() {
    this.submitTarget.click()
  }

  #autoSubmit() {
    this.#cancelSubmit()
    this.#showLoader()
    this.debouncedSubmit()
  }

  #showLoader() {
    this.#clearResults()
    this.loaderTarget.classList.remove('u-hide')
  }

  #clearResults() {
    this.resultsTarget.innerHTML = ''
  }

  #cancelSubmit() {
    this.#formSubmission?.stop()
  }

  // ==== Channels

}
