import $fq              from '../../support/fake_query'
import WizardController from '../wizard_controller'

export default class extends WizardController {

  static targets = [
    'additionalBreastFeedingSection',
    'additionalHivSection'
  ]

  static values = {}

  static classes = []

  initialize() {}

  connect() {}

  // ==== Controllers

  // ==== Actions

  toggleAdditionalHivSection(event) {
    const element = event.currentTarget

    if (element.value === 'yes') {
      $fq(this.additionalHivSectionTarget).show()
    } else {
      const inputs = this.additionalHivSectionTarget.querySelectorAll("input[type='text']")

      inputs.forEach((input) => {
        input.value = ''
      })

      $fq(this.additionalHivSectionTarget).hide()
    }
  }

  toggleAdditionalBreastFeedingSection(event) {
    const element = event.currentTarget

    if (element.value === 'no') {
      $fq(this.additionalBreastFeedingSectionTarget).show()
    } else {
      this.additionalBreastFeedingSectionTarget.querySelector('textarea').value = ''
      $fq(this.additionalBreastFeedingSectionTarget).hide()
    }
  }

  // ==== Getters

  // ==== Setters

  // ==== Private

  // ==== Channels

}
