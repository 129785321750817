import ApplicationController             from '../../support/application_controller'
import { disableElement, enableElement } from '../../support/helpers'

export default class extends ApplicationController {

  static targets = [
    'navigateAwayModal',
    'videomedCall',
    'myQueueButton',
    'myQueuePopover',
    'disableDuringCall'
  ]

  videomedCallTargetConnected() {
    this.disableElements()
  }

  videomedCallTargetDisconnected() {
    this.enableElements()
  }

  // ==== Controllers

  get navigateAwayModalController() {
    return this.getControllerForElement(this.navigateAwayModalTarget, 'modal-component--modal')
  }

  // ==== Actions

  navigateAway(event) {
    if (this.hasVideomedCallTarget) {
      event.preventDefault()
      this.navigateAwayModalController.open()
    }
  }

  changeMyQueueButtonStyle({ newState }) {
    if (newState === 'closed') {
      this.myQueueButtonTarget.setAttribute('aria-selected', 'false')
    }
  }

  clickMyQueueButton(event) {
    if (this.closingPopover) {
      event.preventDefault()
      event.stopPropagation()
    }
  }

  // ==== Getters

  get closingPopover() {
    if (!this.hasMyQueuePopoverTarget) return false

    return this.myQueuePopoverTarget.dataset.togglingTo === 'closed'
  }

  // ==== Setters

  // ==== Private

  disableElements() {
    this.disableDuringCallTargets.forEach((el) => disableElement(el))
  }

  enableElements() {
    this.disableDuringCallTargets.forEach((el) => enableElement(el))
  }

  // ==== Channels

}
