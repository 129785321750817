import ApplicationController from '../support/application_controller'

export default class extends ApplicationController {

  static targets = [
    'loader'
  ]

  initialize() {
    if (this.hasLoaderTarget) {
      this.data.set('originalHtml', this.loaderTarget.innerHTML)
    }
  }

  // ==== Controllers

  // ==== Actions

  hideSpinner() {
    if (this.hasLoaderTarget) {
      this.loaderTarget.innerHTML = this.originalHtml
      this.loaderTarget.disabled = false
    }
  }

  showSpinner() {
    if (this.hasLoaderTarget) {
      this.loaderTarget.innerHTML = this.spinnerHtml
      this.loaderTarget.disabled = true
    }
  }

  // ==== Getters

  get originalHtml() {
    return this.data.get('originalHtml')
  }

  get spinnerHtml() {
    return this.data.get('spinner')
  }

  // ==== Setters

  // ==== Private

  // ==== Channels

}
