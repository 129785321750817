import ApplicationController from '../support/application_controller'

export default class extends ApplicationController {

  static targets = [
    'button',
    'message'
  ]

  initialize() { }

  // ==== Controllers

  // ==== Actions

  hideMessage() {
    this.messageTarget.classList.add('u-hide')
    this.buttonTarget.classList.remove('-alert')
  }

  // ==== Getters

  // ==== Setters

  // ==== Private

  // ==== Channels

}
