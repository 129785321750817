// https://media.twiliocdn.com/sdk/js/video/releases/2.0.0-beta14/docs/

import camelCase              from 'lodash/camelCase'
import Video                  from 'twilio-video'
import { patch }              from '@rails/request.js'
import ApplicationController  from '../support/application_controller'
import I18n                   from '../support/i18n'
import { setGlobalMute }      from '../support/global_mute'

let activeRoom = {}
let audioCtx
let testTracks
let previewTracks = []

const i18nScope    = 'patients.consultations.twilio_videomed_player.messages'
const audioOptions = {
  echoCancellation: true
}
const videoOptions = {
  aspectRatio: 4 / 3,
  facingMode:  'user',
  width:       640,
  frameRate:   24
}

export default class extends ApplicationController {

  static targets = [
    'localVideoTrack',
    'messageAuthFailureTemplate',
    'messageTemplate',
    'msgAuthExpired',
    'msgConnecting',
    'msgEnablingVideo',
    'msgParticipantDisconnected',
    'msgParticipantReconnecting',
    'msgParticipantReconnected',
    'msgSecondScreenActive',
    'msgVideoDisabled',
    'msgVoiceOnly',
    'msgWaitingForParticipant',
    'networkQuality',
    'networkQualitySignalBar',
    'participant',
    'participantAudioStatus',
    'participantIdentity',
    'participants',
    'participantTracks',
    'preview',
    'remoteVideoTrack',
    'secondScreenActiveTemplate',
    'secondScreenStatus',
    'selectAudioInput',
    'selectAudioOutput',
    'selectVideoInput',
    'settings',
    'statusMessage',
    'statusMicrophone',
    'statusSpeaker',
    'statusVideo',
    'testSound',
    'videoTest'
  ]

  static outlets = [
    'videomed-call',
    'consultation',
    'second-screen'
  ]

  initialize() {
    const observer = new MutationObserver((mutations) => { this.handleVideomedCallStateChange(mutations) })
    observer.observe(this.element, {
      attributes:        true,
      attributeOldValue: true,
      attributeFilter:   ['data-twilio-video-videomed-call-status'],
      childList:         false
    })

    window.mainScreenController = this

    if (this.videomedCallStatus === 'answered') {
      if (this.videomedCallGreetingComplete === 'true') {
        this.answerCall()
      } else {
        this.disableElements()
      }
    }

    if (this.videomedCallStatus === 'ringing') {
      this.disableElements()
    }
  }

  disconnect() {
    this.disconnectFromRoom()
  }

  async handleVideomedCallStateChange(mutations) {
    const newState = mutations[0].target.dataset.twilioVideoVideomedCallStatus
    // const oldState = mutations[0].oldValue

    if (newState === 'answered') {
      this.answerCall()
    } else {
      this.disconnectFromRoom()
      this.enableElements(newState)
    }
  }

  // ==== Actions

  answerCall() {
    if (this.videomedCallGreetingComplete === 'false') return

    this.enablePlayer()
    this.handleCallType()
    this.connectToRoom()
    this.disableElements()
    this.autoOpenSecondScreen()
  }

  disconnectFromRoom() {
    if (activeRoom && activeRoom.sid) {
      activeRoom.disconnect()
    }

    if (this.hasSecondScreenOutlet && this.videomedCallStatus === 'answered' && this.openScreensaverOnDisconnect) {
      this.secondScreenOutlet.openScreensaver()
    }

    if (this.hasLocalVideoTrackTarget) {
      this.localVideoTrackTarget.remove()
    }

    if (this.hasParticipantTracksTarget) {
      this.participantTracksTarget.remove()
    }

    this.disablePlayer()
  }

  // ==== Actions: Second screen

  autoOpenSecondScreen() {
    if (this.videomedSecondScreen === 'enabled') {
      setTimeout(() => { this.openSecondScreen() }, 1000)
    }
  }

  openSecondScreen(event) {
    if (event) event.currentTarget.blur()

    this.showSecondScreenActiveTemplate()

    if (this.hasSecondScreenOutlet) {
      this.secondScreenOutlet.openVideoPlayer(this.roomId)
    }
  }

  restoreVideo() {
    this.removeMessageTemplate('second_screen_active')

    if (this.hasSecondScreenOutlet && this.openGreetingOnRestore) {
      this.secondScreenOutlet.openGreeting(this.roomId)
    }
  }

  // ==== Settings

  checkSecondScreenStatus() {
    if (!this.hasSecondScreenStatusTarget) return

    if (this.videomedSecondScreen === 'enabled') {
      this.secondScreenStatusTarget.checked = true
    } else {
      this.secondScreenStatusTarget.checked = false
    }
  }

  enumerateDevices() {
    navigator.mediaDevices.enumerateDevices()
      .then((mediaDevices) => { this.handleEnumeratedDevices(mediaDevices) })
      .catch((error) => this.captureError(error))
  }

  handleEnumeratedDevices(mediaDevices) {
    this.selectAudioInputTarget.querySelectorAll('.tmpOption').forEach((el) => { el.remove() })
    this.selectAudioOutputTarget.querySelectorAll('.tmpOption').forEach((el) => { el.remove() })
    this.selectVideoInputTarget.querySelectorAll('.tmpOption').forEach((el) => { el.remove() })

    mediaDevices.forEach((mediaDevice) => {
      const option = document.createElement('option')
      option.value = mediaDevice.deviceId
      option.classList.add('tmpOption')

      if (mediaDevice.deviceId === 'default') return

      switch (mediaDevice.kind) {
        case 'audioinput':
          if (this.videomedAudioInput === mediaDevice.deviceId) option.selected = true
          option.text = mediaDevice.label || `Microphone ${this.selectAudioInputTarget.length - 1}`
          this.selectAudioInputTarget.appendChild(option)
          break

        case 'audiooutput':
          if (this.videomedAudioOutput === mediaDevice.deviceId) option.selected = true
          option.text = mediaDevice.label || `Speaker ${this.selectAudioOutputTarget.length - 1}`

          this.selectAudioOutputTarget.appendChild(option)
          break

        case 'videoinput':
          if (this.videomedVideoInput === mediaDevice.deviceId) option.selected = true
          option.text = mediaDevice.label || `Camera ${this.selectVideoInputTarget.length - 1}`
          this.selectVideoInputTarget.appendChild(option)
          break

        default:
          break
      }
    })
  }

  playTestSound(event) {
    event.currentTarget.blur()
    this.testSoundTarget.play()
  }

  refreshDevices(event) {
    event.currentTarget.blur()
    this.enumerateDevices()
  }

  testMicrophone() {
    // Temporary solution. Replace with Web Audio API in the future

    const audio = this.videoTestTarget.querySelector('audio')

    if (!audio) return

    const audioContext = new AudioContext()
    const analyser     = audioContext.createAnalyser()
    const processor    = audioContext.createScriptProcessor(2048, 1, 1)
    const source       = audioContext.createMediaStreamSource(audio.srcObject)

    audioCtx = audioContext

    analyser.smoothingTimeConstant = 0.8
    analyser.fftSize               = 1024

    source.connect(analyser)
    analyser.connect(processor)
    processor.connect(audioContext.destination)

    processor.onaudioprocess = () => {
      const array  = new Uint8Array(analyser.frequencyBinCount)
      let values   = 0

      analyser.getByteFrequencyData(array)

      array.forEach((item) => { values += (item) })

      const volume      = values / array.length
      const volumePips  = Array.from(document.querySelectorAll('.a-volumePip'))
      const volumeRange = volumePips.slice(0, Math.round(volume / 10))

      volumePips.forEach((pip) => pip.classList.remove('-active'))
      volumeRange.forEach((pip) => pip.classList.add('-active'))
    }
  }

  toggleSettings(event) {
    event.currentTarget.blur()
    this.settingsTarget.classList.toggle('u-hide')

    if (!this.settingsTarget.classList.contains('u-hide')) {
      this.settingStatus = 'open'
      this.enumerateDevices()
      this.attachTestTracks()
      this.checkSecondScreenStatus()
    } else {
      this.settingStatus = 'closed'
      this.detachTestTracks()
    }
  }

  toggleSecondScreen(event) {
    if (event.currentTarget.checked) {
      this.videomedSecondScreen = 'enabled'
    } else {
      this.videomedSecondScreen = 'disabled'
    }
  }

  // ==== Switch tracks

  switchAudioInput(event) {
    const value = event.currentTarget.value
    if (value === '') return

    this.videomedAudioInput = value
    this.detachTestTracks()
    this.attachTestTracks()

    if (!activeRoom) return
    const participant = activeRoom.localParticipant

    // Stop, unpublish and detach track
    participant.audioTracks.forEach((publication) => {
      const track = publication.track

      publication.unpublish()
      this.detachTracks([track])
    })

    // Publish and attach new track
    audioOptions.deviceId = value
    audioOptions.name     = `${participant.identity} (audio)`
    Video.createLocalAudioTrack(audioOptions)
      .then((audioTrack) => {
        participant.publishTrack(audioTrack)
        this.attachPreviewTracks([audioTrack])
      })
      .catch((error) => this.captureError(error))
  }

  switchAudioOutput(event) {
    const value = event.currentTarget.value
    if (value === '') return

    this.videomedAudioOutput = value
    this.testSoundTarget.setSinkId(value)

    if (activeRoom) {
      activeRoom.participants.forEach((participant) => {
        const tracks       = document.getElementById(participant.sid)
        const audioElement = tracks.querySelector('audio')

        if (audioElement) {
          audioElement.setSinkId(value)
        }
      })
    }
  }

  switchVideoInput(event) {
    const value = event.currentTarget.value
    if (value === '') return

    this.videomedVideoInput = value
    this.detachTestTracks()
    this.attachTestTracks()

    if (!activeRoom) return
    const localParticipant = activeRoom.localParticipant

    // Stop, unpublish and detach track
    localParticipant.videoTracks.forEach((publication) => {
      const track = publication.track

      publication.unpublish()
      this.detachTracks([track])
    })

    // Publish and attach new track
    videoOptions.deviceId = value
    this.attachVideoTrack()
  }

  // ==== Toggle tracks

  enableTrackToggle(key, checked = true) {
    const targetStr = `status_${key}_target`

    if (this[camelCase(`has_${targetStr}`)]) {
      const statusTarget = this[camelCase(targetStr)]
      statusTarget.removeAttribute('disabled')
      statusTarget.checked = checked
    }
  }

  toggleMicrophone() {
    activeRoom.localParticipant.audioTracks.forEach((publication) => {
      if (publication.track.isEnabled) {
        publication.track.disable()
        this.detachTracks([publication.track])
        this.trackEvent('toggle_microphone', { state: 'off' })
      } else {
        publication.track.enable()
        this.attachPreviewTracks([publication.track])
        this.trackEvent('toggle_microphone', { state: 'on' })
      }
    })
  }

  toggleSpeaker() {
    if (!activeRoom) return

    activeRoom.participants.forEach((participant) => {
      const tracks       = document.getElementById(participant.sid)
      const audioElement = tracks.querySelector('audio')
      const videoElement = tracks.querySelector('video')

      if (audioElement.muted) {
        audioElement.muted = false
        this.trackEvent('toggle_speaker', { state: 'off' })
      } else {
        audioElement.muted = true
        this.trackEvent('toggle_speaker', { state: 'on' })
      }

      if (videoElement.muted) {
        videoElement.muted = false
      } else {
        videoElement.muted = true
      }
    })
  }

  toggleVideo() {
    if (this.statusVideoTarget.checked) {
      if (activeRoom.localParticipant.videoTracks.size === 0) {
        this.attachVideoTrack()
      } else {
        activeRoom.localParticipant.videoTracks.forEach((publication) => {
          if (!publication.track.isEnabled) {
            publication.track.enable()
            this.attachPreviewTracks([publication.track])
            this.trackEvent('toggle_video', { state: 'on' })
          }
        })
      }
    } else {
      activeRoom.localParticipant.videoTracks.forEach((publication) => {
        if (publication.track.isEnabled) {
          publication.track.disable()
          this.detachTracks([publication.track])
          this.trackEvent('toggle_video', { state: 'off' })
        }
      })
    }
  }

  attachVideoTrack() {
    this.switchToVideoCall()
    videoOptions.name     = `${activeRoom.localParticipant.identity} (video)`
    Video.createLocalVideoTrack(videoOptions)
      .then((videoTrack) => {
        activeRoom.localParticipant.publishTrack(videoTrack)
        this.attachPreviewTracks([videoTrack])
      })
      .catch((error) => this.captureError(error))
  }

  // ==== Getters

  get consultationId() {
    return this.data.get('consultationId')
  }

  get isVideoCall() {
    return this.videomedCallType === 'video_call'
  }

  get isVoiceCall() {
    return this.videomedCallType === 'voice_call'
  }

  get openGreetingOnRestore() {
    return this.data.get('openGreetingOnRestore') === 'true'
  }

  get openScreensaverOnDisconnect() {
    return this.data.get('openScreensaverOnDisconnect') === 'true'
  }

  get participantId() {
    return this.data.get('participantId')
  }

  get participantIdentity() {
    return this.data.get('participantIdentity')
  }

  get participantType() {
    return this.data.get('participantType')
  }

  get patientId() {
    return this.data.get('patientId')
  }

  get patientIdentity() {
    return this.data.get('patientIdentity')
  }

  get roomId() {
    return this.data.get('roomId')
  }

  get settingStatus() {
    return this.data.get('settings') || 'closed'
  }

  get token() {
    return this.data.get('token')
  }

  get twilioRoomSid() {
    return activeRoom.sid
  }

  get videomedCallStatus() {
    return this.data.get('videomedCallStatus')
  }

  get videomedCallType() {
    return this.data.get('videomedCallType') || 'video_call'
  }

  get videomedCallGreetingComplete() {
    return this.data.get('videomedCallGreetingComplete')
  }

  get skipEvents() {
    return this.data.get('skipEvents') === 'true'
  }

  // ==== Getters: localStorage

  get videomedAudioInput() {
    return localStorage.getItem('videomedAudioInput')
  }

  get videomedAudioOutput() {
    return localStorage.getItem('videomedAudioOutput')
  }

  get videomedSecondScreen() {
    return localStorage.getItem('secondScreen')
  }

  get videomedVideoInput() {
    return localStorage.getItem('videomedVideoInput') || undefined
  }

  // ==== Setters

  set settingStatus(value) {
    this.data.set('settings', value)
  }

  set token(value) {
    this.data.set('token', value)
  }

  set videomedCallType(value) {
    this.data.set('videomedCallType', value)
  }

  set videomedCallStatus(value) {
    this.data.set('videomedCallStatus', value)
  }

  set videomedCallGreetingComplete(value) {
    this.data.set('videomedCallGreetingComplete', value)
  }

  set videoTrackStarted(value) {
    this.data.set('videoTrackStarted', value)
  }

  // ==== Setters: localStorage

  set videomedAudioInput(value) {
    localStorage.setItem('videomedAudioInput', value)
  }

  set videomedAudioOutput(value) {
    localStorage.setItem('videomedAudioOutput', value)
  }

  set videomedSecondScreen(value) {
    localStorage.setItem('secondScreen', value)
  }

  set videomedVideoInput(value) {
    localStorage.setItem('videomedVideoInput', value)
  }

  // ==== Message templates

  showMessageTemplate(message, {
    cssClass,
    i18n = {},
    loader = true
  } = {}) {
    const node          = document.importNode(this.messageTemplateTarget.content, true)
    const element       = node.firstChild
    const i18nMessage   = I18n.t(`${i18nScope}.${message}`, i18n)
    const loaderElement = element.querySelector('.loader')

    if (!loader) {
      loaderElement.remove()
    } else if (this.isVoiceCall) {
      loaderElement.classList.remove('is-large')
      loaderElement.classList.add('is-medium')
    }

    if (cssClass) {
      element.classList.add(cssClass)
    }

    element.dataset.twilioVideoTarget = camelCase(`msg_${message}`)
    element.querySelector('p').innerHTML = i18nMessage

    this.statusMessageTarget.appendChild(element)
    this.trackEvent('status_message', { key: message, message: i18nMessage })
  }

  showMessageAuthFailureTemplate(doc, obj) {
    const node = doc.importNode(obj.messageAuthFailureTemplateTarget.content, true)
    obj.participantTarget.appendChild(node)
    this.trackEvent('twilio_token_expired')
  }

  showSecondScreenActiveTemplate() {
    if (!this.hasMsgSecondScreenActiveTarget) {
      const node    = document.importNode(this.secondScreenActiveTemplateTarget.content, true)
      const element = node.firstChild

      element.dataset.twilioVideoTarget = camelCase('msg_second_screen_active')
      this.element.classList.add('-secondScreenActive')

      this.participantTarget.appendChild(element)
    }
  }

  removeMessageTemplate(key) {
    const targetStr = `msg_${key}_target`

    if (this[camelCase(`has_${targetStr}`)]) {
      this[camelCase(targetStr)].remove()
    }
  }

  // ==== Player UI

  disablePlayer() {
    setGlobalMute(false)
    this.element.classList.remove('-active')
    this.element.classList.add('-disabled')
    this.removeMessageTemplate('participant_disconnected')
  }

  enablePlayer() {
    if (this.hasVideomedCallOutlet && this.videomedCallOutlet.videomedCallStatus !== 'answered') {
      return
    }

    setGlobalMute(true)
    this.element.classList.add('-active')
    this.element.classList.remove('-disabled')
  }

  handleCallType() {
    if (this.isVideoCall) {
      this.showMessageTemplate('enabling_video')
    } else {
      this.element.classList.add('-voice-call')
    }
  }

  // ===========================================================================
  // twilio-video.js ===========================================================
  // ===========================================================================

  // ==== Test tracks

  attachTestTracks() {
    if (this.settingStatus !== 'open') return

    const localTrackOptions = { audio: { name: 'testAudio' } }
    if (this.videomedAudioInput) localTrackOptions.audio.deviceId = this.videomedAudioInput
    if (this.isVideoCall) {
      localTrackOptions.video = { name: 'testVideo', width: { exact: 160 } }
      if (this.videomedVideoInput) localTrackOptions.video.deviceId = this.videomedVideoInput
    }

    Video.createLocalTracks(localTrackOptions)
      .then((localTracks) => {
        testTracks = localTracks

        localTracks.forEach((track) => {
          this.videoTestTarget.appendChild(track.attach())

          if (track.kind === 'audio') {
            this.testMicrophone()
          }
        })
      })
      .catch((error) => this.captureError(error))
  }

  detachTestTracks() {
    testTracks.forEach((track) => {
      track.stop()
      track.detach().forEach((element) => element.remove())
    })
    if (audioCtx) audioCtx.close()
  }

  // ==== Twilio track helpers

  attachPreviewTracks(tracks) {
    tracks.forEach((track) => {
      if (track.kind === 'video') {
        const videoElement = track.attach()
        videoElement.dataset.twilioVideoTarget = 'localVideoTrack'
        this.previewTarget.appendChild(videoElement)
      }
    })
  }

  detachTracks(tracks) {
    tracks.forEach((track) => {
      track.detach().forEach((element) => element.remove())
    })
  }

  removeRemoteTrackWrapper(id) {
    const wrapper = document.getElementById(id)

    if (wrapper) {
      wrapper.remove()
    }
  }

  disableElements() {
    this.consultationOutlet?.disableElements()
  }

  enableElements(state) {
    if (['on_hold', 'closed', 'rejected'].includes(state)) {
      this.consultationOutlet?.enableElements()
    }
  }

  // ==== Twilio connect

  async connectToRoom() {
    videoOptions.name = `${this.participantIdentity} (video)`
    audioOptions.name = `${this.participantIdentity} (audio)`

    if (this.videomedVideoInput) videoOptions.deviceId = this.videomedVideoInput
    if (this.videomedAudioInput) audioOptions.deviceId = this.videomedAudioInput

    const room = await Video.connect(this.token, {
      name:                 this.roomId,
      audio:                audioOptions,
      video:                this.isVideoCall ? videoOptions : false,
      dominantSpeaker:      false,
      bandwidthProfile: {
        video: {
          mode:                        'grid',
          clientTrackSwitchOffControl: 'auto',
          contentPreferencesMode:      'auto'
        }
      },
      maxAudioBitrate: 16000,
      // For multiparty rooms (participants>=3) uncomment the line below
      // preferredVideoCodecs: [{ codec: 'VP8', simulcast: true }],
      networkQuality:       {
        local:  1,
        remote: 1
      }
    }).catch((error) => {
      switch (error.code) {
        case 20104:
          this.removeMessageTemplate('connecting')
          this.showMessageAuthFailureTemplate(document, this)
          break
        default:
          break
      }
      this.captureError(error)
    })

    if (!room) return

    activeRoom = room

    // Listen to the "beforeunload" event on window to leave the Room when the tab/browser is being closed.
    window.addEventListener('beforeunload', () => room.disconnect())

    // iOS Safari does not emit the "beforeunload" event on window.
    window.addEventListener('pagehide', () => room.disconnect())

    // ==== Local participant

    this.localParticipantConnected()

    // ==== Participants

    // Handle participants already connected to the Room
    room.participants.forEach((participant) => {
      this.participantConnected(participant)
    })

    // Handle NEW participants as they connect to the Room
    room.on('participantConnected', (participant) => {
      this.participantConnected(participant)
    })

    // Handle participants as they disconnect from the Room
    room.on('participantDisconnected', (participant) => {
      this.participantDisconnected(participant)
    })

    // Handle participants reconnecting to the Room
    room.on('participantReconnecting', (participant) => {
      this.participantReconnecting(participant)
    })

    // Handle participants reconnected to the Room
    room.on('participantReconnected', (participant) => {
      this.participantReconnected(participant)
    })

    // ==== Remote track events

    room.on('trackEnabled', (track) => {
      if (track.kind === 'audio') {
        this.participantIdentityTarget.classList.remove('-microphoneOff')
        this.participantAudioStatusTarget.classList.add('u-hide')
      } else if (track.kind === 'video') {
        this.switchToVideoCall()
        this.removeMessageTemplate('video_disabled')
      }
    })

    room.on('trackDisabled', (track) => {
      if (track.kind === 'audio') {
        this.participantIdentityTarget.classList.add('-microphoneOff')
        this.participantAudioStatusTarget.classList.remove('u-hide')
      } else if (track.kind === 'video') {
        this.showMessageTemplate('video_disabled', { i18n: { name: this.participantIdentityTarget.textContent }, loader: false })
      }
    })

    room.on('trackStarted', (track) => {
      if (track.kind === 'video' && track.isEnabled) {
        this.switchToVideoCall()
        this.removeMessageTemplate('video_disabled')
      }
    })

    // ==== Connection states

    room.on('disconnected', (error) => {
      if (previewTracks) {
        previewTracks.forEach((track) => {
          this.detachTracks([track])
        })
      }

      // Detach local participant tracks
      room.localParticipant.tracks.forEach((publication) => {
        const attachedElements = publication.track.detach()
        attachedElements.forEach((element) => element.remove())
      })

      room.participants.forEach((participant) => {
        this.removeRemoteTrackWrapper(participant.sid)
      })

      activeRoom    = null
      previewTracks = []

      if (this.hasNetworkQualityTarget) {
        this.networkQualityTarget.classList.add('-inactive')
      }

      if (!error) return
      if (error.code === 20104) {
        this.logError('Signaling reconnection failed due to expired AccessToken!')
      } else if (error.code === 53000) {
        this.logError('Signaling reconnection attempts exhausted!')
      } else if (error.code === 53204) {
        this.logError('Signaling reconnection took too long!')
      }
    })
  }

  // ==== Twilio events

  localParticipantConnected() {
    if (!activeRoom) return

    const localParticipant = activeRoom.localParticipant

    this.trackEvent('connected_to_room', { sid: localParticipant.sid, identity: localParticipant.identity })
    this.displayNetworkQuality(localParticipant)

    localParticipant.tracks.forEach((publication) => {
      this.trackEvent('track_published', { sid: localParticipant.sid, identity: localParticipant.identity, kind: publication.kind })

      previewTracks.push(publication.track)

      if (this.isVideoCall && publication.kind === 'video') {
        this.attachPreviewTracks([publication.track])

        if (publication.track.isStarted) {
          this.removeMessageTemplate('enabling_video')
          this.showMessageTemplate('connecting', { i18n: { name: this.participantType } })
          this.previewTarget.classList.remove('u-hide')
          this.enableTrackToggle('video')
        }
      } else if (publication.kind === 'audio') {
        if (publication.track.isStarted) {
          this.enableTrackToggle('microphone')
          this.enableTrackToggle('speaker')
        }
        if (this.isVoiceCall) {
          this.showMessageTemplate('connecting', { i18n: { name: this.participantType } })
          this.enableTrackToggle('video', false)
        }
      }
    })

    localParticipant.on('reconnecting', () => {
      this.participantReconnecting(localParticipant)
    })

    localParticipant.on('reconnected', () => {
      this.participantReconnected(localParticipant)
    })
  }

  participantConnected(participant) {
    this.trackEvent('connected_to_room', { sid: participant.sid, identity: participant.identity })

    // Remove existing tracks wrapper
    this.removeRemoteTrackWrapper(participant.sid)

    // Create participant tracks wrapper
    const html                     = document.createElement('div')
    html.id                        = participant.sid
    html.dataset.twilioVideoTarget = 'participantTracks'

    // Display participant identity
    this.participantIdentityTarget.innerHTML = this.nameFromIdentity(participant.identity)
    this.participantIdentityTarget.classList.remove('u-hide')

    participant.tracks.forEach((publication) => {
      if (publication.isSubscribed) {
        this.trackSubscribed(html, publication.track)
      }
    })

    participant.on('trackSubscribed', (track) => {
      this.trackSubscribed(html, track)
      this.trackEvent('track_subscribed', { sid: participant.sid, identity: participant.identity, kind: track.kind })
    })

    participant.on('trackUnsubscribed', (track) => {
      this.detachTracks([track])
      this.trackEvent('track_unsubscribed', { sid: participant.sid, identity: participant.identity, kind: track.kind })
    })

    // Attach tracks wrapper
    this.participantTarget.appendChild(html)
  }

  participantDisconnected(participant) {
    this.trackEvent('disconnected_from_room', { sid: participant.sid, identity: participant.identity })
    this.showMessageTemplate('participant_disconnected', { i18n: { name: this.nameFromIdentity(participant.identity) } })
    this.removeRemoteTrackWrapper(participant.sid)
  }

  participantReconnecting(participant) {
    this.logInfo(`${participant.identity} is reconnecting to the Room`)
    this.trackEvent('reconnecting_to_room', { sid: participant.sid, identity: participant.identity })
    this.showMessageTemplate('participant_reconnecting', { i18n: { name: this.nameFromIdentity(participant.identity) } })

    this.removeMessageTemplate('participant_disconnected')
  }

  participantReconnected(participant) {
    this.logInfo(`${participant.identity} has reconnected to the Room`)
    this.trackEvent('reconnected_to_room', { sid: participant.sid, identity: participant.identity })

    this.removeMessageTemplate('participant_reconnecting')
    this.removeMessageTemplate('participant_reconnected')
    this.removeMessageTemplate('participant_disconnected')
  }

  trackSubscribed(html, track) {
    if (track.kind === 'audio') {
      if (this.videomedAudioOutput) {
        const audioElement = track.attach()
        audioElement.setSinkId(this.videomedAudioOutput).then(() => {
          this.previewTarget.appendChild(audioElement)
          html.appendChild(audioElement)
        }).catch((error) => this.captureError(error))
      } else {
        html.appendChild(track.attach())
      }

      if (this.isVoiceCall) {
        this.removeMessageTemplate('connecting')
        this.removeMessageTemplate('participant_disconnected')
        this.showMessageTemplate('voice_only', { loader: false })
      }
    } else if (track.kind === 'video') {
      const videoElement = track.attach()
      videoElement.dataset.twilioVideoTarget = 'remoteVideoTrack'
      html.appendChild(videoElement)

      track.on('started', () => {
        this.removeMessageTemplate('enabling_video')
        this.removeMessageTemplate('connecting')
        this.removeMessageTemplate('participant_disconnected')
      })
    }
  }

  // ==== Twilio network quality

  printNetworkQualityStats(networkQualityLevel, networkQualityStats) {
    this.trackEvent('network_quality_level', { level: networkQualityLevel })

    if (this.hasNetworkQualityTarget && networkQualityLevel) {
      this.networkQualityTarget.classList.remove('-inactive')
    }

    if (this.hasNetworkQualitySignalBarTarget) {
      this.networkQualitySignalBarTarget.classList.remove('-level5', '-level4', '-level3', '-level2', '-level1', '-level0')
      this.networkQualitySignalBarTarget.classList.add(`-level${networkQualityLevel}`)
    }

    if (networkQualityStats) {
      this.logInfo('Network Quality statistics:', networkQualityStats)
    }

    if (networkQualityLevel === 0) {
      this.captureError(new Error('Network level 0'))
    }
  }

  displayNetworkQuality(participant) {
    this.printNetworkQualityStats(participant.networkQualityLevel, participant.networkQualityStats)

    participant.on('networkQualityLevelChanged', (networkQualityLevel, networkQualityStats) => {
      this.printNetworkQualityStats(networkQualityLevel, networkQualityStats)
    })
  }

  // ==== Logging

  trackEvent(eventType, data = {}) {
    if (!activeRoom) return
    if (this.skipEvents) return

    const body = {
      event_type:       eventType,
      topic:            'twilio',
      twilio_room_sid:  this.twilioRoomSid,
      videomed_call_id: this.roomId,
      data
    }
    const payload = {
      headers: {
        'content-type': 'application/json; charset=UTF-8'
      },
      body:   JSON.stringify(body),
      method: 'POST'
    }

    fetch('/api/v1/events/twilio', payload)
      .catch((error) => { this.logError(error) })
  }

  // ==== Debug

  logSuccess(message) {
    HF.consoleLog(message, {
      level: 'success'
    })
  }

  logWarning(message) {
    HF.consoleLog(message, {
      display: 'warning',
      level:   'warning'
    })
  }

  logInfo(message) {
    HF.consoleLog(message, {
      display: 'info',
      level:   'info'
    })
  }

  logError(message, skip = false) {
    if (skip) return

    HF.consoleLog(message, {
      display: 'error',
      level:   'error'
    })
  }

  // ==== Channels

  // ==== Private

  nameFromIdentity(identity) {
    if (identity === this.patientId) return this.patientIdentity
    if (identity === this.participantId) return this.participantIdentity

    return identity
  }

  async switchToVideoCall() {
    if (!this.isVoiceCall) return

    this.videomedCallType = 'video_call'
    this.removeMessageTemplate('voice_only')
    this.element.classList.remove('-voice-call')
    this.previewTarget.classList.remove('u-hide')

    activeRoom.participants.forEach((participant) => {
      if (participant.videoTracks.size === 0) {
        this.showMessageTemplate('video_disabled', { i18n: { name: this.nameFromIdentity(participant.identity) }, loader: false })
      }
    })

    if (this.consultationId) {
      await patch(`/kena/consultations/${this.consultationId}/videomed-calls/${this.roomId}`, {
        body:         { call_type: 'video_call' },
        responseKind: 'json'
      })
    }
  }

}
