import SimpleAssessmentController from './simple_assessment_controller'

export default class extends SimpleAssessmentController {

  connect() {}

  initialize() {}

  disconnect() {}

  // ==== Controllers

  // ==== Actions

  // ==== Getters

  // ==== Setters

  // ==== Private

  // ==== Channels

}
