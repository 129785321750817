export default class Axis {

  constructor(content, bar, axis) {
    this.content = content
    this.bar = bar
    this.axis = axis

    this.offset = 0
    this.lastContentStart = this.contentStart

    this.drag = this.drag.bind(this)
    this.endDragging = this.endDragging.bind(this)
    this.startDragging = this.startDragging.bind(this)

    this.bar.addEventListener('mousedown', this.startDragging, true)
  }

  disconnect() {
    this.bar.removeEventListener('mousedown', this.startDragging, true)
  }

  get scrollSize() {
    if (this.axis === 'x') return this.content.scrollWidth
    return this.content.scrollHeight
  }

  get clientSize() {
    if (this.axis === 'x') return this.content.clientWidth
    return this.content.clientHeight
  }

  get length() {
    if (this.axis === 'x') return this.bar.clientWidth
    return this.bar.clientHeight
  }

  get contentStart() {
    return this.content.getBoundingClientRect()[this.axis === 'x' ? 'left' : 'top']
  }

  get scrollStart() {
    return this.bar.getBoundingClientRect()[this.axis === 'x' ? 'left' : 'top']
  }

  drag(event) {
    event.preventDefault()
    event.stopPropagation()

    const pageOffset = this.axis === 'y' ? event.pageY : event.pageX

    const drag = pageOffset - this.lastContentStart - this.offset

    const dragPercent = drag / (this.clientSize - this.length)
    const scroll = dragPercent * (this.scrollSize - this.clientSize)

    if (this.axis === 'x') this.content.scrollLeft = scroll
    else this.content.scrollTop = scroll
  }

  endDragging() {
    window.removeEventListener('mousemove', this.drag, true)
    window.removeEventListener('mouseup', this.endDragging, true)
    document.body.classList.remove('timebar-dragging')
  }

  startDragging(event) {
    this.lastContentStart = this.contentStart
    const pageOffset = this.axis === 'y' ? event.pageY : event.pageX
    this.offset = pageOffset - this.scrollStart

    window.addEventListener('mouseup', this.endDragging, true)
    window.addEventListener('mousemove', this.drag, true)
    document.body.classList.add('timebar-dragging')
  }

}
