import ApplicationController from '../support/application_controller'
import I18n                  from '../support/i18n'
import $fq                   from '../support/fake_query'

export default class extends ApplicationController {

  static targets = [
    'passwordInput',
    'strengthIndicator',
    'strengthIndicatorMessage'
  ]

  static values = {
    minPasswordLength: Number
  }

  static classes = []

  initialize() {}

  // ==== Controllers

  // ==== Actions

  checkStrength(event) {
    const password = event.currentTarget.value

    if (!this.hasStrengthIndicatorTarget) return

    $fq(this.strengthIndicatorTarget).removeClass('-rag-status-r')
    $fq(this.strengthIndicatorTarget).removeClass('-rag-status-a')
    $fq(this.strengthIndicatorTarget).removeClass('-rag-status-g')
    $fq(this.strengthIndicatorMessageTarget).innerHTML('')

    if (password === '') return

    if (password.length >= this.minPasswordLengthValue) {
      const score    = this.score(password)
      const scoreKey = this.scoreRagStatus[score]

      $fq(this.strengthIndicatorTarget).addClass(this.ragStatuses[scoreKey])
      $fq(this.strengthIndicatorMessageTarget).innerHTML(this.i18nMessages[scoreKey])
    } else {
      $fq(this.strengthIndicatorTarget).addClass(this.ragStatuses.tooShort)
      $fq(this.strengthIndicatorMessageTarget).innerHTML(this.i18nMessages.tooShort)
    }
  }

  toggle(event) {
    const button    = event.currentTarget
    const input     = this.passwordInputTarget
    const inputType = input.getAttribute('type')

    if (inputType === 'password') {
      input.setAttribute('type', 'text')
      button.innerHTML = button.getAttribute('data-password-on')
    } else if (inputType === 'text') {
      input.setAttribute('type', 'password')
      button.innerHTML = button.getAttribute('data-password-off')
    }
  }

  // ==== Getters

  get i18nMessages() {
    const scope = 'devise.passwords.strenght_indicator'

    return {
      tooShort: I18n.t(`${scope}.too_short`, { length: this.minPasswordLengthValue }),
      veryWeak: I18n.t(`${scope}.very_weak`),
      weak:     I18n.t(`${scope}.weak`),
      strong:   I18n.t(`${scope}.strong`)
    }
  }

  get ragStatuses() {
    return {
      tooShort: '-rag-status-r',
      veryWeak: '-rag-status-r',
      weak:     '-rag-status-a',
      strong:   '-rag-status-g'
    }
  }

  get scoreRagStatus() {
    return {
      0: 'veryWeak',
      1: 'veryWeak',
      2: 'veryWeak',
      3: 'weak',
      4: 'strong'
    }
  }

  // ==== Setters

  // ==== Private

  score(password) {
    return Math.min(this.characterScore(password), window.zxcvbn(password).score)
  }

  characterScore(password) {
    let score = 0

    if (/[a-z]/.test(password)) {
      score += 1
    }

    if (/[A-Z]/.test(password)) {
      score += 1
    }

    if (/\d/.test(password)) {
      score += 1
    }

    if (/\W/.test(password)) {
      score += 1
    }

    return score
  }

  // ==== Channels

}
