import * as Sentry     from '@sentry/browser'
import { metaContent } from '../support/helpers'

function getUser() {
  return {
    email: metaContent('app:user:email'),
    id:    metaContent('app:user:id'),
    name:  metaContent('app:user:name'),
    role:  metaContent('app:user:role')
  }
}

function getDocumentState() {
  return {
    readyState:   document.readyState,
    turboPreview: document.documentElement.hasAttribute('data-turbo-preview')
  }
}

window.HF = {
  current: {
    env:     import.meta.env.HF_ENV,
    release: metaContent('app:release'),
    user:    getUser(),

    get documentState() {
      return getDocumentState()
    }
  },

  captureError(error, options = {}) {
    Sentry.captureException(error, {
      ...options,
      tags: {
        ...options.tags,
        url: window.location.href
      },
      extra: {
        ...options.extra,
        explicit: true
      }
    })
  },

  consoleLog(message, options = {}) {
    const display      = options.display || 'log'
    const groupDisplay = options.groupDisplay || 'log'
    const groupTitle   = options.groupTitle
    const level        = options.level
    const colors       = {
      error:   'color:red; font-weight:bold',
      info:    'color:cyan; font-weight:bold',
      success: 'color:limegreen; font-weight:bold',
      warning: 'color:yellow; font-weight:bold'
    }

    let messageArgs = []

    if (!window.debugMode) return

    switch (level) {
      case 'error':
      case 'info':
      case 'success':
      case 'warning':
        messageArgs = [`%c${message}`, colors[level]]
        break

      default:
        messageArgs = [message]
        break
    }

    /* eslint-disable no-console */
    switch (display) {
      case 'error':
        console.error(...messageArgs)
        break

      case 'group':
        console.group(`%c${groupTitle}`, colors[level])
        if (groupDisplay === 'table') {
          console.table(message)
        } else {
          console.log(message)
        }
        console.groupEnd()
        break

      case 'groupCollapsed':
        console.groupCollapsed(`%c${groupTitle}`, colors[level])
        if (groupDisplay === 'table') {
          console.table(message)
        } else {
          console.log(message)
        }
        console.groupEnd()
        break

      case 'table':
        console.table(message, colors[level])
        break

      case 'warn':
        console.warn(...messageArgs)
        break

      default:
        console.log(...messageArgs)
        break
    }
    /* eslint-enable no-console */
  }
}

window.debugMode = HF.current.env === 'development' || localStorage.getItem('debug') === 'true'
