import $fq              from '../../support/fake_query'
import I18n             from '../../support/i18n'
import WizardController from '../wizard_controller'

export default class extends WizardController {

  static targets = [
    'additionalInstructions',
    'noneInverse',
    'otherMedicationDetails',
    'otherMedicationDetailsInput',
    'reasonOther',
    'reasonOtherTextarea'
  ]

  static values = {}

  static classes = []

  initialize() {}

  connect() {}

  // ==== Controllers

  // ==== Actions

  toggleNoneInverse(event) {
    this.toggleNoneInverseSelections({
      input:   event.currentTarget,
      targets: this.noneInverseTargets
    })
  }

  toggleAdditionalInstructions(event) {
    const element           = event.currentTarget
    const glyphSvg          = element.querySelector('.c-glyph__svg')
    const buttonText        = element.querySelector('.c-button__text')
    const instructionTarget = this.additionalInstructionsTarget

    if ($fq(instructionTarget).hasClass('u-hide')) {
      $fq(instructionTarget).show()
      $fq(glyphSvg).changeGlyph('clear')
      $fq(buttonText).text(I18n.t('layouts.medication_selection.clear_instructions'))
    } else {
      $fq(instructionTarget).hide()
      $fq(glyphSvg).changeGlyph('add')
      $fq(buttonText).text(I18n.t('layouts.medication_selection.add_instructions'))
      instructionTarget.querySelector('textarea').value = ''
    }
  }

  addOtherMedicationDetailsItem() {
    this.addNestedForm({
      list:      this.otherMedicationDetailsInputTargets,
      inputType: "input[type='text'], select",
      wrapper:   this.otherMedicationDetailsTarget
    })
  }

  removeOtherMedicationDetailsItem(event) {
    this.removeNestedForm({
      button:    event.currentTarget,
      wrapper:   this.otherMedicationDetailsTarget,
      list:      this.otherMedicationDetailsInputTargets,
      inputType: "input[type='text']"
    })
  }

  // ==== Getters

  // ==== Setters

  // ==== Private

  // ==== Channels

}
