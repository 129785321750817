import { get }               from '@rails/request.js'
import camelCase             from 'lodash/camelCase'
import ApplicationController from '../../support/application_controller'
import $fq                   from '../../support/fake_query'
import BmiCalculator         from '../../support/bmi_calculator'
import EscalationMixin       from '../../support/escalation_mixin'
import I18n                  from '../../support/i18n'
import WizardMixin           from '../../support/wizard_mixin'
import { animationFrame }    from '../../support/helpers'

export default class extends WizardMixin(EscalationMixin(ApplicationController)) {

  static targets = [
    ...super.targets,
    'bmi',
    'breastFeedingReasonsNoneOfTheAbove',
    'contraceptiveManagementPatientStatus',
    'contraceptiveManagementType',
    'height',
    'medicalConditionsNoneInverse',
    'medicationNoneInverse',
    'noPregnancyTestReason',
    'otherSymptomsNoneOfTheAbove',
    'outOfRangeGlyphBreastFeedingNone',
    'outOfRangeGlyphCalvesOnPalpation',
    'outOfRangeGlyphCalvesSwelling',
    'outOfRangeGlyphCalvesTenderness',
    'outOfRangeGlyphFirstTimeContraception',
    'outOfRangeGlyphNoPregnancyTestReason',
    'outOfRangeGlyphPregnancyTestResult',
    'outOfRangeGlyphUpperLegsPelvicSwelling',
    'outOfRangeGlyphUpperLegsPelvicTenderness',
    'outOfRangeGlyphVaginalBleeding',
    'outOfRangePopoverBreastFeedingNone',
    'outOfRangePopoverCalvesOnPalpation',
    'outOfRangePopoverCalvesSwelling',
    'outOfRangePopoverCalvesTenderness',
    'outOfRangePopoverFirstTimeContraception',
    'outOfRangePopoverNoPregnancyTestReason',
    'outOfRangePopoverPregnancyTestResult',
    'outOfRangePopoverUpperLegsPelvicSwelling',
    'outOfRangePopoverUpperLegsPelvicTenderness',
    'outOfRangePopoverVaginalBleeding',
    'patientDiabetic',
    'pregnancyStatus',
    'pregnancyStatusNone',
    'pregnancyStatusNoneInverse',
    'pregnancyStatusPatientIsBreastFeeding',
    'pregnancyTestResult',
    'recentProcedure',
    'submit',
    'takingProgesterone',
    'urinarySymptomsNoneOfTheAbove',
    'urineTestStripNo',
    'urineTestStripYes',
    'weight'
  ]

  static classes = [
    'hide'
  ]

  static outlets = [
    'popover'
  ]

  initialize() {
    this.checkForEscalation = this.checkForEscalation.bind(this)
  }

  connect() {
    BmiCalculator(this)
    // HACK: popovers are cooked!
    window.addEventListener('resize', this.updatePopovers, { passive: true })
    this.scrollbars?.addEventListener('scroll', this.updatePopovers, { passive: true })

    this.observer = new MutationObserver(this.checkForEscalation)
    this.observer.observe(this.element, { childList: true, subtree: true })

    this.element.addEventListener('input', this.checkForEscalation)

    this.checkForEscalation()
  }

  disconnect() {
    // HACK: popovers are cooked!
    window.removeEventListener('resize', this.updatePopovers)
    this.scrollbars?.removeEventListener('scroll', this.updatePopovers)

    this.element.removeEventListener('input', this.checkForEscalation)
    this.observer?.disconnect()
  }

  updatePopovers = () => {
    // HACK: popovers are cooked!
    this.popoverOutlets.forEach((popover) => popover.update())
  }

  async checkForEscalation() {
    const escalationGlyphs = this.element.querySelectorAll('.escalation-popover-glyph')

    await animationFrame()
    this.updatePopovers()

    const hasEscalation = Array.from(escalationGlyphs).some((glyph) => glyph.checkVisibility())

    if (hasEscalation) {
      this.submitTarget.value = 'escalation'
      this.submitTarget.textContent = I18n.t('app.start_comprehensive')
    } else {
      this.submitTarget.value = ''
      this.submitTarget.textContent = I18n.t('app.join_videomed_express_queue')
    }
  }

  // ==== Controllers

  // ==== Actions

  toggleCondition(event) {
    this.#conditions().forEach((obj) => {
      if (event.currentTarget.value === obj.value) {
        this.showRadioButtonMoreDetails({
          target: obj.target
        })
      } else {
        this.hideRadioButtonMoreDetails({
          target: obj.target
        })
      }
    })
  }

  toggleMedicationNoneInverse(event) {
    this.toggleNoneInverseSelections({
      input:   event.currentTarget,
      targets: this.medicationNoneInverseTargets
    })
  }

  toggleMedicalConditionsNoneInverse(event) {
    this.toggleNoneInverseSelections({
      input:   event.currentTarget,
      targets: this.medicalConditionsNoneInverseTargets
    })
  }

  togglePatientDiabetic(event) {
    if (event.currentTarget.value === 'yes') {
      this.showRadioButtonMoreDetails({
        target: this.patientDiabeticTarget
      })
    } else {
      this.hideRadioButtonMoreDetails({
        target: this.patientDiabeticTarget
      })
    }
  }

  // === Urinary Tract Infection

  toggleRecentProcedure(event) {
    if (event.currentTarget.value === 'yes') {
      this.showRadioButtonMoreDetails({
        target: this.recentProcedureTarget
      })
    } else {
      this.hideRadioButtonMoreDetails({
        target: this.recentProcedureTarget
      })
    }
  }

  toggleUrineTestStrip(event) {
    [
      { value: 'no', target: this.urineTestStripNoTarget },
      { value: 'yes', target: this.urineTestStripYesTarget }
    ].forEach((obj) => {
      if (event.currentTarget.value === obj.value) {
        this.showRadioButtonMoreDetails({
          target: obj.target
        })
      } else {
        this.hideRadioButtonMoreDetails({
          target: obj.target
        })
      }
    })
  }

  toggleUrinarySymptomsNoneOfTheAbove({ currentTarget }) {
    this.toggleCheckboxNoneOfTheAbove({
      input:   currentTarget,
      targets: this.urinarySymptomsNoneOfTheAboveTargets
    })
  }

  toggleOtherSymptomsNoneOfTheAbove({ currentTarget }) {
    this.toggleCheckboxNoneOfTheAbove({
      input:   currentTarget,
      targets: this.otherSymptomsNoneOfTheAboveTargets
    })
  }

  // === Contraceptive Management

  togglePatientCurrentlyBreastFeeding(event) {
    if (event.currentTarget.value === 'yes') {
      this.showRadioButtonMoreDetails({
        target: this.takingProgesteroneTarget
      })
    } else {
      this.hideRadioButtonMoreDetails({
        target: this.takingProgesteroneTarget
      })
    }
  }

  toggleContraceptiveManagementType(event) {
    if (!this.hasContraceptiveManagementPatientStatusTarget) { return }

    if (event.currentTarget.value === 'repeat') {
      this.showRadioButtonMoreDetails({
        target: this.contraceptiveManagementPatientStatusTarget
      })
    } else {
      this.hideRadioButtonMoreDetails({
        target: this.contraceptiveManagementPatientStatusTarget
      })
    }
  }

  toggleBreastFeedingReasonsNoneOfTheAbove({ currentTarget }) {
    this.toggleCheckboxNoneOfTheAbove({
      input:             currentTarget,
      targets:           this.breastFeedingReasonsNoneOfTheAboveTargets,
      noneValue:         'none',
      dispatchNoneInput: true // force dispatch input event to trigger escalation check
    })
  }

  togglePregnancyStatus({ currentTarget: { checked }, params: { listItem } }) {
    const targetKey = camelCase(`pregnancy_status_${listItem}_target`)

    if (this[camelCase(`has_${targetKey}`)]) {
      this[targetKey].classList.toggle(this.hideClass, !checked)
    }
  }

  togglePregnancyStatusNoneInverse(event) {
    this.toggleNoneInverseSelections({
      input:   event.currentTarget,
      targets: this.pregnancyStatusNoneInverseTargets
    })
  }

  togglePregnancyTest(event) {
    [
      { value: 'no', target: this.noPregnancyTestReasonTarget },
      { value: 'yes', target: this.pregnancyTestResultTarget }
    ].forEach((obj) => {
      if (event.currentTarget.value === obj.value) {
        this.showRadioButtonMoreDetails({
          target: obj.target
        })
      } else {
        this.hideRadioButtonMoreDetails({
          target: obj.target
        })
      }
    })
  }

  // === Escalation

  async checkRange(event) {
    const element   = event.currentTarget
    const queryData = new FormData()

    if (element.value === element.dataset.capturedValue) return

    if (element.dataset.bloodPressure) {
      queryData.append(this.bloodPressureDiastolicTarget.name, this.bloodPressureDiastolicTarget.value)
      queryData.append(this.bloodPressureSystolicTarget.name, this.bloodPressureSystolicTarget.value)
    } else {
      let value = element.value

      if (element.type === 'checkbox') {
        if (element.checked) {
          value = element.value
        } else {
          value = `${element.value}_unchecked`
        }
      }

      queryData.append(element.name, value)
    }

    const response = await get(
      this.rangeCheckUrlValue,
      {
        query:        queryData,
        responseKind: 'turbo-stream'
      }
    )

    if (response.ok) {
      this.outOfRangePopoverTargets.forEach((elPopover) => {
        if (!$fq(elPopover).hasClass('u-hide')) {
          $fq(elPopover).hide()
        }
      })

      this.outOfRangeGlyphTargets.forEach((elGlyph) => {
        $fq(elGlyph).changeGlyph('#exclamation-circle')
      })

      this.#updateBloodPressureGlyph({ element, show: response.statusCode === 200 })
    }
  }

  submitForm() {
    this.submitTarget.click()
  }

  toggleOutOfRangePopoverVaginalBleeding() {
    this.toggleOutOfRangePopover({ targetStr: 'VaginalBleeding' })
  }

  toggleOutOfRangePopoverFirstTimeContraception() {
    this.toggleOutOfRangePopover({ targetStr: 'FirstTimeContraception' })
  }

  toggleOutOfRangePopoverCalvesOnPalpation() {
    this.toggleOutOfRangePopover({ targetStr: 'CalvesOnPalpation' })
  }

  toggleOutOfRangePopoverCalvesTenderness() {
    this.toggleOutOfRangePopover({ targetStr: 'CalvesTenderness' })
  }

  toggleOutOfRangePopoverCalvesSwelling() {
    this.toggleOutOfRangePopover({ targetStr: 'CalvesSwelling' })
  }

  toggleOutOfRangePopoverUpperLegsPelvicTenderness() {
    this.toggleOutOfRangePopover({ targetStr: 'UpperLegsPelvicTenderness' })
  }

  toggleOutOfRangePopoverUpperLegsPelvicSwelling() {
    this.toggleOutOfRangePopover({ targetStr: 'UpperLegsPelvicSwelling' })
  }

  toggleOutOfRangePopoverPregnancyTestResult() {
    this.toggleOutOfRangePopover({ targetStr: 'PregnancyTestResult' })
  }

  toggleOutOfRangePopoverNoPregnancyTestReason() {
    this.toggleOutOfRangePopover({ targetStr: 'NoPregnancyTestReason' })
  }

  toggleOutOfRangePopoverBreastFeedingNone() {
    this.toggleOutOfRangePopover({ targetStr: 'BreastFeedingNone' })
  }

  // ==== Getters

  // HACK: popovers are cooked!
  get scrollbars() {
    return this.element.closest('.m-scrollbars')
  }

  // ==== Setters

  // ==== Private

  #conditions() {
    const conditions = []

    if (this.hasContraceptiveManagementTypeTarget) {
      conditions.push({ value: 'contraceptive_management', target: this.contraceptiveManagementTypeTarget })
    }

    return conditions
  }

  #updateBloodPressureGlyph({ element, show } = {}) {
    if (!element.dataset.bloodPressure) return

    const glyph = this.outOfRangeGlyphBloodPressureDiastolicTarget

    if (show) {
      this.toggleOutOfRangeGlyph({ glyphStr: '#exclamation-circle-outline', targetStr: 'BloodPressureDiastolic' })
      $fq(glyph).show()
    } else {
      $fq(glyph).hide()
    }
  }

}
