import ApplicationController from '../support/application_controller'

export default class extends ApplicationController {

  static targets = [
    'button',
    'container'
  ]

  connect() {
    if (this.containerTarget.offsetHeight > 20) {
      this.buttonTarget.classList.remove('u-hide')
    } else {
      this.buttonTarget.classList.add('u-hide')
    }
  }

  // ==== Controllers

  // ==== Actions

  toggleBody(_event) {
    if (this.element.classList.contains('-expand')) {
      this.element.classList.remove('-expand')
    } else {
      this.element.classList.add('-expand')
    }
  }

  // ==== Getters

  // ==== Setters

  // ==== Private

  // ==== Channels

}
