import consumer from '../channels/consumer'

/**
 * Creates a subscription on the default ActionCable consumer
 *
 * @see {@link ActionCable.Subscriptions.create}
 *
 * @param {string|ActionCable.ChannelNameWithParams} channelName
 * @param {ActionCable.Mixin|undefined} mixin
 * @returns {ActionCable.Subscription & ActionCable.Mixin}
 */
export default (channelName, mixin) => consumer.subscriptions.create(channelName, mixin)
