import ApplicationController from '../support/application_controller'
import { disableElement } from '../support/helpers'

export default class extends ApplicationController {

  static targets = [
    'disableElements'
  ]

  disableButtons() {
    this.disableElementsTargets.forEach((button) => {
      setTimeout(() => {
        disableElement(button)
      }, 0)
    })
  }

}
