import ApplicationController from '../../support/application_controller'
import $fq                   from '../../support/fake_query'

export default class extends ApplicationController {

  static targets = [
    'postalAddressForm'
  ]

  initialize() {}

  connect() {}

  // ==== Controllers

  // ==== Actions

  togglePostalAddressForm(event) {
    if (event.currentTarget.checked) {
      this.postalAddressFormTargets.forEach((target) => {
        $fq(target).addClass('u-hide')
      })
    } else {
      this.postalAddressFormTargets.forEach((target) => {
        $fq(target).removeClass('u-hide')
      })
    }
  }

  // ==== Getters

  // ==== Setters

  // ==== Private

  // ==== Channels

}
