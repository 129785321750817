import ApplicationController from '../support/application_controller'
import $fq                   from '../support/fake_query'

export default class extends ApplicationController {

  static targets = [
    'location',
    'locationContainer',
    'route'
  ]

  static values = {}

  static classes = []

  initialize() {}

  connect() {}

  // ==== Controllers

  // ==== Actions

  renderResponse(event) {
    const [_response, _status, xhr] = event.detail

    $fq(this.element).replaceWith(xhr.response)
  }

  routeChanged(event) {
    const element          = event.currentTarget
    const locationRequired = [
      'intradermal',
      'intramuscular_injection',
      'intravenous_infusion',
      'subcutaneous'
    ]

    if (locationRequired.includes(element.value) === true) {
      $fq(this.locationContainerTarget).show()
      this.locationTarget.removeAttribute('disabled')
    } else {
      $fq(this.locationContainerTarget).hide()
      this.locationTarget.setAttribute('disabled', 'disabled')
    }
  }

  // ==== Getters

  // ==== Setters

  // ==== Private

  // ==== Channels

}
