import camelCase        from 'lodash/camelCase'
import WizardController from '../../../wizard_controller'

export default class extends WizardController {

  static targets = [
    'breastAbnormalitiesInLeft',
    'breastAbnormalitiesInLeftAxillaryNodes',
    'breastAbnormalitiesInLeftMasses',
    'breastAbnormalitiesInLeftNippleDischarge',
    'breastAbnormalitiesInLeftSignsOfPreviousSurgery',
    'breastAbnormalitiesInLeftSkinChanges',
    'breastAbnormalitiesInRight',
    'breastAbnormalitiesInRightAxillaryNodes',
    'breastAbnormalitiesInRightMasses',
    'breastAbnormalitiesInRightNippleDischarge',
    'breastAbnormalitiesInRightSignsOfPreviousSurgery',
    'breastAbnormalitiesInRightSkinChanges',
    'breastNoAbnormalities',
    'lowerAbdominalDistensionTrue',
    'lowerAbdominalGuardingTrue',
    'lowerAbdominalMassesFelt',
    'lowerAbdominalPainTrue',
    'lowerAbdominalUterineSizePalpable',
    'thyroidEnlargementTrue',
    'thyroidEnlargementTrue',
    'thyroidMassesFelt',
    'thyroidPainTrue',
    'vulvaAndVaginalExaminationForm',
    'vulvaAndVaginalPvDischargeTrue',
    'vulvaAndVaginalRefusedExaminationForm',
    'vulvaAndVaginalSpeculumExaminationDone',
    'vulvaAndVaginalVaginalMassesFelt',
    'vulvaAndVaginalVulvaExaminationAbnormal'
  ]

  // ==== Controllers

  // ==== Actions

  toggleBreastNoAbnormalities(event) {
    this.toggleNoneInverseSelection({
      input:  event.currentTarget,
      target: this.breastNoAbnormalitiesTarget
    })
  }

  toggleBreastSubForm(event) {
    const prefix = 'consultation_note_gynaecology_assessment_examination_form_'
    const id     = event.currentTarget.id

    const targetPropertyName = `${camelCase(id.substring(prefix.length))}Target`
    const target             = this[targetPropertyName]

    this.toggleCheckboxMoreDetails({
      input:  event.currentTarget,
      target
    })
  }

  toggleFeltNotFeltSelection(event) {
    const prefix = 'consultation_note_gynaecology_assessment_examination_form_'
    const id     = event.currentTarget.id
    const key    = id.substring(prefix.length).replace('_not_felt', '').replace('felt', '')

    const targetPropertyName = `${camelCase(camelCase(key))}FeltTarget`
    const target             = this[targetPropertyName]

    if (event.currentTarget.value === 'felt') {
      this.showRadioButtonMoreDetails({ target })
    } else {
      this.hideRadioButtonMoreDetails({ target })
    }
  }

  toggleLowerAbdominalUterineSizeSelections(event) {
    const target = this.lowerAbdominalUterineSizePalpableTarget

    if (event.currentTarget.value === 'palpable') {
      this.showRadioButtonMoreDetails({ target })
    } else {
      this.hideRadioButtonMoreDetails({ target })
    }
  }

  toggleVulvaAndVaginalRefusedExamination(event) {
    this.toggleNoneInverseSelection({
      input:  event.currentTarget,
      target: this.vulvaAndVaginalExaminationFormTarget
    })
    this.toggleCheckboxMoreDetails({
      input:  event.currentTarget,
      target: this.vulvaAndVaginalRefusedExaminationFormTarget
    })
  }

  toggleVulvaAndVaginalSpeculumExaminationSelections(event) {
    const target = this.vulvaAndVaginalSpeculumExaminationDoneTarget

    if (event.currentTarget.value === 'done') {
      this.showRadioButtonMoreDetails({ target })
    } else {
      this.hideRadioButtonMoreDetails({ target })
    }
  }

  toggleVulvaAndVaginalVulvaExaminationSelections(event) {
    const target = this.vulvaAndVaginalVulvaExaminationAbnormalTarget

    if (event.currentTarget.value === 'abnormal') {
      this.showRadioButtonMoreDetails({ target })
    } else {
      this.hideRadioButtonMoreDetails({ target })
    }
  }

  toggleTrueFalseSelection(event) {
    const prefix = 'consultation_note_gynaecology_assessment_examination_form_'
    const id     = event.currentTarget.id

    const key                = id.substring(prefix.length).split('_').slice(0, -1).join('_')
    const targetPropertyName = `${camelCase(camelCase(key))}TrueTarget`
    const target             = this[targetPropertyName]

    if (event.currentTarget.value === 'true') {
      this.showRadioButtonMoreDetails({ target })
    } else {
      this.hideRadioButtonMoreDetails({ target })
    }
  }

  // ==== Getters

  // ==== Setters

  // ==== Private

  // ==== Channels

}
