import debounce              from 'lodash/debounce'
import Rails                 from '@rails/ujs'
import ApplicationController from '../../support/application_controller'

export default class extends ApplicationController {

  static values = {
    overrideSelectUrl: String,
    viewSearch:        String
  }

  static targets = [
    'destroy',
    'hasMedicalAid',
    'loader',
    'membershipNumber',
    'optionContent',
    'query',
    'queryWrapper',
    'results',
    'searchBox',
    'selectedOption',
    'selection',
    'submit'
  ]

  static outlets = [
    'onboarding--hpq-search'
  ]

  initialize() {
    this.startSearch = debounce(this.startSearch.bind(this), 250)

    if (this.viewSearchValue === 'true') {
      this.medicalAidTarget('_true').checked = true
      this.toggleSearch('show')
    }
  }

  // ==== Actions

  toggleForm(event) {
    const element = event.currentTarget

    if (element.value === 'true') {
      this.toggleSearch('show')
    } else if (element.value === 'false') {
      this.toggleSearch('hide')
    }
  }

  clearSearch() {
    this.resultsTarget.innerHTML = ''
    this.searchBoxTarget.classList.remove('-active')

    if (this.query) {
      this.queryTarget.value = ''
    }

    this.queryTarget.focus()
  }

  startSearch(_event) {
    this.queryWrapperTarget.classList.remove('-error')
    this.showLoader()

    Rails.ajax({
      url:      this.url,
      type:     'POST',
      data:     this.params({ q: this.query }),
      dataType: 'script',
      success:  (_response, _status, xhr) => {
        this.resultsTarget.innerHTML = xhr.response
        this.setFormState()
      },
      error:    (_response, _status, _xhr) => {},
      complete: (_xhr, _status) => {
        this.hideLoader()
      }
    })
  }

  selectOption(event) {
    const element = event.currentTarget
    let url       = element.dataset.url

    if (this.overrideSelectUrlValue && element.dataset.row_id) {
      url = this.overrideSelectUrlValue.replace('OPTION_ID', element.dataset.row_id)
    }

    Rails.ajax({
      url,
      type:     'GET',
      dataType: 'script',
      success:  (_response, _status, xhr) => {
        this.clearSearch()
        this.deactivateSearch()
        this.hideSearchBox()
        this.selectionTarget.innerHTML = xhr.response
        this.showSelection()
        this.setDestroyTarget()
        if (this.hasMembershipNumberTarget) {
          this.membershipNumberTarget.focus()
        }
      },
      error:    (_response, _status, _xhr) => {},
      complete: (_xhr, _status) => {
        if (this.hasOptionContentTarget && this.hasSelectedOptionTarget) {
          this.selectedOptionTarget.value = this.optionContentTarget.dataset.id
          this.hideSearchBox()
        }
      }
    })
  }

  setDestroyTarget() {
    if (this.hasSelectedOptionTarget && this.hasDestroyTarget) {
      const discoveryScheme = this.selectedOptionTarget.dataset.discoveryScheme

      if (discoveryScheme === 'true') {
        this.destroyTarget.value = 'false'
      } else if (discoveryScheme === 'false') {
        this.destroyTarget.value = 'true'
      }
    }
  }

  changeMedicalScheme(_event) {
    this.showSearchBox()
    this.selectedOptionTarget.value = ''
    if (this.hasMembershipNumberTarget) {
      this.membershipNumberTarget.value = ''
    }
    this.hideSelection()
    this.queryTarget.focus()
  }

  renderResults(event) {
    event.stopPropagation()

    const [_data, _status, xhr] = event.detail

    this.resultsTarget.innerHTML = xhr.response
    this.setFormState()
  }

  checkHPQ(event) {
    if (!this.hasOnboardingHpqSearchOutlet) return

    if (this.onboardingHpqSearchOutlet.valid === 'false') {
      this.toggleSelectionError('show')
      event.preventDefault()
    } else if (this.onboardingHpqSearchOutlet.valid === 'init' || this.onboardingHpqSearchOutlet.valid === 'true') {
      this.toggleSelectionError('hide')
    }
  }

  // ==== Getters

  get url() {
    return this.data.get('url')
  }

  get query() {
    return this.queryTarget.value
  }

  // ==== Setters

  // ==== Private

  activateSearch() {
    this.searchBoxTarget.classList.add('-active')
  }

  deactivateSearch() {
    this.searchBoxTarget.classList.remove('-active')
  }

  showSearchBox() {
    this.searchBoxTarget.classList.remove('u-hide')
  }

  hideSearchBox() {
    this.searchBoxTarget.classList.add('u-hide')
  }

  showSelection() {
    this.selectionTarget.classList.remove('u-hide')
  }

  hideSelection() {
    this.selectionTarget.classList.add('u-hide')
  }

  medicalAidTarget(filter) {
    return this.hasMedicalAidTargets.find((input) => input.id.includes(filter))
  }

  toggleSelectionError(state) {
    if (state === 'hide') {
      this.selectionTarget.classList.remove('-errorProfile')
    } else if (state === 'show') {
      this.selectionTarget.classList.add('-errorProfile')
    }
  }

  toggleSearch(state) {
    if (state === 'show') {
      this.showSearchBox()
      this.queryTarget.focus()
    } else if (state === 'hide') {
      this.hideSearchBox()
      this.clearSearch()
      this.selectionTarget.innerHTML = ''
    }
  }

  setFormState() {
    if (this.query) {
      this.activateSearch()
    } else {
      this.deactivateSearch()
    }
  }

  showLoader(event) {
    if (event) {
      event.stopPropagation()
    }

    this.loaderTarget.classList.remove('u-hide')
  }

  hideLoader(event) {
    if (event) {
      event.stopPropagation()
    }

    const self = this

    setTimeout(() => {
      self.loaderTarget.classList.add('u-hide')
    }, 500)
  }

  params(data) {
    return Object.keys(data).map((key) => `${key}=${encodeURIComponent(data[key])}`).join('&')
  }

  // ==== Channels

}
