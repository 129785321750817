import ApplicationController from '../../support/application_controller'

export default class extends ApplicationController {

  static targets = [
    'age0',
    'age6w',
    'age10w',
    'age14w',
    'age6m',
    'age9m',
    'age12m',
    'age1215m',
    'age18m',
    'age6y',
    'age9y',
    'age12y',
    'age925y',
    'ageNa',
    'age0Item',
    'age6wItem',
    'age10wItem',
    'age14wItem',
    'age6mItem',
    'age9mItem',
    'age12mItem',
    'age1215mItem',
    'age18mItem',
    'age6yItem',
    'age9yItem',
    'age12yItem',
    'age925yItem',
    'ageNaItem',
    'form',
    'patientVaccine',
    'newActions',
    'newVaccine',
    'responseBody',
    'skeleton',
    'vaccine'
  ]

  static outlets = [
    'consultation'
  ]

  connect() {
    if (this.data.get('type') === 'other') {
      if (this.hasNewVaccineTarget) {
        this.showSkeleton()
        this.newVaccineTarget.click()
      }
    }
  }

  // ==== Actions

  restoreForm() {
    this.deselectVaccines()
    this.hideSkeleton()
    this.showNewActions()
    this.removeForm()
  }

  selectVaccine(event) {
    const [_data, _status, xhr] = event.detail
    const element = event.srcElement.childNodes[0]

    this.deselectVaccines()
    element.classList.add('-selected')
    this.responseBodyTarget.innerHTML = xhr.response
    this.hideSkeleton()
  }

  addOtherVaccine(event) {
    const [_data, _status, xhr] = event.detail

    this.deselectVaccines()
    this.responseBodyTarget.innerHTML = xhr.response
    this.hideSkeleton()
  }

  renderForm(event) {
    const [_data, _status, xhr] = event.detail

    this.responseBodyTarget.innerHTML = xhr.response
    this.renderFlashMessage(xhr)
    this.hideSkeleton()
  }

  toggleVaccineAge(vaccineAge) {
    const target      = this[`${vaccineAge}Target`]
    const itemTargets = this[`${vaccineAge}ItemTargets`]

    if (itemTargets.length === 0) {
      target.classList.add('u-hide')
    } else {
      target.classList.remove('u-hide')
    }
  }

  createVaccine(event) {
    const [_data, _status, xhr] = event.detail
    const vaccineAge  = xhr.getResponseHeader('X-VaccineAge')
    const vaccineType = xhr.getResponseHeader('X-VaccineType')
    const target      = this[`${vaccineAge}Target`]

    if (xhr.status === 422) {
      this.responseBodyTarget.innerHTML = xhr.response
      this.renderFlashMessage(xhr)
      return
    }

    if (target) {
      const element = target.querySelector('[data-vaccines-list="epi_other"]')

      if (element) {
        if (vaccineType === 'other') {
          if (this.ageNaItemTargets.length === 0) {
            element.childNodes[0].classList.add('u-hide')
          }
          element.insertAdjacentHTML('afterbegin', xhr.response)
        } else {
          const newNode       = document.createElement('div')
          const referenceNode = element.childNodes[0]
          newNode.innerHTML   = xhr.response
          referenceNode.after(newNode.childNodes[0])
          element.classList.remove('u-hide')
        }
      }

      this.showNewActions()
      this.renderFlashMessage(xhr)

      if (vaccineType === 'other') {
        this.showSkeleton()
        this.newVaccineTarget.click()
      } else {
        this.toggleVaccineAge(vaccineAge)
        target.scrollIntoView({
          behavior: 'smooth',
          block:    'start'
        })
      }
    }

    this.toggleManageButton()
    this.renderFlashMessage(xhr)
  }

  destroyVaccine(event) {
    const [_data, _status, xhr] = event.detail
    const vaccineAge  = xhr.getResponseHeader('X-VaccineAge')
    const vaccineId   = xhr.getResponseHeader('X-VaccineId')
    const vaccineType = xhr.getResponseHeader('X-VaccineType')
    const target      = this[`${vaccineAge}Target`]

    if (vaccineId) {
      const element = document.querySelector(`[data-vaccine-id="${vaccineId}"]`)

      if (element) {
        element.remove()

        if (vaccineType !== 'other') {
          this.toggleVaccineAge(vaccineAge)
        }

        this.toggleManageButton()
        this.renderFlashMessage(xhr)

        if (target) {
          if (target.querySelectorAll('[data-vaccines-list="epi_other"] [data-vaccine-id]').length === 0) {
            if (vaccineType === 'other') {
              target.querySelector('[data-vaccines-list="epi_other"] *:first-child').classList.remove('u-hide')
            } else {
              target.querySelector('[data-vaccines-list="epi_other"]').classList.add('u-hide')
            }
          }
        }
      }
    }
  }

  updateVaccine(event) {
    const [_data, _status, xhr] = event.detail
    const vaccineId   = xhr.getResponseHeader('X-VaccineId')
    const vaccineType = xhr.getResponseHeader('X-VaccineType')

    if (xhr.status === 422) {
      this.responseBodyTarget.innerHTML = xhr.response
      this.renderFlashMessage(xhr)
      return
    }

    if (vaccineId) {
      const element = document.querySelector(`[data-vaccine-id="${vaccineId}"]`)

      if (element) {
        element.outerHTML = xhr.response
        this.showNewActions()
        this.toggleManageButton()
        this.renderFlashMessage(xhr)
      }

      if (vaccineType === 'other') {
        this.showSkeleton()
        this.newVaccineTarget.click()
      }
    }
  }

  // ==== Getters

  get count() {
    return parseInt(this.data.get('count'), 10)
  }

  // ==== Setters

  set count(value) {
    this.data.set('count', value)
  }

  // ==== Private

  deselectVaccines() {
    this.vaccineTargets.forEach((el) => {
      el.childNodes[0].classList.remove('-selected')
    })
  }

  hideNewActions() {
    this.newActionsTarget.classList.add('u-hide')
  }

  hideSkeleton() {
    this.skeletonTarget.classList.add('u-hide')
    this.skeletonTarget.classList.remove('u-ani-skeletonLoading')
  }

  removeForm() {
    this.responseBodyTarget.innerHTML = ''
  }

  showNewActions() {
    this.removeForm()
    this.newActionsTarget.classList.remove('u-hide')
  }

  showSkeleton() {
    this.hideNewActions()
    this.responseBodyTarget.innerHTML = ''
    this.skeletonTarget.classList.remove('u-hide')
    this.skeletonTarget.classList.add('u-ani-skeletonLoading')
  }

  toggleManageButton() {
    if (!this.consultationOutlet.hasManageVaccinesTarget) return

    if (this.patientVaccineTargets.length === 0) {
      this.consultationOutlet.manageVaccinesTarget.classList.add('u-hide')
    } else {
      this.consultationOutlet.manageVaccinesTarget.classList.remove('u-hide')
    }
  }

  // ==== Channels

}
