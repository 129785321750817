import ApplicationController from '../support/application_controller'

export default class extends ApplicationController {

  static targets = []

  static values = {}

  static classes = []

  initialize() {
    this.startPoller()
    this.setLocalStorage(true)

    window.addEventListener('unload', (_event) => {
      this.stopPoller()
      this.setLocalStorage(false)
    })
  }

  connect() {}

  disconnect() {
    this.stopPoller()
  }

  // ==== Controllers

  // ==== Actions

  // ==== Getters

  // ==== Setters

  // ==== Private

  startPoller() {
    this.intervalPoller = setInterval(() => {
      this.handleSecondScreen()
    }, 3000)
  }

  stopPoller() {
    clearInterval(this.intervalPoller)
  }

  handleSecondScreen() {
    if (!window.opener) {
      window.close()
      this.setLocalStorage(false)
    }
  }

  setLocalStorage(value) {
    localStorage.setItem('activeSecondScreen', value)
  }

  // ==== Channels

}
