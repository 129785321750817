import SimpleAssessmentController from './simple_assessment_controller'
import BmiCalculator              from '../../support/bmi_calculator'

export default class extends SimpleAssessmentController {

  static targets = [
    'bmi',
    'cholesterol',
    'cholesterolTest',
    'fullAssessment',
    'glucose',
    'glucoseAndCholesterolTest',
    'glucoseTest',
    'height',
    'weight'
  ]

  connect() {
    BmiCalculator(this)
  }

  initialize() {}

  disconnect() {}

  // ==== Controllers

  // ==== Actions

  hideTarget(target) {
    target.classList.add('u-hide')
  }

  hideRadioButtonMoreDetails({ target } = {}) {
    this.hideTarget(target)
  }

  showRadioButtonMoreDetails({ target } = {}) {
    this.showTarget(target)
  }

  showTarget(target) {
    target.classList.remove('u-hide')
  }

  toggleAssessmentSelections({ currentTarget }) {
    const targetMap = {
      cholesterol_test:             this.cholesterolTestTarget,
      full_assessment:              this.fullAssessmentTarget,
      glucose_and_cholesterol_test: this.glucoseAndCholesterolTestTarget,
      glucose_test:                 this.glucoseTestTarget
    }

    Object.entries(targetMap).forEach(([value, target]) => {
      if (currentTarget.value === value) {
        this.showRadioButtonMoreDetails({ target })
      } else {
        this.hideRadioButtonMoreDetails({ target })
      }
    })
  }

  updateCholesterolInputs({ currentTarget }) {
    this.updateInputValues(this.cholesterolTargets, currentTarget.value)
  }

  updateGlucoseInputs({ currentTarget }) {
    this.updateInputValues(this.glucoseTargets, currentTarget.value)
  }

  // ==== Getters

  // ==== Setters

  // ==== Private

  updateInputValues(inputs, value) {
    inputs.forEach((input) => { input.value = value })
  }

  // ==== Channels

}
