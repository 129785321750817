import debounce              from 'lodash/debounce'
import Rails                 from '@rails/ujs'
import ApplicationController from '../support/application_controller'
import $fq                   from '../support/fake_query'

export default class extends ApplicationController {

  static targets = [
    'icon',
    'loader',
    'query',
    'results',
    'searchBox',
    'selected',
    'selectedDisplayName',
    'selectedId',
    'submit'
  ]

  initialize() {
    this.currentXHR = null

    if (this.autofocusQuery) {
      this.queryTarget.focus()
    }

    this.startSearch = debounce(this.startSearch.bind(this), 250)
  }

  // ==== Controllers

  // ==== Actions

  clearSearch() {
    $fq(this.resultsTarget).innerHTML('')
    $fq(this.searchBoxTarget).removeClass('-active')

    if (this.query) {
      this.queryTarget.value = ''
    }

    this.queryTarget.focus()
  }

  startSearch(_event) {
    this.showLoader()

    Rails.ajax({
      url:        this.url,
      type:       'POST',
      data:       this.params({ q: this.query }),
      dataType:   'script',
      beforeSend: (xhr, _options) => {
        this.cancelInProgress({ detail: [xhr] })
      },
      success:    (_response, _status, xhr) => {
        $fq(this.resultsTarget).innerHTML(xhr.response)
        this.setFormState()
      },
      error:      (_response, _status, _xhr) => { },
      complete:   (xhr, _status) => {
        if (this.currentXHR === xhr) {
          this.currentXHR = null
          this.hideLoader()
        }
      }
    })
  }

  startManualSearch(_event) {
    this.submitTarget.click()
  }

  renderResults(event) {
    event.stopPropagation()

    const [_data, _status, xhr] = event.detail

    $fq(this.resultsTarget).innerHTML(xhr.response)
    this.setFormState()
  }

  editSelection() {
    $fq(this.searchBoxTarget).show()
    $fq(this.selectedTarget).hide()

    this.selectedIdTarget.value          = ''
    this.selectedDisplayNameTarget.value = ''

    this.queryTarget.focus()
  }

  showLoader(event) {
    if (event) { event.stopPropagation() }

    if (this.hasIconTarget) {
      $fq(this.iconTarget).hide()
    }

    $fq(this.loaderTarget).show()
  }

  hideLoader(event) {
    if (event) {
      event.stopPropagation()

      const [xhr] = event.detail

      if (this.currentXHR === xhr) {
        this.currentXHR = null
      } else {
        return
      }
    }

    const self = this

    setTimeout(() => {
      if (this.hasIconTarget) {
        $fq(this.iconTarget).show()
      }

      $fq(self.loaderTarget).hide()
    }, 500)
  }

  selectItem(event) {
    const element     = event.currentTarget
    const id          = element.dataset.id
    const displayName = element.dataset.displayName

    $fq(this.searchBoxTarget).hide()
    $fq(this.selectedTarget).show()

    this.selectedIdTarget.value          = id
    this.selectedDisplayNameTarget.value = displayName

    this.clearSearch()
  }

  cancelInProgress({ detail: [xhr] }) {
    const previousXHR = this.currentXHR
    this.currentXHR = xhr
    previousXHR?.abort()
  }

  // ==== Getters

  get url() {
    return this.data.get('url')
  }

  get query() {
    return this.queryTarget.value
  }

  get autofocusQuery() {
    return this.data.get('autofocusQuery') === 'true'
  }

  // ==== Setters

  // ==== Private

  setFormState() {
    if (this.query) {
      $fq(this.searchBoxTarget).addClass('-active')
    } else {
      $fq(this.searchBoxTarget).removeClass('-active')
    }
  }

  params(data) {
    return Object.keys(data).map((key) => `${key}=${encodeURIComponent(data[key])}`).join('&')
  }

  // ==== Channels

}
