import ApplicationController from '../../support/application_controller'

export default class extends ApplicationController {

  static targets = [
    'billingPrice'
  ]

  // ==== Actions

  disablePrices(event) {
    const currentId = event.currentTarget.id

    this.billingPriceTargets.forEach((element) => {
      if (currentId !== element.id) {
        element.innerHTML = element.getAttribute('data-disable-with')
        element.setAttribute('disabled', 'disabled')
      }
    })
  }

}
