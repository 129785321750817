import OverlayScrollbars     from 'overlayscrollbars'
import ApplicationController from '../support/application_controller'

export default class extends ApplicationController {

  static targets = [
    'content'
  ]

  contentTargetConnected(element) {
    let sizeAutoCapable = true
    if (element.dataset.sizeAutoCapable === 'false') {
      sizeAutoCapable = false
    }

    element.overlayScrollbars = OverlayScrollbars(element, {
      sizeAutoCapable,
      callbacks: {
        onScroll() {
          element.dispatchEvent(new Event('scroll', { bubbles: false, cancelable: false }))
        }
      }
    })
  }

  contentTargetDisconnected(element) {
    element.overlayScrollbars?.destroy()
  }

}
