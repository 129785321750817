import CreateChannel     from '../client/cable'
import { getGlobalMute } from './global_mute'
import { metaContent }   from './helpers'
import { playSound }     from './audio_sound'

async function requestPermission() {
  return (await Notification.requestPermission()) === 'granted'
}

async function canDisplay() {
  if (!('Notification' in window)) return false

  switch (Notification.permission) {
    case 'denied': return false
    case 'granted': return true
    default: return requestPermission()
  }
}

/**
 * Checks if notifications are allowed, request them if necessary,
 * and displays the notification if allowed and the page is not in focus
 *
 * @param {NotificationOptions & { title: string }} param0
 * @returns {Notification|null} the notification if allowed, null otherwise
 */
export default async function displayNotification({
  title = metaContent('app:notifications:title'),
  icon = metaContent('app:notifications:icon'),
  ...options
}) {
  if (!document.hasFocus() && await canDisplay()) {
    const notification = new Notification(title, {
      icon,
      silent: getGlobalMute(),
      ...options
    })
    notification.onclick = () => window.focus()
    return notification
  }

  return null
}

/**
 * Custom element to listen to NotificationsChannel
 * Accepts `type` and `context` attributes
 *
 * `type` can be "user", "role", or absent:
 * - "user" listens to notifications for the current user
 * - "role" listens to notifications for the current user's role
 * - if absent, listens to notifications for all users
 *
 * `context` can be a single string or multiple strings split by whitespace
 * This allows for listenening to notifications for a specific context
 *
 * Examples:
 * <notification-source type="user" context="lobby">
 * <notification-source type="role" context="queue express">
 *
 * Based on turbo stream source element:
 * @see {@link https://github.com/hotwired/turbo/blob/main/src/elements/stream_source_element.js}
 * @see Broadcast::NotificationJob app/jobs/broadcast/notification_job.rb
 */
class NotificationSourceElement extends HTMLElement {

  connectedCallback() {
    this.subscription = CreateChannel({ channel: 'NotificationsChannel', type: this.type, context: this.context }, {
      received({ sound, notification }) {
        if (notification) displayNotification(notification)
        if (sound) playSound(sound)
      }
    })
  }

  disconnectedCallback() {
    this.subscription?.unsubscribe()
  }

  get type() {
    return this.getAttribute('type') || undefined
  }

  get context() {
    if (this.hasAttribute('context')) {
      return this.getAttribute('context').split(/\s+/)
    }
    // getters require explicit returns
    return undefined
  }

}

if (customElements.get('notification-source') === undefined) {
  customElements.define('notification-source', NotificationSourceElement)
}
