import ApplicationController from '../../support/application_controller'
import $fq                   from '../../support/fake_query'
import BmiCalculator         from '../../support/bmi_calculator'

export default class extends ApplicationController {

  static targets = [
    'bmi',
    'height',
    'weight'
  ]

  static outlets = [
    'consultation'
  ]

  connect() {
    BmiCalculator(this)
  }

  initialize() {}

  disconnect() {}

  // ==== Actions

  renderResponse(event) {
    const [_response, _status, xhr] = event.detail

    $fq(this.consultationOutlet.responseBodyTarget).html(xhr.response)
  }

  // ==== Getters

  // ==== Setters

  // ==== Private

  // ==== Channels

}
