import ApplicationController from '../../frontend/support/application_controller'

export default class extends ApplicationController {

  static targets = []

  static values = {}

  static classes = []

  initialize() {}

  connect() {}

  // ==== Controllers

  // ==== Actions

  toggleSelected({ currentTarget }) {
    currentTarget.classList.toggle('-style-selected')
  }

  // ==== Getters

  // ==== Setters

  // ==== Private

  // ==== Channels

}
