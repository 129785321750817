import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="numeric-input"
export default class extends Controller {

  scroll() {
    if (this.element !== document.activeElement) { return }

    this.element.blur()

    setTimeout(() => {
      this.element.focus()
    }, 0)
  }

}
