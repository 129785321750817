import ApplicationController from '../../frontend/support/application_controller'
import map from '../support/map.json'

export default class extends ApplicationController {

  static targets = [
    'tooltip'
  ]

  initialize() {
    this.update = this.update.bind(this)
  }

  connect() {}

  // ==== Controllers

  // ==== Actions

  show() {
    this.update()
    document.addEventListener('scroll', this.update, { passive: true, capture: true })
  }

  hide() {
    document.removeEventListener('scroll', this.update, { capture: true })
  }

  // ==== Getters

  // ==== Setters

  // ==== Private

  update() {
    const rect = this.element.getBoundingClientRect()
    this.tooltipTarget.style.position = 'fixed'
    this.tooltipTarget.style.top      = `${rect.y + parseInt(map.space_base_3x.original.value, 10)}px`
    this.tooltipTarget.style.left     = `${rect.x}px`
    if (this.tooltipTarget.classList.contains('u-w-full')) {
      this.tooltipTarget.style.width = `${rect.width}px`
    }
  }

  // ==== Channels

}
