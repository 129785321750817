import { Client }      from '@twilio/conversations'
import { metaContent } from '../../support/helpers'

const TOKEN_META_NAME = 'app:clinician_conversation_token'

/**
 * Lazily constructs and memoizes a single global twilio conversation client instance
 * @returns {import("@twilio/conversations").Client} twilio conversation client
 */
export function getTwilioConversationsClient() {
  const token = metaContent(TOKEN_META_NAME)
  if (!token) throw new Error(`getTwilioConversationsClient: Meta tag missing or empty: "${TOKEN_META_NAME}"`)

  window.TwilioConversationsClient ??= new Client(token)
  return window.TwilioConversationsClient
}

/**
 * Adds a bunch of event listeners to a twilio Client or Conversation,
 * returning a function for later removing only those listeners.
 *
 * Useful for sharing a single Twilio client/conversation between multiple stimulus controllers
 * without dangling event listeners
 *
 * @param {import("@twilio/replay-event-emitter").ReplayEventEmitter} emitter e.g. twilio Client or Conversation
 * @param {Record<string, Function>} listeners Object of the form { [eventName]: listenerFunction }
 * @returns {() => void} clean-up function
 */
export function addTwilioEventListeners(emitter, listeners) {
  const listenerEntries = Object.entries(listeners)

  listenerEntries.forEach(([event, callback]) => {
    emitter.on(event, callback)
  })

  return () => {
    listenerEntries.forEach(([event, callback]) => {
      emitter.off(event, callback)
    })
  }
}
