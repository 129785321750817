import ApplicationController from '../../support/application_controller'

export default class extends ApplicationController {

  static targets = [
    'emailAddresses',
    'emailButton',
    'emailComplete',
    'emailForm',
    'emailInput',
    'pdfUrl'
  ]

  // ==== Actions

  ajaxComplete(event) {
    const [_response, _status, xhr] = event.detail

    this.renderFlashMessage(xhr)
    this.emailFormTarget.outerHTML = xhr.response
    if (this.hasEmailButtonTarget) this.emailButtonTarget.classList.remove('u-hide')
  }

  showEmailForm() {
    this.emailFormTarget.classList.remove('u-hide')
    this.emailInputTargets[0].focus()

    if (this.hasEmailButtonTarget) this.emailButtonTarget.classList.add('u-hide')
    if (this.hasEmailCompleteTarget) this.emailCompleteTarget.remove()
  }

  addEmail(event) {
    event.currentTarget.blur()

    const emailInput = this.emailInputTargets[0]
    const clone      = emailInput.cloneNode(true)

    clone.classList.remove('has-error')
    const errorMsg = clone.querySelector('.form-control__error')
    if (errorMsg) errorMsg.remove()

    const label = clone.querySelector('label')
    label.setAttribute('for', `simple_mailer[emails_attributes][${this.emailInputTargets.length}]_email`)

    const input = clone.querySelector('input')
    input.value = ''
    input.id    = `simple_mailer_emails_attributes_${this.emailInputTargets.length}_email`
    input.name  = `simple_mailer[emails_attributes][${this.emailInputTargets.length}][email]`

    this.emailAddressesTarget.appendChild(clone)
  }

  removeEmail(event) {
    const wrapper = event.target.closest('.l-nested-form')

    if (this.emailInputTargets.length === 1) return

    wrapper.remove()
  }

  openPdfInNewTab(_event) {
    window.open(this.pdfUrlTarget.value, '_blank')
  }

}
