import ApplicationController from '../../support/application_controller'
import $fq                   from '../../support/fake_query'

export default class extends ApplicationController {

  static targets = [
    'idLegalReference',
    'resetIdInput',
    'resetInput',
    'responseBody'
  ]

  static outlets = [
    'consultation'
  ]

  connect() {}

  initialize() {}

  disconnect() {}

  // ==== Actions

  renderConsultation(event) {
    const [_response, _status, xhr] = event.detail

    $fq(this.consultationOutlet.responseBodyTarget).html(xhr.response)
  }

  renderMemberDetailsForm(event) {
    const [_response, _status, xhr] = event.detail

    $fq(this.consultationOutlet.responseBodyTarget).html(xhr.response)
  }

  // ==== Getters

  // ==== Setters

  // ==== Private

  // TODO: Abstract this into a parent controller with VHC

  resetInputs(inputs) {
    inputs.forEach((element) => {
      const type = element.getAttribute('type')

      if (type === 'checkbox' || type === 'radio') {
        element.checked = false
      } else {
        element.value = ''
      }
    })
  }

  // ==== Channels

}
