import debounce              from 'lodash/debounce'
import { post }              from '@rails/request.js'
import ApplicationController from '../support/application_controller'
import { timeout }           from '../support/helpers'

export default class extends ApplicationController {

  static targets = [
    'autoSearchErrorMessage',
    'clear',
    'divider',
    'forcedSearchErrorMessage',
    'form',
    'input',
    'inputs',
    'loader',
    'newPatient',
    'progressiveScroll',
    'results',
    'resultsTable',
    'submit'
  ]

  static values = {
    url:      String,
    formless: Boolean
  }

  static classes = []

  inputValues = {}

  initialize() {
    this.debouncedSearch = debounce(this.debouncedSearch.bind(this), 800)
    this.intersectionObserver = new IntersectionObserver(this.intersectionCallback.bind(this), {
      root:       this.element,
      rootMargin: '0px',
      threshold:  1.0
    })
  }

  connect() {
    if (this.formlessValue && !this.hasUrlValue) {
      throw new Error('formless advanced search must have a url value')
    }
    if (document.documentElement.hasAttribute('data-turbo-loaded')) {
      this.removeSkeleton()
    } else {
      document.addEventListener('turbo:load', () => {
        this.removeSkeleton()
      })
    }
    if (this.autofocusInput) {
      this.intersectionObserver.observe(this.autofocusInput)
    }
  }

  resultsTargetConnected() {
    if (this.hasResults) {
      this.hideErrorMessages()
      this.hideLoader()
      this.showClear()
      this.showDivider()
      this.showNewPatient()
      this.shrinkResultsIfNecessary()
    } else {
      this.hideNewPatient()
    }
  }

  // ==== Controllers

  get submitButtonSpinnerController() {
    if (!this.hasSubmitTarget) return null

    return this.getControllerForElement(this.submitTarget, 'button-component--spinner')
  }

  // ==== Actions

  clear() {
    for (const input of this.inputTargets) {
      input.value = ''
      const filled = input.closest('.-filled')
      if (filled) filled.classList.remove('-filled')
    }
    this.showSubmit()
    this.clearResults()
  }

  async input({ currentTarget }) {
    await timeout(1) // wait for cleave updates
    const { name, value } = currentTarget
    if (this.inputValues[name] === value) return
    this.search()
  }

  submitStart({ detail: { formSubmission } }) {
    if (this.anyAtLeastTwo) {
      this.formSubmission = formSubmission
      this.showLoader()
    } else {
      formSubmission.stop()
      this.showForcedSearchErrorMessage()
    }
  }

  // ==== Getters

  get anyAtLeastMin() {
    for (const input of this.inputTargets) {
      if (this.isAtLeastMin(input)) {
        return true
      }
    }
    return false
  }

  get anyAtLeastTwo() {
    for (const input of this.inputTargets) {
      if (input.value.length >= Math.min(2, this.getMinParameter(input))) {
        return true
      }
    }
    return false
  }

  get allBlank() {
    for (const input of this.inputTargets) {
      if (this.isBlank(input)) {
        return false
      }
    }
    return true
  }

  get hasResults() {
    return this.resultsTarget.children.length > 0
  }

  get autofocusInput() {
    return this.inputTargets.find((input) => input.autofocus)
  }

  get params() {
    const data = new FormData()
    this.inputTargets.forEach((input) => {
      data.append(input.name, input.value)
    })
    return data
  }

  // ==== Setters

  // ==== Private

  getMinParameter(input) {
    const paramAttr = `data-${this.identifier}-min-param`
    if (!input.hasAttribute(paramAttr)) return null

    return parseInt(input.getAttribute(paramAttr), 10)
  }

  isAtLeastMin(input) {
    const min = this.getMinParameter(input)
    if (min === null) return false

    return input.value.length >= min
  }

  isBlank(input) {
    return input.value.trim().length > 0
  }

  search() {
    this.hideForcedSearchErrorMessage()
    if (this.anyAtLeastMin) {
      this.hideAutoSearchErrorMessage()
      this.autoSearch()
    } else {
      this.clearResults()
      this.showSubmit()

      if (!this.allBlank) {
        this.showAutoSearchErrorMessage()
      }
    }
  }

  autoSearch() {
    this.cancelSearch()
    this.showLoader()
    this.debouncedSearch()
  }

  debouncedSearch() {
    if (this.anyAtLeastMin) {
      this.submit()
    }
  }

  manualSubmit(event) {
    event.preventDefault()
    if (this.formlessValue) this.showLoader()
    this.submit()
  }

  submit() {
    if (this.formlessValue) {
      this.formlessSubmit()
    } else {
      this.submitTarget.click()
    }
  }

  async formlessSubmit() {
    if (!this.anyAtLeastTwo) {
      this.showForcedSearchErrorMessage()
      return
    }

    this.abortController?.abort()
    this.abortController = new AbortController()

    try {
      await post(this.urlValue, { body: this.params, signal: this.abortController.signal })
    } catch (error) {
      if (error instanceof DOMException) return
      throw error
    }

    this.hideLoader()
  }

  clearResults() {
    this.resultsTarget.innerHTML = ''
    this.hideNewPatient()
    this.hideErrorMessages()
    this.hideDivider()
  }

  shrinkResultsIfNecessary() {
    if (!this.hasProgressiveScrollTarget) return

    if (this.resultsTableTarget.clientHeight < this.progressiveScrollTarget.clientHeight) {
      this.progressiveScrollTarget.style.height = `${this.resultsTableTarget.clientHeight}px`
    }
  }

  showClear(value = true) {
    if (this.hasClearTarget) {
      this.clearTarget.classList.toggle('u-hide', !value)
    }
    if (this.hasSubmitTarget) {
      this.submitTarget.classList.toggle('u-hide', value)
    }
  }

  showSubmit(value = true) {
    this.showClear(!value)
  }

  showLoader() {
    this.clearResults()
    this.showDivider()
    this.loaderTarget.classList.remove('u-hide')
  }

  hideLoader() {
    this.submitButtonSpinnerController?.loaded()
    this.loaderTarget.classList.add('u-hide')
  }

  showNewPatient() {
    if (!this.hasNewPatientTarget) return

    this.newPatientTarget.classList.remove('u-hide')
  }

  hideNewPatient() {
    if (!this.hasNewPatientTarget) return

    this.newPatientTarget.classList.add('u-hide')
  }

  hideErrorMessages() {
    this.hideAutoSearchErrorMessage()
    this.hideForcedSearchErrorMessage()
  }

  showAutoSearchErrorMessage() {
    if (!this.hasAutoSearchErrorMessageTarget) return

    this.hideLoader()
    this.hideForcedSearchErrorMessage()
    this.showDivider()
    this.autoSearchErrorMessageTarget.classList.remove('u-hide')
  }

  hideAutoSearchErrorMessage() {
    if (!this.hasAutoSearchErrorMessageTarget) return

    this.autoSearchErrorMessageTarget.classList.add('u-hide')
  }

  showForcedSearchErrorMessage() {
    this.hideLoader()
    this.hideAutoSearchErrorMessage()
    this.showDivider()
    this.forcedSearchErrorMessageTarget.classList.remove('u-hide')
  }

  hideForcedSearchErrorMessage() {
    this.forcedSearchErrorMessageTarget.classList.add('u-hide')
  }

  showDivider() {
    if (!this.hasDividerTarget) return

    this.dividerTarget.classList.remove('u-hide')
  }

  hideDivider() {
    if (!this.hasDividerTarget) return

    this.dividerTarget.classList.add('u-hide')
  }

  removeSkeleton() {
    this.inputsTarget.classList.remove('-skeleton')
    this.inputTargets.find((t) => t.autofocus)?.focus()
  }

  intersectionCallback(entries) {
    entries[0].target.focus()
  }

  cancelSearch() {
    this.formSubmission?.stop()
    this.abortController?.abort()
  }

  // ==== Channels

}
