import ApplicationController from '../support/application_controller'
import { replaceDOM }        from '../support/helpers'

export default class extends ApplicationController {

  static targets = []

  // ==== Controllers

  // ==== Actions

  ajaxSuccess(event) {
    const [_response, _status, xhr] = event.detail
    replaceDOM(this.element, xhr.response)
  }

  // ==== Getters

  // ==== Setters

  // ==== Private

  // ==== Channels

}
