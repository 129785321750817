import ApplicationController from '../support/application_controller'

export default class extends ApplicationController {

  // ==== Controllers

  // ==== Actions

  toggleMenu(event) {
    const element = event.currentTarget
    element.classList.toggle('-focus')
  }

  closeMenu(event) {
    const element = event.currentTarget
    element.classList.remove('-focus')
  }

  // ==== Getters

  // ==== Setters

  // ==== Private

  // ==== Channels

}
