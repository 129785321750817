import OverlayScrollbars     from 'overlayscrollbars'
import ApplicationController from '../../frontend/support/application_controller'
import $fq                   from '../../frontend/support/fake_query'

export default class extends ApplicationController {

  static targets = [
    'activeMenuItem',
    'inactiveMenuItem',
    'menu',
    'turboFrame'
  ]

  static values = {
    currentConsultationId: String
  }

  static classes = [
    'active'
  ]

  initialize() {}

  connect() {
    this.overlayScrollbars = OverlayScrollbars(this.menuTarget, {
      sizeAutoCapable: true
    })

    this.scrollToSelectedConsultation()
  }

  // ==== Controllers

  // ==== Actions

  setMenuItemState(event) {
    const element = event.currentTarget.parentNode

    setTimeout(() => {
      this.removeInactiveMenuItemState()
      $fq(element).addClass(this.activeClass)
    }, 400)
  }

  restoreActiveMenuItemState(event) {
    event.preventDefault()

    setTimeout(() => {
      this.removeInactiveMenuItemState()
    }, 400)

    if (this.hasTurboFrameTarget) {
      $fq(this.turboFrameTarget).innerHTML('')
      this.turboFrameTarget.removeAttribute('src')
    }
  }

  restoreMenuItemState(_event) {
    this.removeInactiveMenuItemState()
  }

  // ==== Getters

  // ==== Setters

  // ==== Private

  removeInactiveMenuItemState() {
    this.inactiveMenuItemTargets.forEach((target) => {
      $fq(target).removeClass(this.activeClass)
    })
  }

  scrollToSelectedConsultation() {
    if (!this.currentConsultationIdValue) return

    const element = this.menuTarget.querySelector(`[data-consultation-id='${this.currentConsultationIdValue}']`)

    if (!element) return

    setTimeout(() => {
      this.overlayScrollbars.scroll({ el: element }, 300)
    }, 500)
  }

  // ==== Channels

}
