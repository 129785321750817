import ApplicationController from '../support/application_controller'
import CreateChannel         from '../client/cable'

export default class extends ApplicationController {

  static targets = [
    'profile'
  ]

  initialize() {
    this.createPatientChannel()
  }

  // ==== Controllers

  // ==== Actions

  // ==== Getters

  get patientId() {
    return this.data.get('patientId')
  }

  // ==== Setters

  // ==== Private

  // ==== Channels

  createPatientChannel = async () => {
    const self = this

    await CreateChannel({
      channel:    'PatientChannel',
      patient_id: self.patientId
    }, {
      received(data) {
        if (self.hasProfileTarget) {
          self.profileTarget.innerHTML = data.html
        }
      }
    })
  }

}
