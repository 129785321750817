import ApplicationController from '../support/application_controller'

export default class extends ApplicationController {

  static targets = [
    'anchor',
    'popover'
  ]

  static values = {
    autoOpen:  Boolean,
    direction: String,
    position:  String
  }

  initialize() {}

  connect() {
    if (this.autoOpenValue) this.show()
    if (this.isOpen) this.update()
  }

  // ==== Controllers

  // ==== Actions

  show() {
    this.popoverTarget.showPopover()
  }

  hide() {
    this.popoverTarget.hidePopover()
  }

  toggle() {
    this.popoverTarget.togglePopover()
  }

  beforeToggle({ oldState, newState }) {
    this.element.dataset.toggling     = true
    this.element.dataset.togglingFrom = oldState
    this.element.dataset.togglingTo   = newState
  }

  afterToggle({ newState }) {
    delete this.element.dataset.toggling
    delete this.element.dataset.togglingFrom
    delete this.element.dataset.togglingTo

    if (newState === 'open') this.update()
  }

  updateIfOpen() {
    if (this.isOpen) this.update()
  }

  // ==== Getters

  get anchor() {
    if (this.hasAnchorTarget) return this.anchorTarget

    return this.element
  }

  get anchorRect() {
    return this.anchor.getBoundingClientRect()
  }

  get popoverRect() {
    return this.popoverTarget.getBoundingClientRect()
  }

  get isOpen() {
    return this.popoverTarget.matches(':popover-open')
  }

  // ==== Setters

  // ==== Private

  update() {
    const anchorRect  = this.anchorRect
    const popoverRect = this.popoverRect

    let top  = anchorRect.top
    let left = anchorRect.left

    if (this.positionValue?.includes('bottom')) top += anchorRect.height
    if (this.positionValue?.includes('right')) left += anchorRect.width
    if (this.directionValue?.includes('up')) top -= popoverRect.height
    if (this.directionValue?.includes('left')) left -= popoverRect.width

    this.popoverTarget.style.setProperty('--c-popover-position-top', `${top}px`)
    this.popoverTarget.style.setProperty('--c-popover-position-left', `${left}px`)
  }

  // ==== Channels

}
