import Icd10AdvancedSearchController from '../../icd10_advanced_search_controller'

export default class extends Icd10AdvancedSearchController {

  static targets = []

  static outlets = [
    'improved-additional-items-icd10-codes'
  ]

  // ==== Actions

  // ==== Getters

  // ==== Private

  autoSearch() {
    if (this.hasImprovedAdditionalItemsIcd10CodesOutlet) {
      this.improvedAdditionalItemsIcd10CodesOutlet.hideAddedCodes()
    }

    super.autoSearch()
  }

  clearResults() {
    if (this.hasImprovedAdditionalItemsIcd10CodesOutlet && this.allBlank) {
      this.improvedAdditionalItemsIcd10CodesOutlet.showAddedCodes()
      this.hideLoader()
    }

    super.clearResults()
  }

}
