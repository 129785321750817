import ApplicationController from '../../support/application_controller'

export default class extends ApplicationController {

  static targets = [
    'input',
    'accordion'
  ]

  // ==== Controllers

  // ==== Actions

  select({ params: { phrase } }) {
    this.accordionController?.collapseAll()

    if (phrase === null) return

    let text = this.inputTarget.value.trim()

    if (text) {
      text += `\n\n${phrase}`
    } else {
      text += phrase
    }

    this.inputTarget.value = text

    this.inputTarget.dispatchEvent(new Event('input'))
  }

  // ==== Getters

  get accordionController() {
    if (!this.hasAccordionTarget) return null

    return this.getControllerForElement(this.accordionTarget, 'accordion-component--accordion')
  }

  // ==== Setters

  // ==== Private

  // ==== Channels

}
