import videojs               from 'video.js'
import ApplicationController from '../support/application_controller'

export default class extends ApplicationController {

  static targets = []

  initialize() {
    window.VIDEOJS_NO_DYNAMIC_STYLE = false
    window.HELP_IMPROVE_VIDEOJS     = false
  }

  connect() {
    this.player = videojs(this.element, {
      aspectRatio: '16:9',
      muted:       true
    })
  }

  // ==== Controllers

  // ==== Actions

  // ==== Getters

  // ==== Setters

  // ==== Private

  // ==== Channels

}
