import Cleave                from 'cleave.js'
import ApplicationController from '../support/application_controller'

export default class extends ApplicationController {

  connect() {
    this.loadFormatter()
  }

  // ==== Controllers

  // ==== Actions

  // ==== Getters

  get formatter() {
    return this.data.get('formatter')
  }

  get countryCode() {
    return this.data.get('countryCode')
  }

  get datePattern() {
    if (this.data.has('datePattern')) {
      return JSON.parse(this.data.get('datePattern'))
    }
    return ['Y', 'm', 'd']
  }

  get allowPaste() {
    if (this.data.has('allowPaste')) {
      return this.data.get('allowPaste') === 'true'
    }
    return false
  }

  // ==== Setters

  // ==== Private

  loadFormatter() {
    let cleaveOptions = null

    switch (this.formatter) {
      case 'date':
        cleaveOptions = {
          date:        true,
          datePattern: this.datePattern
        }
        break

      case 'time':
        cleaveOptions = {
          time:        true,
          timePattern: ['h', 'm']
        }
        break

      case 'datetime':
        cleaveOptions = {
          numericOnly: true,
          delimiters:  ['/', '/', ' ', ':'],
          blocks:      [4, 2, 2, 2, 2]
        }
        break

      case 'date_year':
        cleaveOptions = {
          numericOnly: true,
          blocks:      [4]
        }
        break

      case 'expiry_date':
        cleaveOptions = {
          numericOnly: true,
          blocks:      [4, 2],
          delimiters:  ['/']
        }
        break

      case 'float':
        cleaveOptions = {
          numeral:            true,
          numeralDecimalMark: '.',
          delimiter:          ''
        }
        break

      case 'integer':
        cleaveOptions = {
          numericOnly: true,
          blocks:      [100]
        }
        break

      case 'phone':
        if (!this.allowPaste) {
          this.element.addEventListener('paste', (event) => {
            event.preventDefault()
          })
        }

        if (this.countryCode) {
          cleaveOptions = {
            numericOnly:       true,
            prefix:            this.countryCode,
            noImmediatePrefix: true,
            blocks:            [0, this.countryCode.length, 0, 3, 0, 3, 4],
            delimiters:        ['+', ' ', '(', ')', ' ', '-']
          }
        } else {
          cleaveOptions = {
            numericOnly:       true,
            noImmediatePrefix: true,
            blocks:            [0, 3, 0, 3, 4],
            delimiters:        ['(', ')', ' ', '-']
          }
        }
        break

      case 'uppercase':
        // The blocks and delimiter values is a "hack" to allow spaces in the
        // input while formatting it as uppercase.
        cleaveOptions = {
          blocks:    [99999],
          delimiter: '',
          uppercase: true
        }
        break

      case 'invoice':
        cleaveOptions = {
          numericOnly: true,
          blocks:      [0, 99999],
          delimiters:  ['INV']
        }
        break

      default:
        break
    }

    // eslint-disable-next-line
    const cleave = new Cleave(this.element, cleaveOptions)
  }

  // ==== Channels

}
