import ApplicationController from '../../support/application_controller'

export default class extends ApplicationController {

  static values = {
    consultationId: { type: String, default: '' },
    poll:           { type: Boolean, default: false }
  }

  static targets = [
    'billingItemAmount',
    'billingItemContainer',
    'billingItemPaidFromScheme',
    'billingItemText',
    'billingItemTotalAmount',
    'cashButtons',
    'failedClaimButtons',
    'printButton'
  ]

  // ==== Actions

  initialize() {
    if (this.pollValue) {
      this.updateBillingItemListener()
      this.intervalID = setInterval(() => this.updateBillingItemListener(), 5000)
    }
  }

  updateBillingItemListener() {
    const url  = `/api/v1/billing/information/${this.consultationIdValue}`

    fetch(url)
      .then((response) => response.json())
      .then((json) => {
        if (Object.keys(json).length > 0) {
          clearInterval(this.intervalID)
          this.updateStatus(json)
          this.updateAmounts(json)
          this.showButtons(json)
        }
      })
      .catch((_error) => {})
  }

  updateAmounts(json) {
    if (this.hasBillingItemPaidFromSchemeTarget) {
      this.billingItemPaidFromSchemeTargets.forEach((element) => {
        if (json[element.dataset.invoiceItemId]) {
          const amount = json[element.dataset.invoiceItemId].paid_from_scheme
          if (amount.length !== 0) {
            element.innerHTML = amount
          }
        }
      })
    }

    if (this.hasBillingItemAmountTarget) {
      this.billingItemAmountTargets.forEach((element) => {
        if (json[element.dataset.invoiceItemId]) {
          const amount = json[element.dataset.invoiceItemId].amount
          if (amount.length !== 0) {
            element.innerHTML = amount
          }
        }
      })
    }

    if (this.hasBillingItemTotalAmountTarget) {
      this.billingItemTotalAmountTargets.forEach((element) => {
        if (json[element.dataset.invoiceItemId]) {
          const amount = json[element.dataset.invoiceItemId].total_amount
          if (amount.length !== 0) {
            element.innerHTML = amount
          }
        }
      })
    }
  }

  updateStatus(json) {
    if (this.hasBillingItemContainerTarget) {
      this.billingItemContainerTargets.forEach((element) => {
        if (json[element.dataset.invoiceItemId]) {
          const statusColor = json[element.dataset.invoiceItemId].status_color
          if (statusColor.length !== 0) {
            this.removeClassType(element, '-bc-')
            element.classList.add(`-bc-${statusColor}`)
          }
        }
      })
    }

    if (this.hasBillingItemTextTarget) {
      this.billingItemTextTargets.forEach((element) => {
        if (json[element.dataset.invoiceItemId]) {
          const invoiceItemId = element.dataset.invoiceItemId
          const statusColor   = json[invoiceItemId].status_color
          const statusText    = json[invoiceItemId].status_html
          if (statusColor.length !== 0) {
            this.removeClassType(element, 'u-bgc-')
            element.classList.add(`u-bgc-${statusColor}`)
          }

          if (statusText.length !== 0) {
            element.firstChild.innerHTML = statusText
            element.firstChild.classList.remove('u-mb-ghalf')
            element.firstChild.classList.add('u-mb-g1x')
          }
        }
      })
    }
  }

  showButtons(json) {
    if (json.converted_to_cash === true) {
      this.failedClaimButtonsTarget.hidden = false
    } else {
      this.cashButtonsTarget.hidden = false
    }
  }

  removeClassType(element, classType) {
    element.classList.forEach((item) => {
      if (item.startsWith(classType)) {
        element.classList.remove(item)
      }
    })
  }

}
