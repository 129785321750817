import { get }               from '@rails/request.js'
import ApplicationController from '../../support/application_controller'

export default class extends ApplicationController {

  static values = {
    validityCheckUrl: String
  }

  // ==== Controllers

  // ==== Actions

  async checkValidity(event) {
    const element = event.currentTarget

    if (element.value === element.dataset.capturedValue) return

    const queryData = new FormData()

    queryData.append(element.name, element.value)

    await get(
      this.validityCheckUrlValue,
      {
        query:        queryData,
        responseKind: 'turbo-stream'
      }
    )
  }

  // ==== Getters

  // ==== Setters

  // ==== Private

  // ==== Channels

}
