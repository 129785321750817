import WizardController from '../wizard_controller'

export default class extends WizardController {

  static targets = [
    'cd4Subform',
    'negative',
    'positive'
  ]

  static values = {}

  static classes = []

  initialize() {}

  connect() {}

  // ==== Controllers

  // ==== Actions

  toggleHivSelections(event) {
    [
      { value: 'positive', target: this.positiveTarget },
      { value: 'negative', target: this.negativeTarget }
    ].forEach((obj) => {
      if (event.currentTarget.value === obj.value) {
        this.showRadioButtonMoreDetails({
          target: obj.target
        })
      } else {
        this.hideRadioButtonMoreDetails({
          target: obj.target
        })
      }
    })
  }

  toggleCd4Input(event) {
    if (event.currentTarget.value === 'yes') {
      this.showRadioButtonMoreDetails({ target: this.cd4SubformTarget })
    } else {
      this.hideRadioButtonMoreDetails({ target: this.cd4SubformTarget })
    }
  }

  // ==== Getters

  // ==== Setters

  // ==== Private

  // ==== Channels

}
