import NotificationsControllerBase from './notifications_controller_base'

/**
 * Notification indicator for TabsComponent/c_tabs
 */
export default class extends NotificationsControllerBase {

  static targets = [
    'indicator'
  ]

  // ==== Controllers

  // ==== Actions

  // ==== Getters

  // ==== Setters

  // ==== Private

  onUnread(_count) {
    this.indicatorTarget.classList.remove('u-hide')
  }

  // ==== Channels

}
