import WizardController from '../wizard_controller'

export default class extends WizardController {

  static targets = [
    'noneInverse'
  ]

  static values = {}

  static classes = []

  initialize() {}

  connect() {}

  // ==== Controllers

  // ==== Actions

  toggleNoneInverse(event) {
    this.toggleNoneInverseSelections({
      input:   event.currentTarget,
      targets: this.noneInverseTargets
    })
  }

  // ==== Getters

  // ==== Setters

  // ==== Private

  // ==== Channels

}
