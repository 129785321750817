import { Turbo }             from '@hotwired/turbo-rails'
import ApplicationController from '../support/application_controller'

export default class extends ApplicationController {

  connect() {
    // eslint-disable-next-line
    setTimeout(() => { Turbo.visit(this.page, { action: 'replace' }) }, this.delay)
  }

  // ==== Controllers

  // ==== Actions

  // ==== Getters

  get delay() {
    return parseInt(this.data.get('delay'), 10)
  }

  get page() {
    return this.data.get('page')
  }

  // ==== Setters

  // ==== Private

  // ==== Channels

}
