const GLOBAL_MUTE_ATTR = 'data-global-mute'

/**
 * Activates or deactivates global mute.
 * When active, does not play sound, and silences desktop notifications.
 *
 * @param {boolean} value mute if true, unmute if false.
 */
export function setGlobalMute(value) {
  if (value) {
    document.documentElement.setAttribute(GLOBAL_MUTE_ATTR, '')
  } else {
    document.documentElement.removeAttribute(GLOBAL_MUTE_ATTR)
  }
}

/**
 * Gets the state of global mute.
 *
 * @returns {boolean} true if global mute is active, false otherwise
 */
export function getGlobalMute() {
  return document.documentElement.hasAttribute(GLOBAL_MUTE_ATTR)
}
