import ApplicationController from '../../support/application_controller'
import $fq                   from '../../support/fake_query'

export default class extends ApplicationController {

  static targets = [
    'amount',
    'consultationType',
    'discountedPrice',
    'expressPrice',
    'noChargeForm',
    'noChargePrice',
    'priceButton',
    'standardPrice'
  ]

  static values = {}

  static classes = []

  initialize() {}

  connect() {}

  // ==== Controllers

  // ==== Actions

  selectDiscountedPrice() {
    this.selectPriceOption(this.discountedPriceTarget)
  }

  selectExpressPrice() {
    this.selectPriceOption(this.expressPriceTarget)
  }

  selectStandardPrice() {
    this.selectPriceOption(this.standardPriceTarget)
  }

  selectNoChargePrice() {
    this.selectPriceOption(this.noChargePriceTarget)
  }

  // ==== Getters

  // ==== Setters

  // ==== Private

  selectPriceOption(target) {
    if (!$fq(target).hasClass('-selected')) {
      this.resetSelection()
      this.setValues(target)

      if (this.consultationTypeTarget.value === 'no_charge_price') {
        this.showNoChargeReasons()
      } else {
        this.hideNoChargeReasons()
      }

      $fq(target).addClass('-selected')
    }
  }

  setValues(element) {
    this.amountTarget.value = element.dataset.amount
    this.consultationTypeTarget.value = element.dataset.consultationType
  }

  showNoChargeReasons() {
    $fq(this.noChargeFormTarget).show()
  }

  hideNoChargeReasons() {
    $fq(this.noChargeFormTarget).hide()
  }

  resetSelection() {
    this.priceButtonTargets.forEach((target) => {
      target.classList.remove('-selected')
    })
  }

  // ==== Channels

}
