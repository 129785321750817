import ApplicationController from '../../frontend/support/application_controller'

export default class extends ApplicationController {

  static targets = [
    'button'
  ]

  // ==== Controllers

  // ==== Actions

  toggle(event) {
    const element = event.currentTarget

    if (this.isSelected(element)) {
      element.setAttribute('aria-selected', 'false')
    } else {
      element.setAttribute('aria-selected', 'true')
    }
  }

  // ==== Getters

  // ==== Setters

  // ==== Private

  isSelected(element) {
    return element.getAttribute('aria-selected') === 'true'
  }

  // ==== Channels

}
