import moment                from 'moment'
import Rails                 from '@rails/ujs'
import ApplicationController from '../support/application_controller'

const REFRESH_INTERVAL = 60

export default class extends ApplicationController {

  static targets = [
    'display',
    'exceedAverageWaitTime',
    'firstInQueue',
    'toggleDisplay'
  ]

  static outlets = ['waiting-list']

  initialize() {
    this.requestAndResponse()

    this.intervalID = setInterval(() => {
      this.requestAndResponse()
    }, REFRESH_INTERVAL * 1000)
  }

  disconnect() {
    clearInterval(this.intervalID)
  }

  // ==== Controllers

  // ==== Actions

  // ==== Getters

  get url() {
    return '/api/v1/videomed-queue'
  }

  // ==== Setters

  // ==== Private

  requestAndResponse() {
    const self = this
    this.request((response) => { self.handleResponse(response) })
  }

  request(callback) {
    Rails.ajax({
      url:      this.url,
      type:     'GET',
      dataType: 'script',
      success:  callback,
      error:    (_response) => {}
    })
  }

  handleResponse(data) {
    this.showExceedAverageTime(data)
    
    if (this.hasDisplayTarget) {
      this.displayTarget.innerHTML = data.meta.average_wait_time.formatted
    }
  }

  showExceedAverageTime(data) {
    const averageWaitTime = moment.duration({
      seconds: data.meta.average_wait_time.seconds,
      minutes: data.meta.average_wait_time.minutes
    })
    
    if (this.hasWaitingListOutlet && this.hasExceedAverageWaitTimeTarget) {
      if (this.waitingListOutlet.duration.asMinutes() > averageWaitTime.asMinutes()) {
        this.exceedAverageWaitTimeTarget.classList.remove('u-hide')
      }
    }
  }

  // ==== Channels

}
