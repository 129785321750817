import ApplicationController from '../support/application_controller'
import BmiCalculator         from '../support/bmi_calculator'

export default class extends ApplicationController {

  static targets = this.targets.concat([
    'bmi',
    'height',
    'weight'
  ])

  initialize() {
    super.initialize()
  }

  connect() {
    BmiCalculator(this)
  }

}
