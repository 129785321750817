import moment                from 'moment'
import ApplicationController from '../support/application_controller'

export default class extends ApplicationController {

  static targets = ['counter']

  static values = {
    timerStartedAt: String
  }

  connect() {
    this.timeInQueue()
    
    const eventTime   = moment(this.timerStartedAt, 'YYYY-MM-DD HH-mm-ss')
    const currentTime = moment()
    
    this.duration = moment.duration(currentTime.diff(eventTime, 'ms'))
  }

  disconnect() {
    clearTimeout(this.counterInterval)
  }

  // ==== Controllers

  // ==== Actions

  // ==== Getters

  get timerStartedAt() {
    return this.timerStartedAtValue
  }

  // ==== Setters

  // ==== Private

  timeInQueue() {
    const self  = this

    if (this.hasCounterTarget) {
      const interval    = 1000

      this.counterInterval = setInterval(() => {
        this.duration = moment.duration(this.duration.asMilliseconds() + interval, 'ms')

        const d = `0${this.duration.days()}`.substr(-2)
        const h = `0${this.duration.hours()}`.substr(-2)
        const m = `0${this.duration.minutes()}`.substr(-2)
        const s = `0${this.duration.seconds()}`.substr(-2)

        self.counterTarget.innerHTML = `${d}:${h}:${m}:${s}`.replace(/^(?:00:)/, '')
      }, interval)
    }
  }

  // ==== Channels

}
