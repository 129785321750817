import ApplicationController from '../support/application_controller'

export default class extends ApplicationController {

  static targets = [
    'container'
  ]

  initialize() {
    this.addToggle()
  }

  // ==== Controllers

  // ==== Actions

  toggle() {
    this.containerTarget.classList.toggle('u-hide')
  }

  // ==== Getters

  // ==== Setters

  // ==== Private

  addToggle() {
    const button = `<button type='button' data-action='click->testing-tools#toggle' class='a-btn -micro -jade -p-half'>
      Toggle testing tools
    </button>`
    this.element.insertAdjacentHTML('afterbegin', button)
  }

  // ==== Channels

}
