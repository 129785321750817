import ApplicationController from '../../support/application_controller'

export default class extends ApplicationController {

  static targets = [
    'responseBody'
  ]

  static values = {}

  static classes = []

  initialize() {}

  connect() {}

  // ==== Controllers

  // ==== Actions

  ajaxComplete(event) {
    const [_response, _status, xhr] = event.detail
    this.renderFlashMessage(xhr)

    this.responseBodyTarget.outerHTML = xhr.response
  }

  // ==== Getters

  // ==== Setters

  // ==== Private

  // ==== Channels

}
