import ApplicationController from '../support/application_controller'

export default class extends ApplicationController {

  static targets = []

  static values = {}

  static classes = []

  initialize() {}

  connect() {
    this.shadowRoot = this.element.attachShadow({ mode: 'open' })

    const html = this.element.innerHTML
    this.element.innerHTML = ''
    this.shadowRoot.innerHTML = html
  }

  // ==== Controllers

  // ==== Actions

  // ==== Setters

  // ==== Private

  // ==== Channels

}
