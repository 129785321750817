class HfDirectUploadEvents {

  constructor() {
    this.uploadController     = null
    this.fileInputTarget      = null
    this.progressWrapperClass = null
    this.submitButtonTarget   = null
    this.useGallery           = null
    this.skipSubmitOnError    = null
  }

  bindAllEvents() {
    this.fileInputTarget.addEventListener('direct-upload:initialize', (event) => {
      const { _target, detail } = event
      const { id, file }        = detail

      const cell = document.createElement('div')
      cell.setAttribute('id', `directUpload-${id}`)
      cell.setAttribute('class', this.progressWrapperClass)
      cell.setAttribute('data-direct-upload-file', file.name)
      cell.innerHTML = this.progressWrapperHTML(id)

      if (this.useGallery) {
        this.uploadController.prependCell(cell)
      } else {
        this.uploadController.showPreview(cell)
      }

      this.didError = false
    })

    this.fileInputTarget.addEventListener('direct-upload:start', (event) => {
      const { id }  = event.detail
      const element = document.getElementById(`directUpload-${id}`)
      element.classList.remove('-pending')
    })

    this.fileInputTarget.addEventListener('direct-upload:progress', (event) => {
      const { id, progress } = event.detail
      const progressElement  = document.getElementById(`directUploadProgress-${id}`)
      const progressNumber   = document.querySelector(`#directUploadProgressNumber-${id} span`)
      const progressMessage  = document.querySelector(`#directUploadProgressNumber-${id} small`)

      progressNumber.textContent = `${Math.round(progress)}%`
      this.updateProgressElement(progressElement, progress)

      if (progress === 100) {
        progressMessage.textContent = 'Finalising upload...'
      }
    })

    this.fileInputTarget.addEventListener('direct-upload:error', (event) => {
      event.preventDefault()
      const { id, error } = event.detail
      const element       = document.getElementById(`directUpload-${id}`)

      element.classList.add('-error')
      element.setAttribute('title', error)
      this.didError = true
    })

    this.fileInputTarget.addEventListener('direct-upload:end', (event) => {
      if (this.skipSubmitOnError && this.didError) return

      const { id }  = event.detail
      const element = document.getElementById(`directUpload-${id}`)

      element.classList.add('-complete')
      this.submitButtonTarget.click()
    })
  }

  progressWrapperHTML(id) {
    return `
      <div class="a-directUploadProgress">
        <div id="directUploadProgress-${id}" class="a-directUploadProgress__bar" style="top: -200%"></div>
        <div id="directUploadProgressNumber-${id}" class="a-directUploadProgress__number">
          <span>0%</span>
          <small>&nbsp;</small>
        </div>
      </div>
    `
  }

  updateProgressElement(progressElement, progress) {
    progressElement.style.top  = `${-200 - (progress * -1.4)}%`
  }

}

export default HfDirectUploadEvents
