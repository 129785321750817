import ApplicationController from '../../support/application_controller'

export default class extends ApplicationController {

  static targets = [
    'idDocumentInput',
    'idNumberInput',
    'passportNumberInput',
    'passportNumberWrapper'
  ]

  // ==== Controllers

  // ==== Actions

  switchFields(event) {
    const idDocumentInputValue = event.target.value
    const isPassportSelected   = idDocumentInputValue === 'passport'

    this.idNumberInputTarget.hidden = isPassportSelected
    this.passportNumberInputTargets.forEach((input) => { input.hidden = !isPassportSelected })

    if (isPassportSelected) {
      if (this.hasPassportNumberWrapperTarget) {
        this.passportNumberWrapperTarget.classList.remove('u-hide')
      }
      this.removeErrorMessage(this.idNumberInputTarget)
    } else {
      if (this.hasPassportNumberWrapperTarget) {
        this.passportNumberWrapperTarget.classList.add('u-hide')
      }
      this.passportNumberInputTargets.forEach((input) => this.removeErrorMessage(input))
    }
  }

  // ==== Getters

  // ==== Setters

  // ==== Private

  removeErrorMessage(target) {
    const errorMessage = target.querySelector("[class$='__error']")
    if (errorMessage) {
      target.classList.remove('-error')
      errorMessage.remove()
    }
  }

  // ==== Channels

}
