import ApplicationController from '../support/application_controller'

export default class extends ApplicationController {

  static targets = [
    'chapterFilters',
    'expandedStateInput',
    'toggleButton'
  ]

  connect() {
    this.handleCollapsedState()
  }

  // ==== Actions

  toggleCollapsed(_event) {
    if (this.chapterFilterCollection.classList.contains('-collapsed')) {
      this.chapterFilterCollection.classList.remove('-collapsed')
      this.expandedStateInputTarget.setAttribute('value', 'expanded')
    } else {
      this.chapterFilterCollection.classList.add('-collapsed')
      this.expandedStateInputTarget.setAttribute('value', 'collapsed')
    }

    this.updateButtonText()
  }

  // ==== Getters

  get chapterFilterCollection() {
    return this.chapterFiltersTarget.querySelector('.a-ds2-input__collection')
  }

  get chapterFilterCollectionBlockPadding() {
    const blockPaddingValues = this.chapterFilterCollectionComputedStyle.getPropertyValue('padding-block').split(' ')
    return blockPaddingValues.reduce((accumulator, value) => accumulator + parseInt(value.slice(0, -2), 10), 0)
  }

  get chapterFilterCollectionComputedStyle() {
    return window.getComputedStyle(this.chapterFilterCollection)
  }

  get chapterFilterLabelHeight() {
    return this.chapterFilterCollection.querySelector('label').offsetHeight
  }

  get chapterFilterRowGap() {
    const gap = this.chapterFilterCollectionComputedStyle.getPropertyValue('row-gap').slice(0, -2)

    return parseInt(gap, 10)
  }

  get collapseText() {
    return this.toggleButtonTarget.getAttribute('collapse_text')
  }

  get expandText() {
    return this.toggleButtonTarget.getAttribute('expand_text')
  }

  get maxHeight() {
    // the height of 2 rows + the gap between them + the block padding for the chapter filter collection
    return this.chapterFilterLabelHeight * 2 + this.chapterFilterRowGap + this.chapterFilterCollectionBlockPadding
  }

  get toggleButtonText() {
    return this.toggleButtonTarget.querySelector('.c-button__text').innerHTML
  }

  // ==== Setters

  set toggleButtonText(value) {
    this.toggleButtonTarget.querySelector('.c-button__text').innerHTML = value
  }

  // ==== Private

  handleCollapsedState() {
    if (!this.hasChapterFiltersTarget) return

    if (this.shouldCollapse()) {
      this.toggleButtonTarget.classList.remove('u-hide')
      this.chapterFilterCollection.classList.add('-collapsible')

      if (this.expandedStateInputTarget.value === 'collapsed') {
        this.chapterFilterCollection.classList.add('-collapsed')
      }
    }
  }

  shouldCollapse() {
    if (this.maxHeight) {
      return this.chapterFilterCollection.offsetHeight > this.maxHeight
    }

    return false
  }

  updateButtonText() {
    if (this.toggleButtonText === this.expandText) {
      this.toggleButtonText = this.collapseText
    } else {
      this.toggleButtonText = this.expandText
    }
  }

}
