import ApplicationController from '../../support/application_controller'

export default class extends ApplicationController {

  static targets = [
    'prescription',
    'ingredient'
  ]

  static classes = [
    'hide'
  ]

  togglePrescription({ currentTarget: { checked }, params: { prescription } }) {
    this.prescriptionTargets
      .find((target) => target.dataset.prescription === prescription)
      ?.classList
      ?.toggle(this.hideClass, !checked)
  }

  toggleIngredient({ currentTarget: { value }, params: { prescription } }) {
    this.ingredientTargets
      .filter((target) => target.dataset.prescription === prescription)
      .forEach((target) => {
        target.classList.toggle(this.hideClass, target.dataset.ingredient !== value)
      })
  }

}
